import TextInput from 'components/inputs/text-input';
import { SmallButton } from 'components/button';
import { FormattedMessage } from 'react-intl';

type FormData = {
  email: string;
  phone: string;
};

const emptyFormData: FormData = {
  email: '',
  phone: '',
};

type Props = {
  initialData?: FormData;
  onChangePassword: () => void;
  readOnly?: boolean;
};

export default function AccountForm({
  initialData = emptyFormData,
  onChangePassword,
  readOnly,
}: Props) {
  return (
    <div className="flex flex-col items-start mt-4">
      <h3 className="mb-6 text-2xl font-semibold">Account</h3>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-x-10">
        <TextInput value={initialData.email} label="E-Mail" readOnly />
        <TextInput value={initialData.phone} label="Phone" readOnly />
      </div>
      <div className="flex items-center space-x-2 mt-4">
        <TextInput
          type="password"
          value="**********"
          label="Password"
          readOnly
        />
        {!readOnly && (
          <SmallButton className="ms-2 mt-6" onClick={onChangePassword}>
            <FormattedMessage id="account.change" />
          </SmallButton>
        )}
      </div>
    </div>
  );
}
