import cn from 'classnames';
import { divide } from 'ramda';


const AudienceLabel = ({audience}) => {
  return (
    <div className="flex justify-center">
      {audience.map((item) => <div className="mx-2 bg-white rounded border border-black px-1">{item}</div>)}
    </div>
  );
}
const CollectionOrderItem = ({data, pickedItem}) => {
    return (
        <div className="mb-2 me-2  overflow-hidden">
          <div
            className={cn(
              'flex flex-col justify-between w-200 h-300 rounded-lg pb-6 px-2 cursor-pointer bg-cover shadow-md ', {
                "border-8 border-gray": pickedItem === data.id
              }
            )}
            style={{
              backgroundImage: `url("${data.thumbnailUrl}")`,
            }}
          >
            <AudienceLabel audience={data.audience}/>
            <div className="font-bold text-white flex flex-col items-center">
              <CardTitle>{data.titleEnglish}</CardTitle>
            </div>
            </div>
          </div>
      );
    };
const CardTitle = ({ children }: any) => (
    <span style={{ textShadow: '#000 0 0 3px' }} className="break-all">{children}</span>
  );

 export const PlaceholderItem = ({text} : {text: string}) => {
    return (
        <div className="mb-2 me-2  overflow-hidden">
          <div
            className={cn(
              'flex flex-col justify-center w-200 h-300 rounded-lg pb-6 pt-2 px-2 cursor-pointer bg-gray shadow-md'
            )}
          >
            <div className="font-bold text-black flex flex-col items-center justify-center">
                {text}
            </div>
            </div>
          </div>
    )
}

export default CollectionOrderItem;