import * as React from 'react';
import { useIntl } from 'react-intl';
import cx from 'classnames';

type Props = {
  id: string;
  className?: string;
};

const TranslatedText = ({ id, className }: Props) => {
  const intl = useIntl();
  return <span className={cx(className)}>{intl.formatMessage({ id })}</span>;
};

export default TranslatedText;
