import * as React from 'react';
import { path } from 'ramda';
import icons from 'components/icons';
import { AuthInput } from 'components/input';
import { useForm } from 'react-hook-form';
import { AuthButton } from 'components/button';
import * as yup from 'yup';
import { FormattedMessage, useIntl } from 'react-intl';
import { yupResolver } from '@hookform/resolvers/yup';

type FormData = {
  username: string;
};

const useSchema = () => {
  const intl = useIntl();

  return React.useMemo(
    () =>
      yup.object({
        username: yup
          .string()
          .required(intl.formatMessage({ id: 'validation.fieldRequired' })),
      }),
    [intl]
  );
};

type Props = {
  onFormExit?: () => void;
  onSubmit: (formData: FormData) => void;
  isLoading: boolean;
};

const RequestPasswordForm = ({ onFormExit, onSubmit, isLoading }: Props) => {
  const intl = useIntl();
  const schema = useSchema();
  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  return (
    <div className="flex flex-col">
      <div className="text-center my-5">
        <h1 className="text-xl">
          <FormattedMessage id="auth.requestNewPassword" />
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthInput
          inputRef={register({ required: true })}
          name="username"
          error={path(['username', 'message'], errors)}
          placeholder={intl.formatMessage({
            id: 'auth.usernamePlaceholder',
          })}
        />
        <div className="mt-8">
          <AuthButton type="submit" fullWidth>
            <FormattedMessage
              id={isLoading ? 'common.pleaseWait' : 'common.submit'}
            />
          </AuthButton>
        </div>
      </form>
      <div className="text-center mt-6">
        <span
          onClick={() => onFormExit && onFormExit()}
          className="inline-block w-6 h-6 font-bold underline text-secondary cursor-pointer hover:text-primary transition-colors"
        >
          {icons.chevronLeft}
        </span>
      </div>
    </div>
  );
};

export { RequestPasswordForm as default };
