import * as React from 'react';
import { useIntl } from 'react-intl';
import { FILE_UPLOAD_LIMIT } from 'config/const';
import { uploadFileToPublicS3, getLinkForFile } from 'lib/file-upload';
import useEnlargeMedia from 'lib/use-enlarge-media';
import { useKeyPress } from 'lib/key-press';
import mimeTypes from 'utils/mime-types';
import icons from 'components/icons';
import Loader from 'components/loader';
import FileInput from 'components/file-input';
import { useSelector, useDispatch } from 'react-redux';
import { collectionValidationSelector } from 'lib/redux/index';
import { validateCollectionInput } from 'lib/redux/formValidation/actions';
import Cropper from 'react-easy-crop';
import getCroppedImg, {crop600700} from 'utils/imageCrop';
import { Slider } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import { LoaderOverlay } from 'components/loader';

export type OnFileSelectedParam = {
  file: File; //< File is provided in case if upload should be provided externally
  isImage: boolean;
};

type Props = {
  acceptedMime: string; //< Multiple can be separated by ", "
  fileUrl?: string; //< Set to initial image or video
  isVideo: boolean; //< True if initial media file is video
  initialFile: File | null; //< Used when user selected file, then button was destroyed, then recreated
  onError: (message: string) => void;
  onFileSelected?: (param: OnFileSelectedParam) => boolean | Promise<boolean>; //< \return true if file is OK and should be used, false otherwise
  name?: string;
  picAspectX?: number;
  picAspectY?: number;
  picWidth?: number;
  picHeight?: number;
  cropLocked?: boolean;
  toCrop?: boolean;
};

const MediaUploadButton = React.forwardRef(
  (
    {
      acceptedMime,
      onFileSelected,
      fileUrl,
      isVideo,
      onError,
      initialFile,
      name,
      picAspectX,
      picAspectY,
      picHeight,
      picWidth,
      cropLocked,
      toCrop
    }: Props,
    ref
  ) => {
    const intl = useIntl();
    const [file, setFile] = React.useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
    const [isImage, setIsImage] = React.useState(true);
    const [isFormatSupported, setIsFormatSupported] = React.useState(true);
    const [isLoading, setIsLoading] = React.useState(false);
    const dispatch = useDispatch()
    const formValidation = useSelector(collectionValidationSelector);

    const [image, setImage] = React.useState<any>(null);
	  const [croppedArea, setCroppedArea] = React.useState(null);
	  const [crop, setCrop] = React.useState({ x: 0, y: 0 });
	  const [zoom, setZoom] = React.useState<any>(1);
    const [isCropping, setIsCropping] = React.useState(false);
    const [showCropper, setShowCropper] = React.useState(false);

    React.useEffect(() => {
      isCropping && !toCrop && onCropEndWH()
    }, [image])

    const onSelectFile = (event) => {
      if(event === undefined) return;
      setIsLoading(true);
      setIsCropping(true);
        const reader = new FileReader();
        reader.readAsDataURL(event);
        reader.addEventListener("load", () => {
          setImage(reader.result);
          setIsLoading(false);
        });
    };

    const dataUrlToFile = async (dataUrl: any, fileName: string): Promise<File> => {
      const res: Response = await fetch(dataUrl);
      const blob: Blob = await res.blob();
      return new File([blob], fileName, { type: 'image/png' });
  }

    const updateThumbnail = async (newFile: File) => {
      setPreviewUrl(null);
      setFile(newFile);
      const url = await getLinkForFile(newFile);
      setPreviewUrl(url);
      if(name) dispatch(validateCollectionInput('', name))
    };

    React.useEffect(() => {
      if (initialFile) {
        updateThumbnail(initialFile).then(() => {
          setIsImage(!isVideo);
        });
      } else if (fileUrl) {
        setPreviewUrl(fileUrl);
        setIsImage(!isVideo);
      }
    }, []);

    const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
      setCroppedArea(croppedAreaPixels);
    };

    const onCropEndWH = async () => {
      setIsCropping(false);
      const blob : any = await crop600700(image, croppedArea, 0);
      const selectedFile: any = await dataUrlToFile(blob, 'newimg.png');
      setIsFormatSupported(true); // Removing info.
      onError(''); // Removing info.
      const mimeType = selectedFile.type;
      // Verification, if selected mime type is correct, is passed onto browser.
      const isImageSelected = mimeTypes.image.includes(mimeType);
      const maxFileSize = isImageSelected
        ? FILE_UPLOAD_LIMIT.IMAGE
        : FILE_UPLOAD_LIMIT.VIDEO;
      if (selectedFile.size > maxFileSize) {
        name && dispatch(validateCollectionInput(`${intl.formatMessage({ id: 'upload.tooLargeFile' })} (max=3mb)`, name));
        onError(intl.formatMessage({ id: 'upload.tooLargeFile' }));
        return;
      }

      if (onFileSelected) {
        const isValid = await onFileSelected({
          file: selectedFile,
          isImage: isImageSelected,
        });
        if (!isValid) {
          return;
        }
      }

      if (isImageSelected !== isImage) {
        setIsImage(isImageSelected);
      }
      await updateThumbnail(selectedFile);
      setImage('');
      setZoom(1);
    }

    const closeCropper = () => {
      setShowCropper(false);
      setZoom(1);
    }

    const onCropEnd = async () => {
      setIsLoading(true);
      const blob : any = await getCroppedImg(image, croppedArea, 0);
      const selectedFile: any = await dataUrlToFile(blob, 'newimg.png');
      setIsFormatSupported(true); // Removing info.
      onError(''); // Removing info.
      const mimeType = selectedFile.type;
      // Verification, if selected mime type is correct, is passed onto browser.
      const isImageSelected = mimeTypes.image.includes(mimeType);
      const maxFileSize = isImageSelected
        ? FILE_UPLOAD_LIMIT.IMAGE
        : FILE_UPLOAD_LIMIT.VIDEO;
      if (selectedFile.size > maxFileSize) {
        name && dispatch(validateCollectionInput(`${intl.formatMessage({ id: 'upload.tooLargeFile' })} (max=3mb)`, name));
        onError(intl.formatMessage({ id: 'upload.tooLargeFile' }));
        return;
      }

      if (onFileSelected) {
        const isValid = await onFileSelected({
          file: selectedFile,
          isImage: isImageSelected,
        });
        if (!isValid) {
          return;
        }
      }

      if (isImageSelected !== isImage) {
        setIsImage(isImageSelected);
      }
      await updateThumbnail(selectedFile);
      setImage('');
      setZoom(1);
      setIsLoading(false);
    }

    // As for MVP, assuming that all video problems are caused by not supported format. There's no possibility to read error source, as browsers
    // usually do not give same error text in 'event.target.error'.
    const onVideoError = () => {
      setIsFormatSupported(false);
    };

    React.useImperativeHandle(ref, () => ({
      // If file should be uploaded is controlled from parent element. It is due to fact, that user might change his mind, thus creating not needed
      // file (and increasing S3 cost).
      uploadFile: async () => {
        if (file) {
          return await uploadFileToPublicS3(file, isImage);
        }
        return fileUrl;
      },
      clear: () => {
        setFile(null);
        setPreviewUrl(null);
      },
    }));

    const {
      enlargePreview,
      onEnlargeClose,
      isEnlarged,
      renderEnlargedPreview,
    } = useEnlargeMedia(isImage, previewUrl as any, onVideoError);

    useKeyPress('Escape', onEnlargeClose);

    const isThumbnailFromFile = initialFile !== null;
    const idOfFileLabel = isThumbnailFromFile
      ? 'upload.fileName'
      : 'upload.url';
    const fileDescLabel = intl.formatMessage({ id: idOfFileLabel });
    const fileDescValue =
      isThumbnailFromFile && initialFile ? initialFile.name : fileUrl;

    return (
      <>
        {isLoading && <LoaderOverlay />}
        {image && toCrop && showCropper && 
        <div className='z-40 w-full h-full fixed top-0 left-0 flex flex-col bg-white items-center p-2'>
              <FontAwesomeIcon className="absolute top-5 right-5" size='3x' color='royalblue' icon={faTimesCircle} onClick={closeCropper}/>
              <div className="relative h-4/6 w-3/4">
                {picWidth && picHeight && <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                  cropSize={{width: picWidth, height: picHeight}}
                />
                }
                {picAspectX && picAspectY && <Cropper
                  image={image}
                  crop={crop}
                  zoom={zoom}
                  aspect={picAspectX / picAspectY}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />}
              </div>
              <div className="relative h-1/6 w-full p-5 flex flex-col items-center justify-center">
                <p className="mb-3 pt-1"><h1>Usage Info: </h1>Drag n drop to change crop position. You can zoom in and out by using slider/mouse wheel. Click 'CROP ME' to crop and save the picture.</p>
                <div className="w-full flex items-center">
                  <span className="mr-5">ZOOM: </span>
                  <Slider
                    aria-label="Small steps"
                    defaultValue={1}
                    value={zoom}
                    step={0.05}
                    min={1}
                    max={3}
                    onChange={(event, value) => setZoom(value)}
                  />
                </div>
              </div>
              <div className="realtive h-1/6 w-full p-5">
                {!picWidth && !picHeight && <div className="button bg-blue-500 p-8 text-center text-white cursor-pointer rounded" onClick={onCropEnd}>CROP ME</div>}
              </div>
				</div>
        }
        {isEnlarged && renderEnlargedPreview()}
        {previewUrl !== null &&
          !isThumbnailFromFile &&
          !isImage &&
          !isFormatSupported && (
            <a
              href={fileUrl}
              target="_blank"
              className="text-secondary text-xs"
            >
              {fileDescLabel}
              {fileDescValue}
            </a>
          )}
        {previewUrl !== null && isThumbnailFromFile && (
          <span className="text-secondary text-xs">
            {fileDescLabel}
            {fileDescValue}
          </span>
        )}
        <div className="button-add">
          <div className="w-full h-full flex items-center justify-center relative rounded-md overflow-hidden">
            {previewUrl === null && file === null && (
              <FileInput
                className="absolute top-0 left-0 w-full h-full cursor-pointer"
                acceptedMime={acceptedMime}
                onFileSelected={onSelectFile}
              >
                <span className="w-16 h-16 text-green outline-none focus:outline-none" onClick={() => setShowCropper(true)}>
                  {icons.plus}
                </span>
              </FileInput>
            )}
            {previewUrl === null && file !== null && <Loader />}
            {previewUrl !== null && !isFormatSupported && (
              <>
                <span className="text-base text-green outline-none focus:outline-none select-none">
                  {intl.formatMessage({ id: 'upload.codecNotSupported' })}
                </span>
                <FileInput
                  className="absolute right-0 bottom-0 w-16 h-16"
                  acceptedMime={acceptedMime}
                  onFileSelected={onSelectFile}
                >
                  <span className="w-16 h-16 absolute right-0 bottom-0 text-white font-bold cursor-pointer rounded-md bg-opacity-50 bg-green" onClick={() => setShowCropper(true)}>
                    {icons.plus}
                  </span>
                </FileInput>
              </>
            )}
            {previewUrl !== null && isFormatSupported && (
              <div className="outline-none focus:outline-none w-full h-full">
                {isImage && (
                  <img
                    src={previewUrl}
                    className="w-full h-full object-cover"
                  />
                )}
                {!isImage && (
                  <video
                    className="w-full h-full object-cover"
                    preload="metadata"
                    onError={onVideoError}
                  >
                    <source src={previewUrl} />
                  </video>
                )}
                <FileInput
                  className="absolute right-0 bottom-0 w-16 h-16"
                  acceptedMime={acceptedMime}
                  onFileSelected={onSelectFile}
                >
                  <span className="w-16 h-16 absolute right-0 bottom-0 text-white font-bold cursor-pointer rounded-md bg-opacity-50 bg-green" onClick={() => setShowCropper(true)}>
                    {icons.plus}
                  </span>
                </FileInput>
                <div
                  onClick={enlargePreview}
                  className="w-16 h-16 absolute left-0 bottom-0 text-white font-bold cursor-pointer rounded-md bg-opacity-50 bg-green"
                >
                  {icons.search}
                </div>
              </div>
            )}
          </div>
        </div>
        {name && !!formValidation[name] && <p className=" mt-2 w-max md:min-w-300 text-red-500 border rounded border-red-500 p-4 text-center">{formValidation[name]}</p>}
      </>
    );
  }
);

export default MediaUploadButton;
