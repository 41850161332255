import * as React from 'react';
import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import Modal from 'components/modal';
import HeaderLink from 'components/header-link';
import { AuthButton as Button } from 'components/button';
import { CollectionHeaderMenu } from 'components/header-menu';
import { errorToast, successToast, infoToast } from 'components/toast/toast';
import { LoaderOverlay } from 'components/loader';
import ErrorBox from 'components/error-box';
import CountryList from 'components/country-list';
import CollectionForm, { CollectionFiles } from 'components/collection-form';
import ProductsSearch from 'components/products-search';
import icons from 'components/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { useUploadCollectionFiles } from 'lib/file-upload';
import { collectionValidationSelector } from 'lib/redux/index'
import { useDispatch, useSelector } from 'react-redux';
import { checkErrorArray } from 'utils/validation';
import {
  useActiveTab,
  validateCollection,
  validateDraftCollection,
  validateItems,
} from 'utils/collection';
import {
  addProduct,
  removeProduct,
  useUpdateCollection,
  initialCollection,
  validateCollectionFilePayload,
} from 'utils/collection';
import { isArabicSelector, isModeratorSelector } from 'lib/redux';
import Input from 'components/input';
import PageTitle from 'components/PageTitle';

const getUrlSearchParams = (
  search: string
): { inflName: string; inflId: string } => {
  const params = new URLSearchParams(search);

  return {
    inflId: params.get('inflId')!,
    inflName: params.get('inflName')!,
  };
};

const CreateCollectionPage = () => {
  const history = useHistory();
  const [collection, updateCollection] = useUpdateCollection(initialCollection);
  const isModerator = useSelector(isModeratorSelector);
  const isArabic: boolean = useSelector(isArabicSelector);
  const intl = useIntl();
  const [error, setError] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [activeTab, setActiveTab] = useActiveTab();
  const formValidation = useSelector(collectionValidationSelector);
  const { search: locationSearch } = useLocation();
  const searchParams = React.useMemo(
    () => getUrlSearchParams(locationSearch),
    [locationSearch]
  );

  const {
    collectionMedia,
    isCollectionMediaImage,
    coverImage,
    setCollectionMedia,
    setIsCollectionMediaImage,
    setCoverImage,
    uploadFiles,
  } = useUploadCollectionFiles();

  const collectionFiles: CollectionFiles = {
    media: collectionMedia,
    isMediaImage: isCollectionMediaImage,
    coverImage,
  };

  const createDraft = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const validationError = isModerator
        ? validateCollection(collection)
        : validateDraftCollection(collection);
      const collectionChange = await uploadFiles();
      const updatedCollection = {
        ...collection,
        ...collectionChange,
      };
      let imageError = false;

      if(isModerator) imageError = validateCollectionFilePayload(updatedCollection);
      if(validationError || imageError) throw new Error('Some fields are not valid')
      if(validateItems(updatedCollection)) throw new Error('Collection must contain at least one product')

      const { id: collectionId } = await restClient.post(
        endpoints.collections,
        {
          body: updatedCollection,
        }
      );
      if (isModerator) {
        await restClient.put(
          `${endpoints.collections}/${collectionId}/approval`
        );
      }
      setIsLoading(false);
      redirectToCollections();
    } catch (error:any) {
      error.response && checkErrorArray(error.response.data, setError);
      !error.response && setError(error.message);
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    errorToast(error);
  }, [error])

  const createDraftAndSubmit = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const validateError = validateCollection(collection);
      const collectionChange = await uploadFiles();
      const updatedCollection = {
        ...collection,
        ...collectionChange,
      };
      const imageError = validateCollectionFilePayload(updatedCollection);
      if(validateError || imageError) throw new Error('Some fields are not valid')
      if(validateItems(updatedCollection)) throw new Error('Collection must contain at least one product')

      const { id: collectionId } = await restClient.post(
        endpoints.collections,
        {
          body: updatedCollection,
        }
      );
      await restClient.put(
        `${endpoints.collections}/${collectionId}/submission`
      );
      setIsLoading(false);
      redirectToCollections();
    } catch (err:any) {
      setIsLoading(false);
      err.response && checkErrorArray(err.response.data, setError);
      !err.response && setError(err.message);
    }
  };

  const onError = (error: string) => {
    if (!!error.length) setError(error);
  };

  const onCollectionFormChange = (change: any) => {
    // To not have old errors.
    setError(null);

    if (change.collection) {
      updateCollection(change.collection);
    }
    if (change.coverImage) {
      setCoverImage(change.coverImage);
    }
    if (change.media) {
      setCollectionMedia(change.media);
      setIsCollectionMediaImage(change.isMediaImage);
    }
  };

  const redirectToCollections = () => {
    history.push('/collections');
  };

  React.useEffect(() => {
    if (isModerator && searchParams.inflId) {
      updateCollection({ influencerId: searchParams.inflId });
    }
  }, []);

  return (
    <Layout>
      <Header noBorder noPadding>
        <PageTitle  title="Create Collection"/>
      </Header>
      <Content>
          <div className="flex my-4 space-x-2 md:my-0 justify-end">
            {!isModerator && (
              <Button color="green" onClick={createDraft}>
                <FormattedMessage id="collection.saveDraftButton.text" />{' '}
              </Button>
            )}
            <Button
              color="green"
              onClick={isModerator ? createDraft : createDraftAndSubmit}
            >
              <FormattedMessage id="collection.saveButton.text" />
            </Button>
          </div>
        <CollectionHeaderMenu
          activeTab={activeTab}
          onChange={item => {
            setActiveTab(item.value);
            setError(null);
          }}
        />
        {isLoading && <LoaderOverlay />}
        {collection.country && activeTab === 'info' && (
          <div className="flex flex-col">
            {isModerator && (
              <Input
                label={intl.formatMessage({ id: 'collection.influencer' })}
                value={searchParams.inflName}
                readOnly
              />
            )}
            <CollectionForm
              onChange={onCollectionFormChange}
              onError={onError}
              collection={collection}
              files={collectionFiles}
            />
          </div>
        )}
        {collection.country && activeTab === 'products' && (
          <ProductsSearch
            productsError={formValidation.items}
            country={collection.country}
            onProductSelect={newProduct =>
              addProduct(collection.items, newProduct, updateCollection)
            }
            onProductRemove={productToRemove =>
              removeProduct(collection.items, productToRemove, updateCollection)
            }
            selectedProducts={collection.items}
            itemsErrors={[]}
            onProductsOrderChage={orderedProducts =>
              updateCollection({ items: orderedProducts })
            }
          />
        )}
        {!collection.country && (
          <Modal onExit={() => history.goBack()} withExitButton whiteBackground>
            <CountryList onSelect={country => updateCollection({ country })} />
          </Modal>
        )}
      </Content>
    </Layout>
  );
};

export default CreateCollectionPage;
