import * as React from 'react';
import { pick, mergeRight, last } from 'ramda';
import { TARGET_AUDIENCE } from 'config/const';
import {store} from 'lib/redux/index';
import {validateCollectionInput} from 'lib/redux/formValidation/actions';

export const useUpdateCollection = (
  initialValue = null
): [CollectionWithProducts, Function] => {
  const [collection,  setCollection] =
    React.useState<CollectionWithProducts | null>(initialValue);
  const updateCollection = collectionUpdate =>
    setCollection(mergeRight(collection!, collectionUpdate));
  return [collection!, updateCollection];
};

type Tab = 'info' | 'products';

export const useActiveTab = (initialTab: Tab = 'info') => {
  return React.useState<Tab>('info');
};

export const removeProduct = (
  products: Array<ProductResponse>,
  productToRemove: ProductResponse,
  callback: Function
) => {
  const newProducts = [...products].filter(
    product => product.sku !== productToRemove.sku
  );
  callback({ items: newProducts });
};

export const addProduct = (
  products: Array<ProductResponse>,
  newProduct: ProductResponse,
  callback: Function
) => {
  if (products.some(product => product.sku === newProduct.sku)) return;
  const newProducts = [...products, newProduct];
  callback({ items: newProducts });
  store.dispatch(validateCollectionInput('', 'items'));
};

export const initialCollection: any = {
  titleEnglish: '',
  titleArabic: '',
  descriptionEnglish: '',
  descriptionArabic: '',
  country: '',
  imageUrl: '',
  videoUrl: '',
  items: [],
  thumbnailUrl: '',
  audience: [TARGET_AUDIENCE.WOMEN],
};

export class ValidationError extends Error {
  name = 'ValidationError';
}

export const validateDraftCollection = (collection: CollectionPayload) => {
  let isError = false;
  if (!collection.titleEnglish || !collection.titleArabic) {
    if(!collection.titleEnglish) store.dispatch(validateCollectionInput('This field is required', 'titleEnglish'));
    if(!collection.titleArabic) store.dispatch(validateCollectionInput('This field is required', 'titleArabic'));
    isError = true;
  }
  if (!collection.country) {
    isError = true;
  }
  if (!collection.audience || collection.audience.length === 0) {
    isError = true;
    store.dispatch(validateCollectionInput('Please choose the target audience', 'audience'));
  }
  return isError;
};
export const validateItems = (collection: CollectionPayload) => {
  if(!collection.items.length){
    store.dispatch(validateCollectionInput('Collection must contain at least one product', 'items'));
    return true;
  }
  return false;
}
export const validateCollection = (collection: CollectionPayload) => {
  let isError = validateDraftCollection(collection);
  if (!collection.descriptionArabic || !collection.descriptionEnglish) {
    if(!collection.descriptionArabic) store.dispatch(validateCollectionInput('This field is required', 'descriptionArabic'));
    if(!collection.descriptionEnglish) store.dispatch(validateCollectionInput('This field is required', 'descriptionEnglish'));
    isError = true;
  }
  return isError;
};

export const validateCollectionFilePayload = (
  collection: CollectionPayload
) => {
  let error = false;
  if (!collection.videoUrl && !collection.imageUrl) {
    store.dispatch(validateCollectionInput('Media file is required (image or video)', 'media'));
    error = true;
  } 
  if (!collection.thumbnailUrl) {
    store.dispatch(validateCollectionInput('Collection cover image is required', 'cover'));
    error = true;
  } 
  return error;
};

export const buildCollectionPayload = collection => {
  const collectionBody = { ...collection };
  if (collectionBody.items && collectionBody.items.length) {
    collectionBody.items = collectionBody.items.map(pick(['sku']));
  }
  return pick(
    [
      'country',
      'descriptionArabic',
      'descriptionEnglish',
      'items',
      'thumbnailUrl',
      'titleArabic',
      'titleEnglish',
      'videoUrl',
    ],
    collectionBody
  );
};

export const getRejectedReason = (
  collection: CollectionWithProducts
): RejectionReason | null => {
  try {
    const rejectedReason = last(collection.rejectionReasons || []);
    if (!rejectedReason || rejectedReason.alreadySeen) return null;
    return rejectedReason;
  } catch (error) {
    return null;
  }
};
