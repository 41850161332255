export const apiName = 'backoffice-api';

export const endpoints = {
  user: '/users/me',
  users: '/users',
  adminUsers: '/admin/users',
  adminSynchronizations: '/admin/synchronizations',
  createUser: '/users/registrations',
  collections: '/collections',
  products: '/products',
  productsSubpaths: {
    query: '/query',
    skus: '/skus',
  },
  featuredProducts: '/featured-products',
  superadmin: {
    popularity: '/admin/popularity',
    users: '/admin/users',
    synchronizations: '/admin/synchronizations',
    influCollections: '/admin/influencer-collections',
    onBoardInfluencer : '/admin/users/onboardinfluencer/getrequests',
    UpdateonBoardInfluencer : '/admin/users/onboardinfluencer/applicationupdate',
    InsightImage : "presigner/viewimageurl"
  },
  influencerDashboard: {
    performanceReport: '/admin/users/view/performancereport',
    downloadReport: '/admin/users/download/performancereport',
  },
};

export const getCollectionExposureEnpoint = (influencerId: string) =>
  `${endpoints.users}/influencers/${influencerId}/exposures/collections`;

export const getInfToModAssignmentEndpoint = (influencerId: string) =>
  `${endpoints.adminUsers}/${influencerId}/moderators`;

export default {
  apiName,
  endpoints,
};
