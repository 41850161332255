import * as React from 'react';
import { useIntl } from 'react-intl';
import icons from 'components/icons';
import useEnlargeMedia from 'lib/use-enlarge-media';

type Props = {
  fileUrl: string;
  isVideo: boolean;
};

const MediaDisplayer = ({ fileUrl, isVideo }: Props) => {
  const intl = useIntl();
  const [isFormatSupported, setIsFormatSupported] = React.useState(true);

  React.useEffect(() => {
    setIsFormatSupported(true); // Reset message. If video will load (or not), message will be updated.
  }, [fileUrl, isVideo]);

  const onVideoError = () => {
    setIsFormatSupported(false);
  };

  const { enlargePreview, isEnlarged, renderEnlargedPreview } = useEnlargeMedia(
    !isVideo,
    fileUrl,
    onVideoError
  );

  const renderEnlargementButton = () => (
    <div
      onClick={enlargePreview}
      className="w-16 h-16 absolute right-16 bottom-0 text-white font-bold cursor-pointer rounded-md bg-opacity-50 bg-green"
    >
      {icons.search}
    </div>
  );

  const renderUrl = (fileUrl: string) => (
    <a href={fileUrl} target="_blank" className="text-secondary text-xs">
      {intl.formatMessage({ id: 'upload.url' })}
      {fileUrl}
    </a>
  );

  // First <Video> is only to get frame to have thumbnail on button.
  return isVideo ? (
    <>
      {isEnlarged && renderEnlargedPreview()}
      {!isFormatSupported && (
        <>
          {renderUrl(fileUrl)}
          <a href={fileUrl} target="_blank" className="w-64 h-64">
            <div className="w-64 h-64 flex cursor-pointer">
              <div className="w-full h-full flex items-center justify-center relative box-border items-center border border-green border-dashed bg-green bg-opacity-5 outline-none focus:outline-none pointer-events-none">
                <span className="text-base text-green outline-none focus:outline-none pointer-events-none">
                  {intl.formatMessage({ id: 'upload.codecNotSupported' })}
                </span>
              </div>
            </div>
          </a>
        </>
      )}
      {isFormatSupported && (
        <div className="w-64 flex relative">
          <video className="w-full h-full" preload="metadata">
            <source
              src={fileUrl}
              className="object-scale-down"
              onError={onVideoError}
            />
          </video>
          {renderEnlargementButton()}
        </div>
      )}
    </>
  ) : (
    <>
      {isEnlarged && renderEnlargedPreview()}
      <div className="w-64 flex relative">
        <img src={fileUrl} className="object-scale-down" />
        {renderEnlargementButton()}
      </div>
    </>
  );
};

export default MediaDisplayer;
