import * as React from 'react';
import { path } from 'ramda';
import { checkErrorArray } from 'utils/validation';
import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import { useHistory, useParams } from 'react-router-dom';
import { CollectionHeaderMenu } from 'components/header-menu';
import HeaderCollectionButtons from 'components/collection/header-buttons';
import { LoaderOverlay } from 'components/loader';
import ErrorBox from 'components/error-box';
import UserDetails from 'components/user-details';
import ProductsSearch from 'components/products-search';
import { errorToast, successToast, infoToast } from 'components/toast/toast';
import CollectionForm, {
  CollectionFiles,
  CollectionStateChange,
} from 'components/collection-form';
import { useIntl } from 'react-intl';
import icons from 'components/icons';
import restClient from 'lib/rest-client';
import { useUploadCollectionFiles } from 'lib/file-upload';
import { endpoints } from 'config/api';
import {
  addProduct,
  removeProduct,
  validateCollectionFilePayload,
  useUpdateCollection,
  useActiveTab,
  validateDraftCollection,
  validateCollection,
  getRejectedReason,
} from 'utils/collection';
import { fetchProductsBySkus } from 'utils/product';
import { useSelector } from 'react-redux';
import { RejectionModal } from 'components/modal';
import { getResponseErrorMessage } from 'utils/common';
import { isModeratorSelector, langSelector } from 'lib/redux';
import {
  AVAILABLE_LANGUAGES,
  COLLECTION_STATE,
  USER_ROLES,
} from 'config/const';
import { StateBadge } from 'components/badge';
import Duplication from 'components/duplication';
import PageTitle from 'components/PageTitle';
import ShareCollectionLink from 'components/ShareCollectionLink';
import ConfirmationModal from 'components/ConfirmationModal';

const EditCollectionPage = () => {
  const { collectionId: collectionIdFromParams } = useParams<{
    collectionId: string;
  }>();
  const [collection, updateCollection] = useUpdateCollection();
  const history = useHistory();
  const intl = useIntl();
  const lang = useSelector(langSelector);
  const userRole = useSelector(path(['user', 'role']));
  const isModerator = useSelector(isModeratorSelector);
  const [activeTab, setActiveTab] = useActiveTab();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [showRejectModal, setShowRejectModal] = React.useState(false);
  const [showDuplicateModal, setShowDuplicateModal] = React.useState(false);
  const [showConfirmationModal, setShowConfirmationModal]= React.useState(false);
  const [collectionLink, setCollectionLink] = React.useState<null | string>(null);
  const [itemsErrors, setItemsErrors] = React.useState<any>(null);
  const rejectedReason = getRejectedReason(collection);
  const collectionState = collection && collection.state;
  const isFrozen = collection && collection.frozen;

  React.useEffect(() => {
    errorToast(error);
  }, [error])
  
  const isReadOnly =
    collectionState === COLLECTION_STATE.PUBLISHING ||
    (userRole === USER_ROLES.INFLUENCER &&
      collectionState === COLLECTION_STATE.IN_REVIEW);

  const {
    collectionMedia,
    isCollectionMediaImage,
    coverImage,
    setCollectionMedia,
    setIsCollectionMediaImage,
    setCoverImage,
    uploadFiles,
  } = useUploadCollectionFiles();

  const collectionFiles: CollectionFiles = {
    media: collectionMedia,
    isMediaImage: isCollectionMediaImage,
    coverImage,
  };

  const fetchItemsDetails = collectionDetails =>
    fetchProductsBySkus(
      (collectionDetails.items || []).map(item => item.sku),
      collectionDetails.country
    );

  const fetchCollectionDetails = async (hideLoaderOnFinish = true) => {
    try {
      setIsLoading(true);
      setError(null);
      const collectionDetails: CollectionResponse = await restClient.get(
        `${endpoints.collections}/${collectionIdFromParams}`
      );
      setCollectionLink(collectionDetails.storeLink!);
      const itemsDetails = await fetchItemsDetails(collectionDetails);
      setItemsErrors(collectionDetails.items);
      updateCollection({ ...collectionDetails, items: itemsDetails });
    } catch (error) {
      setError(getResponseErrorMessage(error));
    } finally {
      hideLoaderOnFinish && setIsLoading(false);
    }
  };

  const updateCollectionDetails = async (
    asDraft = true,
    isSubFunction = false
  ) => {
    try {
      setError(null);
      setIsLoading(true);
      asDraft
        ? validateDraftCollection(collection)
        : validateCollection(collection);
      const fileUrlsChange = await uploadFiles();
      const collectionWithFileUrls = {
        ...collection,
        ...fileUrlsChange,
      };
      if (!asDraft) {
        validateCollectionFilePayload(collectionWithFileUrls);
      }
      const updatedCollection = await restClient.put(
        `${endpoints.collections}/${collection.id}`,
        { body: collectionWithFileUrls }
      );
      const itemsDetails = await fetchItemsDetails(updatedCollection);
      updateCollection({ ...updatedCollection, items: itemsDetails });
    } catch (error: any) {
      if (isSubFunction) {
        throw error;
      } else {
        error.response && checkErrorArray(error.response.data, setError);
      }
    } finally {
      !isSubFunction && setIsLoading(false);
    }
  };

  const updateDraftAndSubmit = async () => {
    try {
      await updateCollectionDetails(false, true);
      const { state } = await restClient.put(
        `${endpoints.collections}/${collection.id}/submission`
      );
      updateCollection({ state });
    } catch (error : any) {
      error.response && checkErrorArray(error.response.data, setError);

    } finally {
      setIsLoading(false);
    }
  };

  const editLiveCollection = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const fileUrlsChange = await uploadFiles();
      const body = {
        ...collection,
        ...fileUrlsChange,
      };
      const { id: newId } = await restClient.put(
        `${endpoints.collections}/${collection.id}/live`,
        { body }
      );
      if (isModerator) {
        await restClient.put(`${endpoints.collections}/${newId}/submission`);
        await restClient.put(`${endpoints.collections}/${newId}/approval`);
      }
      setIsLoading(false);
      redirectToCollections();
    } catch (error: any) {
      error.response && checkErrorArray(error.response.data, setError);
      setIsLoading(false);
    }
  };

  const deleteCollection = async () => {
    try {
      setError(null);
      setIsLoading(true);
      await restClient.delete(`${endpoints.collections}/${collection.id}`);
      redirectToCollections();
    } catch (error) {
      setError(getResponseErrorMessage(error));
      setIsLoading(false);
    }
  };

  const withdrawCollection = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const { state } = await restClient.put(
        `${endpoints.collections}/${collection.id}/withdrawal`
      );
      updateCollection({ state });
    } catch (error) {
      setError(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const moderatorRejectCollection = async (feedback: RejectionReason) => {
    try {
      setError(null);
      setIsLoading(true);
      setShowRejectModal(false);
      await restClient.put(
        `${endpoints.collections}/${collection.id}/rejection`,
        { body: feedback }
      );
      redirectToCollections();
    } catch (error) {
      setError(getResponseErrorMessage(error));
      setIsLoading(false);
    }
  };

  const moderatorSaveAndApproveCollection = async () => {
    try {
      setError(null);
      setIsLoading(true);
      await updateCollectionDetails(false, true);
      await restClient.put(
        `${endpoints.collections}/${collection.id}/approval`
      );
      redirectToCollections();
    } catch (error: any) {
      error.response && checkErrorArray(error.response.data, setError);
      setIsLoading(false);
    }
  };

  const redirectToCollections = () => {
    history.goBack();
  };

  const onError = (error: string) => {
    setError(error);
  };

  const onCollectionFormChange = (change: any) => {
    if (change.collection) {
      updateCollection(change.collection);
    }
    if (change.coverImage) {
      setCoverImage(change.coverImage);
    }
    if (change.media) {
      setCollectionMedia(change.media);
      setIsCollectionMediaImage(change.isMediaImage);
    }
  };

  React.useEffect(() => {
    fetchCollectionDetails();
  }, []);

  return (
    <>
      {showDuplicateModal && collection && (
        <Duplication
          onExit={() => setShowDuplicateModal(false)}
          type="collection"
          sourceCountry={collection.country}
          id={collection.id}
        />
      )}
      <Layout>
        <Header noBorder noPadding>
          <PageTitle title="Edit collection"/>
        </Header>
        <Content>
        <div className="flex justify-end flex-wrap">
            <HeaderCollectionButtons
              userRole={userRole}
              collectionState={collectionState}
              onDraftUpdate={() => updateCollectionDetails()}
              onDraftSubmit={updateDraftAndSubmit}
              onLiveUpdate={editLiveCollection}
              onDelete={() => setShowConfirmationModal(true)}
              onWithdraw={withdrawCollection}
              onReject={() => setShowRejectModal(true)}
              onApprove={moderatorSaveAndApproveCollection}
              onDuplicate={() => setShowDuplicateModal(true)}
              isFrozen={isFrozen}
            />
            {collectionState === COLLECTION_STATE.PUBLISHING && (
              <StateBadge
                state={COLLECTION_STATE.PUBLISHING as CollectionState}
              />
            )}
          </div>
          <CollectionHeaderMenu
            activeTab={activeTab}
            onChange={item => setActiveTab(item.value)}
          />
          <div>
            {isLoading && <LoaderOverlay />}
            {isModerator && showRejectModal && (
              <RejectionModal
                onExit={() => setShowRejectModal(false)}
                onRejectSubmit={moderatorRejectCollection}
              />
            )}
            {!isModerator && rejectedReason && (
              <ErrorBox>
                {lang === AVAILABLE_LANGUAGES.ARABIC
                  ? rejectedReason.messageArabic
                  : rejectedReason.messageEnglish}
              </ErrorBox>
            )}
            {collection && activeTab === 'info' && (
              <div className="flex flex-col mt-2">
                <div className={isModerator ? "flex md:flex-row flex-col justify-between items-center" : "flex md:flex-row flex-col justify-end items-center"}>
                  {isModerator && <UserDetails user={collection.owner} />}
                  <ShareCollectionLink collectionLink={collectionLink}/>
                </div>
                <CollectionForm
                  onChange={onCollectionFormChange}
                  onError={onError}
                  collection={collection}
                  files={collectionFiles}
                />
              </div>
            )}
            {collection && activeTab === 'products' && (
              <ProductsSearch
                country={collection.country}
                onProductSelect={newProduct =>
                  addProduct(collection.items, newProduct, updateCollection)
                }
                onProductRemove={productToRemove =>
                  removeProduct(
                    collection.items,
                    productToRemove,
                    updateCollection
                  )
                }
                selectedProducts={collection.items}
                itemsErrors={itemsErrors}
                onProductsOrderChage={orderedProducts =>
                  updateCollection({ items: orderedProducts })
                }
                readOnly={isReadOnly}
              />
            )}
          </div>
          {showConfirmationModal && <ConfirmationModal text="Are you sure?" onAccept={deleteCollection} onReject={() => setShowConfirmationModal(false)}/>}
        </Content>
      </Layout>
    </>
  );
};

export default EditCollectionPage;
