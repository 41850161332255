import * as React from 'react';
import cn from 'classnames';

import Navbar from 'components/Navbar/Navbar';

type Props = {
  children?: React.ReactNode;
  noBorder?: boolean;
  noPadding?: boolean;
};

const Header = ({ children, noBorder, noPadding }: Props) => {
  return (
    <div
      className={cn('flex flex-col-reverse md:flex-row justify-between p-1 md:p-5 border-b border-border items-between md:items-center', {
        'border-none': noBorder,
        'md:p-0': noPadding,
      })}
    >
      {children}
      <Navbar/>
    </div>
  );
};

export { Header as default };
