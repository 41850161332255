import { AVAILABLE_LANGUAGES } from 'config/const';
import * as React from 'react';
import { useIntl } from 'react-intl';

type Props = {
  user: User | AssingedModerator;
  className?: string;
};

export const UserName = ({ user, className }: Props) => {
  const intl = useIntl();
  const isArabic = React.useMemo(
    () => intl.locale === AVAILABLE_LANGUAGES.ARABIC,
    [intl.locale]
  );
  if (!user) return null;
  return (
    <span className={className}>
      {isArabic
        ? `${user.nameArabic} ${user.familyNameArabic}`
        : `${user.nameEnglish} ${user.familyNameEnglish}`}
    </span>
  );
};
