import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faStar } from '@fortawesome/free-solid-svg-icons'

type Props = {
    superstar: boolean;
    toggleSuperstar: any;
}

const StarIcon = ({toggleSuperstar, superstar} : Props) => {
    return (
        <FontAwesomeIcon icon={faStar} className="cursor-pointer" color={superstar ? "gold" : "gray"} onClick={toggleSuperstar} size="2x"/>
        );
}

export default StarIcon;