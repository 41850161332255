import * as React from 'react';
import { Storage } from 'aws-amplify';
import { REACT_APP_CDN_HOST } from 'config/const';

const maxFileNameLength = 100; // It is only to be sure, that length of string 'host + path + filename' will not exceed max URL length.

export const uploadFileToPublicS3 = async (file: File, isImage: boolean) => {
  const fileTypePart = isImage ? 'images' : 'videos';
  let fileName = file.name;
  if (fileName.length > maxFileNameLength) {
    const partsByDot = fileName.split('.');
    const extension = partsByDot[partsByDot.length].substr(
      0,
      maxFileNameLength
    );
    const targetBasenameLen = maxFileNameLength - extension.length - 1; // -1 because of dot before extension
    fileName = fileName.substr(0, targetBasenameLen) + '.' + extension;
  }
  fileName = fileName.replace(/[^\w-@#().,]/g, '_'); // for security and OS/browser compatibility reasons
  const date = getDateString();
  const filePath = `resources/${fileTypePart}/${date}-${randomizeId(
    10
  )}-${fileName}`;
  await uploadFile(filePath, file);
  // Backend wants to have URL, because S3 key is unusable in mobile application.
  return `${REACT_APP_CDN_HOST}/public/${filePath}`;
};

const getDateString = () => {
  const date = new Date();
  const year = date.getUTCFullYear();
  const month = 1 + date.getUTCMonth();
  const day = date.getUTCDate();
  return `${year}-${padTo2(month)}-${padTo2(day)}`;
};

const padTo2 = (number: number) => {
  return number.toString().padStart(2, '0');
};

const uploadFile = async (filePath: string, file: File) => {
  type S3PutResponse = {
    key: string;
  };
  const response = await Storage.put(filePath, file, {
    contentType: file.type,
  });
  return (response as S3PutResponse).key;
};

/** To make sure that two files sent at same moment have different IDs */
const randomizeId = (length: Number) => {
  var result = '';
  var characters =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charactersLength = characters.length;
  for (var i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }
  return result;
};

/** Use it as a hook */
export const useUploadCollectionFiles = () => {
  const [collectionMedia, setCollectionMedia] = React.useState(null);
  const [isCollectionMediaImage, setIsCollectionMediaImage] =
    React.useState(false);
  const [coverImage, setCoverImage] = React.useState(null);

  /** \return Change to collection object */
  const uploadFiles = async () => {
    const uploadsInProgress = {}; // key is name of target collection field, value is promise of upload
    if (collectionMedia !== null) {
      const fileFieldName = isCollectionMediaImage ? 'imageUrl' : 'videoUrl';
      uploadsInProgress[fileFieldName] = uploadFileToPublicS3(
        collectionMedia!,
        isCollectionMediaImage
      );
    }
    if (coverImage !== null) {
      uploadsInProgress['thumbnailUrl'] = uploadFileToPublicS3(
        coverImage!,
        true
      );
    }

    const difference = {};
    for (const fieldName in uploadsInProgress) {
      const url = await uploadsInProgress[fieldName];
      difference[fieldName] = url;
    }
    if (collectionMedia !== null) {
      const fieldToClear = isCollectionMediaImage ? 'videoUrl' : 'imageUrl';
      difference[fieldToClear] = '';
    }
    return difference;
  };

  return {
    collectionMedia,
    coverImage,
    isCollectionMediaImage,
    setCollectionMedia,
    setIsCollectionMediaImage,
    setCoverImage,
    uploadFiles,
  };
};

export const getLinkForFile = async (file: Blob) => {
  const reader = new FileReader();
  const promise: Promise<string> = new Promise(resolve => {
    reader.onload = () => {
      resolve(reader.result as string);
    };
  });
  reader.readAsDataURL(file);
  return promise;
};

/** \throws Error when cannot load file. */
export const getImageDimensions = async (
  file: File
): Promise<{ width: number; height: number }> => {
  const fileAsUrl = await getLinkForFile(file);
  const image = new Image();
  const imgLoading = new Promise<{ width: number; height: number }>(resolve => {
    image.onload = () => {
      resolve({ width: image.width, height: image.height });
    };
  });
  image.src = fileAsUrl;
  return imgLoading;
};
