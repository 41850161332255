import { Auth } from 'aws-amplify';

const AWSConfig = {
  Auth: {
    identityPoolId: process.env.REACT_APP_AWS_AUTH_IDENTITYPOOLID,
    region: process.env.REACT_APP_AWS_AUTH_REGION,
    userPoolId: process.env.REACT_APP_AWS_AUTH_USERPOOLID,
    userPoolWebClientId: process.env.REACT_APP_AWS_AUTH_USERPOOLWEBCLIENTID,
  },
  Storage: {
    bucket: process.env.REACT_APP_AWS_BUCKET_NAME,
    region: process.env.REACT_APP_AWS_S3_REGION,
  },
  API: {
    endpoints: [
      {
        name: 'backoffice-api',
        endpoint: process.env.REACT_APP_API_URL,
        custom_header: async () => {
          return {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getAccessToken()
              .getJwtToken()}`,
          };
        },
      },
    ],
  },
};

export default AWSConfig;
