import * as React from 'react';
import { Auth } from 'aws-amplify';
import Input from 'components/input';
import Button from 'components/button';
import Text from 'components/translated-text';
import { useIntl } from 'react-intl';
import ErrorBox from 'components/error-box';

type Props = {
  onSuccessCallback?: () => void;
};

const ChangePassword = ({ onSuccessCallback }: Props) => {
  const [currentPassword, setCurrentPassword] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [confirmNewPassword, setConfirmNewPassword] = React.useState('');
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const intl = useIntl();

  const changePassword = async () => {
    try {
      setError(null);
      setIsLoading(true);
      const user = await Auth.currentAuthenticatedUser();
      await Auth.changePassword(user, currentPassword, newPassword);
      setIsLoading(false);
      onSuccessCallback && onSuccessCallback();
    } catch (error: any) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const isButtonEnabled =
    !isLoading &&
    currentPassword &&
    newPassword &&
    confirmNewPassword &&
    newPassword === confirmNewPassword;

  return (
    <div className="mx-auto flex flex-col justify-center md:max-w-300">
      {error && <ErrorBox>{error}</ErrorBox>}
      <Input
        type="password"
        value={currentPassword}
        onChange={evt => setCurrentPassword(evt.target.value)}
        label={intl.formatMessage({ id: 'changePassword.currentPassword' })}
        readOnly={isLoading}
        fullWidth
      />
      <Input
        type="password"
        value={newPassword}
        onChange={evt => setNewPassword(evt.target.value)}
        label={intl.formatMessage({ id: 'changePassword.newPassword' })}
        readOnly={isLoading}
        fullWidth
      />
      <Input
        type="password"
        value={confirmNewPassword}
        onChange={evt => setConfirmNewPassword(evt.target.value)}
        label={intl.formatMessage({ id: 'changePassword.confirmNewPassword' })}
        readOnly={isLoading}
        fullWidth
      />
      <Button disabled={!isButtonEnabled} onClick={changePassword}>
        <Text
          id={!isLoading ? 'changePassword.change' : 'changePassword.changing'}
        />
      </Button>
    </div>
  );
};

export default ChangePassword;
