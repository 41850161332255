import { useState, useEffect } from "react";

const getWindowDimmensions = () => {
    const {innerWidth: width, innerHeight: height} = window;
    return {
        width,
        height
    };
};

export default () => {
    const [windowDimmensions, setWindowDimmenssions] = useState(getWindowDimmensions());
    function handleResize(){
        setWindowDimmenssions(getWindowDimmensions());
    }
    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return windowDimmensions;
}