import cn from 'classnames';

type Props = any;

export default function TextInput({
  label,
  inputRef,
  error,
  value,
  onChange = () => {},
  maxLenght = 50,
  readOnly,
  type = 'text',
}: Props) {
  return (
    <label className="flex flex-col items-start space-y-1 md:w-80">
      <span className="text-sm">{label}</span>
      <input
        ref={inputRef}
        className={cn(
          'w-full p-3 border-border border-2 rounded-md transition-colors duration-300',
          {
            'focus-within:border-black': !readOnly,
            'bg-lightGray cursor-not-allowed focus-within:border-gray':
              readOnly,
          }
        )}
        value={value}
        onChange={onChange}
        maxLength={maxLenght}
        readOnly={readOnly}
        disabled={readOnly}
        type={type}
      />
      {!!error && <span className="text-error text-sm">{error}</span>}
    </label>
  );
}
