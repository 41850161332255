import * as React from 'react';
import cx from 'classnames';
import icons from './icons';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { isArabicSelector } from 'lib/redux';

export type Option = { name: string; value: any };

type Props = {
  options: Array<Option>;
  value: any;
  onChange: (value: any) => void;
  className?: string;
};

const Select = ({ className, options, value, onChange }: Props) => {
  const intl = useIntl();
  const isArabic: boolean = useSelector(isArabicSelector);
  const [isOpen, setIsOpen] = React.useState(false);
  const selectedOption = options.find(option => option.value === value);

  const handleChange = (option: Option) => {
    setIsOpen(false);
    onChange(option.value);
  };

  return (
    <div
      className={cx(
        'relative cursor-pointer min-w-half md:min-w-250',
        className
      )}
      onClick={() => setIsOpen(!isOpen)}
    >
      <div
        className={cx(
          'px-4 py-2 md:py-4 border border-border rounded-md text-sm md:text-base',
          {
            'rounded-b-none border-black': isOpen,
          }
        )}
      >
        {selectedOption
          ? selectedOption.name
          : intl.formatMessage({ id: 'common.selectOption' })}
      </div>
      <span
        className={cx(
          'absolute z-10 top-0 w-6 h-6 m-2 md:m-4 text-black bg-white hover:text-black transition-colors duration-200',
          { 'right-0': !isArabic, 'left-0': isArabic }
        )}
      >
        {isOpen ? icons.chevronUp : icons.chevronDown}
      </span>
      {isOpen && (
        <div className="absolute z-20 left-0 bg-white w-full px-4 border border-border shadow-sm border-t-0 rounded-b-md max-h-300 overflow-x-hidden overscroll-y-auto">
          <ul className="p-0 m-0 list-none">
            {options.map(option => (
              <li
                className={cx(
                  'py-2 md:py-4 hover:text-higlight transition-colors duration-200 text-sm md:text-base',
                  {
                    'text-higlight': option.value === value,
                  }
                )}
                key={option.value}
                onClick={() => handleChange(option)}
              >
                {option.name}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export { Select as default };
