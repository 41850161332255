import { endpoints } from 'config/api';
import restClient from 'lib/rest-client';
import * as React from 'react';
import { FormattedMessage } from 'react-intl';
import { getResponseErrorMessage } from 'utils/common';
import CountryList from './country-list';
import Heading from './heading';
import { LoaderOverlay } from './loader';
import Modal from './modal';
import { errorToast, successToast, warningToast } from './toast/toast';

type Props = {
  id: string;
  type: 'collection' | 'featuredProducts';
  onExit: () => void;
  sourceCountry: Country;
  duplicationCallback?: Function;
};

type DuplicationResponse = {
  results: Array<{
    country: Country;
    missingProducts: Array<string>;
    newCollectionId: string;
    resultDescription: string;
    resultType: 'SUCCESS' | 'MISSING_PRODUCTS' | 'COLLECTION_ALREADY_EXISTS';
  }>;
};

export default function Duplication({
  id,
  type,
  onExit,
  sourceCountry,
  duplicationCallback,
}: Props) {
  const [isLoading, setIsLoading] = React.useState(false);

  const handleCountrySelect = async (countryCode: Country) => {
    try {
      const url = `${
        type === 'featuredProducts'
          ? endpoints.featuredProducts
          : endpoints.collections
      }/${id}/duplicate`;

      const response: DuplicationResponse = await restClient.put(url, {
        body: { countryCodes: [countryCode] },
      });
      handleResponseMessage(response);
      if (typeof duplicationCallback === 'function') {
        duplicationCallback();
      }
    } catch (e) {
      errorToast(getResponseErrorMessage(e));
    } finally {
      setIsLoading(false);
      onExit();
    }
  };

  const handleResponseMessage = (response: DuplicationResponse) => {
    const { results } = response;
    if (!results) {
      errorToast(<FormattedMessage id="common.genericError" />);
      return;
    }

    results.forEach(result => {
      const { resultDescription, resultType, missingProducts } = result;
      const toast =
        resultType === 'COLLECTION_ALREADY_EXISTS'
          ? warningToast
          : successToast;

      toast(
        <div className="flex flex-col space-y-2">
          <span>{resultDescription}</span>
          {resultType === 'MISSING_PRODUCTS' &&
            missingProducts &&
            !!missingProducts.length &&
            missingProducts.map(sku => (
              <div key={sku} className="font-bold">
                {sku}
              </div>
            ))}
        </div>
      );
    });
  };

  return (
    <>
      <Modal onExit={() => onExit()} withExitButton>
        <div className="flex flex-col">
          <Heading>
            <FormattedMessage id="collection.duplicate.selectTargetCountry" />
          </Heading>
          <CountryList
            onSelect={handleCountrySelect}
            skipCountries={[sourceCountry]}
            noTitle
          />
        </div>
      </Modal>
      {isLoading && <LoaderOverlay />}
    </>
  );
}
