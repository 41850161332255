import { AuthButton as Button } from 'components/button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import TextInput from 'components/inputs/text-input';
import { useValidation } from 'utils/validation';

type FormData = {
  number: string;
  issueAuthority: string;
  expiryDate: string;
};

const emptyFormData: FormData = {
  number: '',
  issueAuthority: '',
  expiryDate: '',
};

type Props = {
  initialData?: FormData;
  onFormSubmit: (data: { passport: FormData }) => Promise<void>;
};

export default function PassportForm({
  initialData = emptyFormData,
  onFormSubmit,
}: Props) {
  const { requiredValidation } = useValidation();

  const schema: yup.SchemaOf<FormData> = yup.object().shape({
    number: requiredValidation,
    issueAuthority: requiredValidation,
    expiryDate: requiredValidation,
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    control,
  } = useForm<FormData>({
    defaultValues: initialData,
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(data => onFormSubmit({ passport: data }))}>
      <div className="flex flex-col items-start mt-4">
        <h3 className="mb-6 text-2xl font-semibold">Passport</h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-x-10">
          <Controller
            name="number"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Number"
                  error={errors.number?.message}
                />
              );
            }}
          />
          <Controller
            name="issueAuthority"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Issue Authority"
                  error={errors.issueAuthority?.message}
                />
              );
            }}
          />
          <Controller
            name="expiryDate"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Expiry Date"
                  error={errors.expiryDate?.message}
                />
              );
            }}
          />
        </div>
        <div className="mt-8">
          <Button type="submit" disabled={!isDirty}>
            <FormattedMessage id="account.submitPassportForm" />
          </Button>
        </div>
      </div>
    </form>
  );
}
