import * as React from 'react';
import { isEmpty } from 'ramda';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/layout';
import Content from 'components/content';
import Header from 'components/header';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { getResponseErrorMessage, useCountries } from 'utils/common';
import { useSelector } from 'react-redux';
import { userSelector } from 'lib/redux';
import UserDetails from 'components/user-details';
import CollectionCard from 'components/collection-card';
import { LoaderOverlay } from 'components/loader';
import { FormattedMessage } from 'react-intl';
import { COLLECTION_STATE } from 'config/const';
import { errorToast } from 'components/toast/toast';
import PageTitle from 'components/PageTitle';
import {AddCollectionButtonCard} from 'components/add-collection-button';
import { AVAILABLE_COUNTRIES } from 'config/const';
import CountryPickerList from 'components/CountryPicker/CountryPickerList';
import { pickCountry } from 'lib/redux/collectionsOrderFilter/actions';


const filterCollections = (
  featuredProductsList: Array<FeaturedProductsCollection>
) => {
  const isEditable = fp =>
    !featuredProductsList.find(({ parentId }) => parentId === fp.id);

  return featuredProductsList.reduce((results, fp) => {
    if (fp.state !== COLLECTION_STATE.DRAFT && isEditable(fp)) {
      results.push(fp);
    }
    return results;
  }, [] as Array<any>);
};

const ModeratorFeaturedProducts = () => {
  const currentModerator = useSelector(userSelector);
  const history = useHistory();
  const { influencerId } = useParams<{ influencerId: string }>();
  const [isLoading, setIsLoading] = React.useState(true);
  const [influencer, setInfluencer] = React.useState<User>();
  const [featuredProductsList, setFeaturedProductsList] = React.useState<
    Array<FeaturedProductsCollection>
  >([]);
  const [selectedCountry, setSelectedCountry] = React.useState<Country>('ARE');
  const countries = useCountries();
  const [button, setButton] = React.useState<boolean>(true);
  const [avaAudience, setAvaAudience] = React.useState<any>([]);


  const checkButton = (data) => {
    const checkArr : any = [];
    const avaCountry: any = [];
    data!.forEach((item) => {
        item.audience.forEach((item) => ['WOMEN', 'MEN', 'KIDS'].includes(item) && !checkArr.includes(item) ? checkArr.push(item) : avaCountry.push(item));
    })
    if(checkArr.length === 3){
      setButton(false);
    } else {
      setAvaAudience(avaCountry);
    }
}

  const fetchInfluencer = async () => {
    const { items } = await restClient.get(endpoints.users);
    const influencer = items.find(user => user.id === influencerId);
    setInfluencer(influencer);
  };

  const fetchCollections = async () => {
    const data: Array<FeaturedProductsCollection> = await restClient.get(
      endpoints.featuredProducts,
      {
        queryStringParameters: {
          country: selectedCountry,
          influencer: influencerId,
        },
      }
    );
    const resp = data.filter((item) => item.country === selectedCountry)
    setFeaturedProductsList(resp);
    checkButton(resp);
  };

  const createNewFeatureProduct = () => {
    history.push(`/featured-edit?inflName=${influencer?.influencerNameEnglish}&inflId=${influencer?.id}&country=${selectedCountry}`);
  }

  const fetchData = async () => {
    try {
      setIsLoading(true);
      await Promise.all([fetchInfluencer(), fetchCollections()]);
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleItemClick = (fp: FeaturedProductsCollection) => {
    history.push(`/featured-edit?editId=${fp.id}&country=${fp.country}`);
  };

  React.useEffect(() => {
    setButton(true);
    fetchData();
  }, [influencerId, selectedCountry]);

  return (
    <Layout>
      <Header>
        <PageTitle title="Featured Products"/>
      </Header>
      <Content>
        {influencer && (
          <div className="mb-8 flex justify-between items-center">
            <CountryPickerList pickedCountry={selectedCountry} countryList={countries} onCountryPick={setSelectedCountry}/>
            <UserDetails user={influencer} />
          </div>
        )}
        {isLoading && <LoaderOverlay />}
        {!isLoading && isEmpty(featuredProductsList) && (
          <h1 className="mb-10">
            <FormattedMessage id="featuredProducts.emptyFPList" />
          </h1>
        )}
          {!isLoading && !isEmpty(featuredProductsList) ? (
            <div className="flex flex-wrap">
              <AddCollectionButtonCard onClick={createNewFeatureProduct} isActive={button}/>
              {featuredProductsList.map(fp => (
                <CollectionCard
                  onClick={handleItemClick as any}
                  collection={fp}
                  key={fp.id}
                  isFP
                  canDuplicate
                />
              ))}
            </div>
          ) : <AddCollectionButtonCard onClick={createNewFeatureProduct} isActive={button}/>}
      </Content>
    </Layout>
  );
};

export { ModeratorFeaturedProducts as default };
