import * as React from 'react';
import { isEmpty } from 'ramda';
import { useHistory, useParams } from 'react-router-dom';
import Layout from 'components/layout';
import Content from 'components/content';
import Header from 'components/header';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { getResponseErrorMessage } from 'utils/common';
import { useSelector } from 'react-redux';
import { userSelector } from 'lib/redux';
import UserDetails from 'components/user-details';
import CollectionCard from 'components/collection-card';
import { LoaderOverlay } from 'components/loader';
import { FormattedMessage } from 'react-intl';
import ErrorBox from 'components/error-box';
import { COLLECTION_STATE } from 'config/const';
import PageTitle from 'components/PageTitle';
import { AddCollectionButtonCard } from 'components/add-collection-button';

const filterCollections = (collections: Array<CollectionResponse>) => {
  const isEditable = collection =>
    !collections.find(({ parentId }) => parentId === collection.id);

  return collections.reduce((results, collection) => {
    if (isEditable(collection)) {
      results.push(collection);
    }
    return results;
  }, [] as Array<any>);
};

const ModeratorCollections = () => {
  const user = useSelector(userSelector);
  const history = useHistory();
  const { influencerId } = useParams<{ influencerId: string }>();
  const [influencer, setInfluencer] = React.useState<User>();
  const [collections, setCollections] = React.useState<
    Array<CollectionResponse>
  >([]);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(true);

  const fetchInfluencer = async () => {
    const {items} = await restClient.get(endpoints.users);
    const influencer = items.find(user => user.id === influencerId);
    setInfluencer(influencer);
  };

  const fetchCollections = async () => {
    const {items}: { items: Array<CollectionResponse> } = await restClient.get(
      endpoints.collections,
      {
        queryStringParameters: {
          influencer: influencerId,
        },
      }
    );
    const filteredCollections = filterCollections(items);
    setCollections(filteredCollections);
  };

  const fetchData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      await Promise.all([fetchInfluencer(), fetchCollections()]);
    } catch (error) {
      setError(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };
  const createNewCollection = () => {
    history.push(`/create-collection?inflName=${influencer?.influencerNameEnglish}&inflId=${influencer?.id}`);
  }
  const handleCollectionClick = (collection: CollectionResponse) => {
    history.push(`/collections/edit/${collection.id}`);
  };

  React.useEffect(() => {
    fetchData();
  }, [influencerId]);

  return (
    <Layout>
      <Header>
        <PageTitle title="User Collections"/>
      </Header>
      <Content>
        {influencer && (
          <div className="mb-8">
            <UserDetails user={influencer} />
          </div>
        )}
        {error && <ErrorBox>{error}</ErrorBox>}
        {isLoading && <LoaderOverlay />}
        {!isLoading && isEmpty(collections) && (
          <h1 className="mb-10">
            <FormattedMessage id="collection.noCollections" />
          </h1>
        )}
        {!isLoading && !isEmpty(collections) ? (
          <div className="flex flex-wrap">
            <AddCollectionButtonCard onClick={createNewCollection} isActive={true}/>
            {collections.map(collection => (
              <CollectionCard
                onClick={handleCollectionClick as any}
                collection={collection}
                key={collection.id}
                canDuplicate
                duplicationCallback={fetchCollections}
              />
            ))}
          </div>
        ) : <AddCollectionButtonCard onClick={createNewCollection} isActive={true}/>}
      </Content>
    </Layout>
  );
};

export { ModeratorCollections as default };
