import * as React from 'react';
import Label from 'components/label';

type Props = any;

const RadioButton = ({ label, onChange, ...rest }: Props) => {
  return (
    <div className="flex items-center">
      <input type="radio" onChange={onChange} {...rest} />
      {label && (
        <Label onClick={onChange} className="ml-2 mr-4">
          {label}
        </Label>
      )}
    </div>
  );
};

export default RadioButton;
