import * as React from 'react';
import { isEmpty } from 'ramda';
import { useHistory, useParams } from 'react-router-dom';
import Modal from "components/modal";
import Layout from 'components/layout';
import Content from 'components/content';
import Header from 'components/header';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { getResponseErrorMessage } from 'utils/common';
import { useSelector } from 'react-redux';
import { userSelector } from 'lib/redux';
import UserDetails, {UserDetailsMinor} from 'components/user-details';
import CollectionCard from './CollectionCard';
import { LoaderOverlay } from 'components/loader';
import { FormattedMessage } from 'react-intl';
import ErrorBox from 'components/error-box';
import { COLLECTION_STATE } from 'config/const';
import CollectionProductItems from './CollectionProductItems';
import { errorToast, successToast } from 'components/toast/toast';
import PageTitle from 'components/PageTitle';


const filterCollections = (collections: Array<CollectionResponse>) => {
  const isEditable = collection =>
    !collections.find(({ parentId }) => parentId === collection.id);

  return collections.reduce((results, collection) => {
    if (collection.state !== COLLECTION_STATE.DRAFT) {
      results.push(collection);
    }
    return results;
  }, [] as Array<any>);
};

const ModeratorCollections = () => {
  const user = useSelector(userSelector);
  const [isModal, setIsModal] = React.useState(false);
  const { influencerId } = useParams<{ influencerId: string }>();
  const [influencer, setInfluencer] = React.useState<User>();
  const [collections, setCollections] = React.useState<
    Array<CollectionResponse>
  >([]);
  const [pickedCollection, setPickedCollection] = React.useState<CollectionResponse>();
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);

  const fetchInfluencer = async () => {
    const  data  = await restClient.get(`${endpoints.adminUsers}/${influencerId}`);
    setInfluencer(data);
  };

  const fetchCollections = async () => {
    const { items }: { items: Array<CollectionResponse> } = await restClient.get(
      endpoints.collections,
      {
        queryStringParameters: {
          influencer: influencerId,
        },
      }
    );
    const filteredCollections = filterCollections(items);
    setCollections(filteredCollections);
  };

  const fetchData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      await Promise.all([fetchInfluencer(), fetchCollections()]);
    } catch (error) {
      setError(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleCollectionClick = (collection: CollectionResponse) => {
    setIsModal(true);
    setPickedCollection(collection);
  };

  const handlePinCollection = async (itemId, isPinned) => {
    if(isPinned){
      try{
        setIsLoading(true);
        await restClient.delete(`${endpoints.collections}/${itemId}/trending`);
        successToast('Collection is now unpinned');
      }catch(e){
        setError(getResponseErrorMessage(e));
      }finally{
        setIsLoading(false);
      }
    }else{
      try{
        setIsLoading(true);
        const response = await restClient.put(`${endpoints.collections}/${itemId}/trending`);
        successToast('Collection is now pinned');
      }catch(e){
        setError(getResponseErrorMessage(e));
      }finally{
        setIsLoading(false);
      }
    }
    fetchCollections();
  }

  React.useEffect(() => {
    fetchData();
  }, [influencerId]);

  return (
    <Layout>
      <Header>
        <PageTitle title="Influencer Collections"/>
      </Header>
      <Content>
        {influencer && (
          <div className="mb-8">
            <UserDetailsMinor user={influencer} />
          </div>
        )}
        {error && <ErrorBox>{error}</ErrorBox>}
        {isLoading && <LoaderOverlay />}
        {isModal && 
          <Modal withExitButton onExit={() => setIsModal(false)}>
              <CollectionProductItems 
              countryCode='ARE' 
              lang='EN' 
              skusArray={pickedCollection?.items}
              />
          </Modal>
          }
        {!isLoading && isEmpty(collections) && (
          <h1>
            <FormattedMessage id="collection.noCollections" />
          </h1>
        )}
        {!isLoading && !isEmpty(collections) && (
          <div className="flex flex-wrap justify-center sm:justify-start">
            {collections.map(collection => (
              <CollectionCard
                onClick={handleCollectionClick as any}
                collection={collection}
                key={collection.id}
                handlePinCollection={handlePinCollection}
              />
            ))}
          </div>
        )}
      </Content>
    </Layout>
  );
};

export { ModeratorCollections as default };