import * as React from 'react';
import { NavLink } from 'react-router-dom';
import { MenuItem as MenuItemType } from 'routes/routes-config';

type Props = {
  item: MenuItemType;
  onlyIcon?: boolean;
};

const Item = ({ item, onlyIcon }: Props) => {
  const to = Array.isArray(item.path) ? item.path[0] : item.path;

  return (
    <NavLink
      className="flex items-center p-5 text-black hover:bg-menuHover hover:text-higlight w-full transition-colors duration-200"
      to={to}
      activeClassName="text-higlight bg-higlight bg-opacity-5"
      exact={item.exact}
    >
      <span className="w-5">{item.icon}</span>
      {!onlyIcon && <span className="ps-4">{item.name}</span>}
    </NavLink>
  );
};

export { Item as default };
