import {types} from './actions';

type initialstate = {
    query: string;
    isSuperstar: string;
    isPinned: string;
    pickedCountry: Country;
    audience: 'ALL' | 'MEN' | 'WOMEN' | 'KIDS';

}
const initialState : initialstate = {
    query: '',
    isSuperstar: 'ALL',
    isPinned: 'ALL',
    pickedCountry: 'ARE',
    audience: 'ALL'
    }

const reducer = (state = initialState, action) => {
    switch(action.type){
        case types.QUERY:
            return {...state, query: action.payload};
        case types.IS_SUPERSTAR:
            return {...state, isSuperstar: action.payload};
        case types.IS_PINNED:
            return {...state, isPinned: action.payload};
        case types.SET_COUNTRY:
            return {...state, pickedCountry: action.payload};
        case types.SET_AUDIENCE:
            return {...state, audience: action.payload};
        default:
            return state;
    }
}

export default reducer;