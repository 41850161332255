import * as React from 'react';
import icons from './icons';

const ErrorBox = ({ withIcon = false, children }: any) => {
  if (!children) return null;

  return (
    <div className="max-w-md my-4 p-4 text-error border-2 border-error rounded-md shadow-md flex justify-start items-center">
      {withIcon && <span className="inline-block me-2">{icons.error}</span>}
      {children}
    </div>
  );
};

export default ErrorBox;
