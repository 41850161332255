import { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronLeft,
  faChevronRight,
  faCheck,
} from '@fortawesome/free-solid-svg-icons';

import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';

import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import PageTitle from 'components/PageTitle';
import { LoaderOverlay } from 'components/loader';

import { Pagination } from './Pagination';

import styles from './OnboardInfluencer.module.css';

import are from 'assets/img/flags/are.svg';
import bhr from 'assets/img/flags/bhr.svg';
import en from 'assets/img/flags/en.svg';
import kwt from 'assets/img/flags/kwt.svg';
import omn from 'assets/img/flags/omn.svg';
import qat from 'assets/img/flags/qat.svg';
import sau from 'assets/img/flags/sau.svg';
import EyeIcon from './Images/eye.svg';

const OnboardInfluencer = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<null | string>(null);
  const [influencerName, setInfluencerName] = useState<string>('');
  const [influencerData, setInfluencerData] = useState<any>([]);
  const [perpage, setPerpage] = useState<any>(10);
  const [currentPage, setCurrentPage] = useState<any>(1);
  const [status, setStatus] = useState<string>('PENDING');
  const [approved, setApproved] = useState<boolean>(false);
  const [onboard, setOnboard] = useState<boolean>(true);
  const [reject, setReject] = useState<boolean>(false);
  const [isApproved, setIsApproved] = useState<boolean>(false);
  const [totalItems, setTotalItems] = useState<any>(0);
  const [imageSrc] = useState<string>(
    'https://d3aud5mq3f80jd.cloudfront.net/static/media/fallback.bf804003.png'
  );
  const OnboardInfluencerFiels = [
    { header: 'User Name', isvisible: true, id: 1 },
    { header: 'Contact', isvisible: true, id: 2 },
    { header: 'Social media handle', isvisible: true, id: 3 },
    { header: 'Document', isvisible: true, id: 4 },
  ];

  useEffect(() => {
    fetchOnboardingInfluencerData();
  }, [status, currentPage, influencerName]);
  const fetchOnboardingInfluencerData = async () => {
    setError(null);
    setIsLoading(true);
    try {
      const data = await restClient.get(
        `${
          endpoints.superadmin.onBoardInfluencer
        }?status=${status}&q=${influencerName}&page=${
          currentPage - 1
        }&limit=${perpage}`
      );
      setInfluencerData(data?.items);
      setTotalItems(data?.totalItems);
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePreviousButton = () => {
    Number(currentPage);
    if (currentPage >= 0) {
      setCurrentPage(Number(currentPage - 1));
    }
  };

  const handleNextButton = () => {
    Number(currentPage);
    if (currentPage >= 1) {
      setCurrentPage(Number(currentPage + 1));
    }
  };

  const handleOnboard = () => {
    setOnboard(true);
    setApproved(false);
    setReject(false);
    setCurrentPage(1);
    setStatus('PENDING');
  };

  const handleApproved = () => {
    setOnboard(false);
    setApproved(true);
    setReject(false);
    setCurrentPage(1);
    setStatus('APPROVED');
  };

  const handleReject = () => {
    setOnboard(false);
    setApproved(false);
    setReject(true);
    setCurrentPage(1);
    setStatus('REJECTED');
  };

  const handleTickMark = async (userId, email, phone, country) => {
    setIsApproved(true);
    try {
      setIsLoading(true);
      setError(null);
      const body = {
        user_id: userId,
        email: email,
        phone: phone,
        country: country,
        is_approved: true,
      };

      await restClient.post(
        `${endpoints?.superadmin?.UpdateonBoardInfluencer}`,
        { body }
      );
      await fetchOnboardingInfluencerData();
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCancelMark = async (userId, email, phone, country) => {
    setIsApproved(false);
    try {
      setIsLoading(true);
      setError(null);
      const body = {
        user_id: userId,
        email: email,
        phone: phone,
        country: country,
        is_approved: false,
      };

      await restClient.post(
        `${endpoints?.superadmin?.UpdateonBoardInfluencer}`,
        { body }
      );
      await fetchOnboardingInfluencerData();
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const countryCodeToFlag = {
    are,
    bhr,
    en,
    kwt,
    omn,
    qat,
    sau,
  };

  const handleImageClick = async image_url => {
    try {
      setIsLoading(true);
      setError(null);
      await restClient.get(
        `${process.env.REACT_APP_API_URL?.split('v1')[0]}${endpoints?.superadmin?.InsightImage}?path=${image_url}`
      );
    } catch (error: any) {
      setError(error);
    } finally {
      setIsLoading(false);
    }
  };

  const displayRange = (totalItems, perpage, currentPage) => {
    const start = Math.max(perpage * (currentPage - 1), 0);
    const end = Math.min(perpage * currentPage, totalItems);

    return `${start + 1} - ${end} of ${totalItems}`;
  };

  const renderInfluencerDashboard = () => {
    let content = [...influencerData];
    const filtererCount = content
      .filter(val => {
        if (
          val?.influencerNameEnglish
            ?.toLowerCase()
            ?.includes(influencerName?.toLowerCase()) ||
          val?.influencerNameArabic?.includes(influencerName)
        ) {
          return val;
        }
      })
      .map(
        ({
          userId,
          influencerNameArabic,
          influencerNameEnglish,
          insightPictures,
          email,
          phone,
          country,
          socialMediaLink,
        }) => {
          return (
            <tr key={userId} className={styles.tableTRBorder}>
              <td>
                <div className="flex items-center ml-4">
                  <img
                    src={
                      'https://cdn-influencer-dev.6tst.com/public/resources/images/2bf85a23-54ed-459d-b283-43960d7f4717.png'
                    }
                    alt="Influencer"
                    className="h-12 w-12 me-3 rounded-full object-cover"
                  />
                  <div className={styles.influencerName_layout}>
                    <div className={styles.influencerName}>
                      {influencerNameArabic
                        ? influencerNameArabic
                        : influencerNameEnglish}
                    </div>
                    <div
                      className={
                        'rounded flex items-center mt-3 text-black cursor-pointer'
                      }
                    >
                      <img
                        className="w-5 h-5 mr-2"
                        src={countryCodeToFlag[country.toLocaleLowerCase()]}
                        alt={country}
                      />
                      <span className={'text-sm'}>{country}</span>
                    </div>
                  </div>
                </div>
              </td>
              <td className={'text-left pl-7'}>
                <div className={styles.font}>{email}</div>
                <div className={styles.font + ' mt-3'}>{phone}</div>
              </td>
              <td className={styles.socialMedia}>
                <span className={styles.SocialMediaLink}>
                  {socialMediaLink}
                </span>
                <span className={styles.nextTabImage}>
                  <a
                    href={`${
                      socialMediaLink.includes('https://')
                        ? socialMediaLink
                        : `https://${socialMediaLink}`
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS-pdKMh1tPQsB8auU-EyTvV_1IBCDQwMgSxN9231yEMpMAMZA1US1C2ClEhPzUc7hJsKk&usqp=CAU"
                      alt={'nextPageviewImage'}
                    />
                  </a>
                </span>
              </td>
              <td>
                <div className={styles.insightImages}>
                  {insightPictures?.map(val => (
                    <div className={styles.insightImage_layout}>
                      <a
                        href={`${process.env.REACT_APP_API_URL?.split('v1')[0]}${endpoints?.superadmin?.InsightImage}?path=${val}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={`${process.env.REACT_APP_API_URL?.split('v1')[0]}${endpoints?.superadmin?.InsightImage}?path=${val}`}
                          onError={({ currentTarget }) => {
                            currentTarget.onerror = null;
                            currentTarget.src = imageSrc;
                          }}
                          alt="insight_images"
                        />
                        <div className={styles.faEyeIcon}>
                          <img src={EyeIcon} alt="EyeIcon" />
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </td>
              {onboard && (
                <td>
                  <div className={styles.approveRejectLayout}>
                    <span
                      className={styles.tickMark}
                      onClick={() => {
                        handleTickMark(userId, email, phone, country);
                      }}
                    >
                      {' '}
                      <FontAwesomeIcon
                        icon={faCheck}
                        size="1x"
                        className={'text-black'}
                      />
                    </span>
                    <span
                      className={styles.crossMark}
                      onClick={() => {
                        handleCancelMark(userId, email, phone, country);
                      }}
                    >
                      {' '}
                      &#10060;{' '}
                    </span>
                  </div>
                </td>
              )}
            </tr>
          );
        }
      );
    return filtererCount;
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <Layout>
        <Header>
          <PageTitle title="Onboard Influencer" />
        </Header>
        <Content>
          <div className={styles.categorizationSection}>
            <div
              className={
                styles.onboard + ' ' + (onboard ? styles.active : null)
              }
              onClick={handleOnboard}
            >
              ONBOARD
            </div>
            <div
              className={
                styles.approved + ' ' + (approved ? styles.active : null)
              }
              onClick={handleApproved}
            >
              APPROVED
            </div>
            <div
              className={
                styles.rejected + ' ' + (reject ? styles.active : null)
              }
              onClick={handleReject}
            >
              REJECTED
            </div>
          </div>
          <div className="influencer-table-header-section">
            <div className={styles.searchInfluncerName}>
              <input
                className={styles.searchInfluencerNameInput}
                type="text"
                placeholder="Search by Influencer name"
                onChange={e => {
                  setInfluencerName(e.target.value);
                  setCurrentPage(1);
                }}
              ></input>
            </div>
          </div>
          <div className={styles.adminDashboard}>
            <table className={'w-full overflow-scroll '}>
              <thead>
                <tr className={styles.HeaderRowStyle}>
                  {OnboardInfluencerFiels?.map(
                    ({ header, isvisible, id }, i) => {
                      return (
                        <th
                          key={header + id}
                          className={'text-left pl-7 ' + styles.font}
                        >
                          <span>{header}</span>
                        </th>
                      );
                    }
                  )}
                  {onboard && (
                    <th
                      key={'Approve/Reject'}
                      className={'text-left pl-7 ' + styles.font}
                    >
                      <span>{'Approve/Reject'}</span>
                    </th>
                  )}
                </tr>
              </thead>
              <tbody className={styles.tableBody}>
                {renderInfluencerDashboard()}
              </tbody>
            </table>
          </div>
        </Content>
        {totalItems >= 1 && (
          <div>
            <div className={styles.paginationitems}>
              <div className={styles.middledetails}>
                {displayRange(totalItems, perpage, currentPage)}
              </div>
              <div className={styles.prevousnextbuttons}>
                <button
                  className={styles.previousIcon}
                  onClick={handlePreviousButton}
                  disabled={currentPage === 1}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    size="lg"
                    className={currentPage === 1 ? 'text-gray' : 'text-black'}
                  />
                </button>

                <button
                  className={styles.nextIcon}
                  onClick={handleNextButton}
                  disabled={currentPage === Math.ceil(totalItems / perpage)}
                >
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size="lg"
                    className={
                      currentPage === Math.ceil(totalItems / perpage)
                        ? 'text-gray'
                        : 'text-black'
                    }
                  />
                </button>
              </div>
            </div>
          </div>
        )}
      </Layout>
    </>
  );
};
export default OnboardInfluencer;
