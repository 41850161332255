import * as React from 'react';
import { path } from 'ramda';
import { useIntl } from 'react-intl';
import {
  AVAILABLE_COUNTRIES,
  AVAILABLE_LANGUAGES,
  TARGET_AUDIENCE,
  USER_ROLES,
  COLLECTION_STATE,
} from 'config/const';
import { Option as OptionType } from 'components/select';
import are from 'assets/img/flags/are.svg';
import bhr from 'assets/img/flags/bhr.svg';
import en from 'assets/img/flags/en.svg';
import kwt from 'assets/img/flags/kwt.svg';
import omn from 'assets/img/flags/omn.svg';
import qat from 'assets/img/flags/qat.svg';
import sau from 'assets/img/flags/sau.svg';

const countryCodeToFlag = {
  are,
  bhr,
  en,
  kwt,
  omn,
  qat,
  sau,
};

export const getResponseErrorMessage = error => {
  const defaultMessage = 'Something went wrong';
  const message = path(['response', 'data', 'message'], error);
  return message || error.message || defaultMessage;
};

export const useCountries = (): Array<{
  name: string;
  value: Country;
  flagImageSrc: string;
}> => {
  const intl = useIntl();

  return React.useMemo(
    () =>
      Object.keys(AVAILABLE_COUNTRIES).map(key => {
        const value = AVAILABLE_COUNTRIES[key];
        return {
          name: intl.formatMessage({
            id: `common.countries.${value}`.toLowerCase(),
          }),
          value,
          flagImageSrc: countryCodeToFlag[value.toLowerCase()],
        };
      }),
    [intl]
  );
};

export const useLanguages = (): Array<OptionType & { value: Language }> => {
  const intl = useIntl();

  return React.useMemo(
    () =>
      Object.keys(AVAILABLE_LANGUAGES).map(key => {
        const value = AVAILABLE_LANGUAGES[key];
        return {
          value,
          name: intl.formatMessage({
            id: `common.languages.${value}`.toLowerCase(),
          }),
        };
      }),
    [intl]
  );
};

export const useTargetAudience = (): Array<
  OptionType & { value: TargetAudience }
> => {
  const intl = useIntl();
  return React.useMemo(
    () =>
      Object.keys(TARGET_AUDIENCE).map(key => {
        const value = TARGET_AUDIENCE[key];
        return {
          value,
          name: intl.formatMessage({
            id: `collection.audience.${value}`.toLowerCase(),
          }),
        };
      }),
    [intl]
  );
};

export const formatUserName = (user: User | AssingedModerator) => {
  if (!user) return '';
  return `${user.nameEnglish} ${user.familyNameEnglish}`;
};

export const mapUsersToOptions = (users: Array<User>): Array<OptionType> =>
  users.map(user => ({ name: formatUserName(user), value: user.id }));

export const getInfluencerName = (user: User, isArabic?: boolean) => {
  if (!user) return '';
  return (
    (isArabic ? user.influencerNameArabic : user.influencerNameEnglish) || ''
  );
};

export const checkIsCollectionReadOnly = (
  collectionState: CollectionState,
  userRole: UserRole
): boolean => {
  const isReviewInfleuncer =
    collectionState === COLLECTION_STATE.IN_REVIEW &&
    userRole === USER_ROLES.INFLUENCER;

  const isPublishing = collectionState === COLLECTION_STATE.PUBLISHING;

  return isReviewInfleuncer || isPublishing;
};
