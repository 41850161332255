import * as React from 'react';
import { useDispatch } from 'react-redux';
import { updateUserSettings } from 'lib/redux/user/actions';
import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import UserDetails from 'components/user-details';
import { SmallButton, SubmitButton } from 'components/button';
import TranslatedText from 'components/translated-text';
import Modal from 'components/modal';
import { useSelector } from 'react-redux';
import Input from 'components/input';
import { useIntl } from 'react-intl';
import { isInfluencerSelector, userSelector } from 'lib/redux';
import ChangePassword from 'components/forms/change-password';
import MediaUploadButton, {
  OnFileSelectedParam,
} from 'components/media-upload-button';
import mimeTypes from 'utils/mime-types';
import { getImageDimensions } from 'lib/file-upload';
import { PROFILE_PIC_ASPECT_RATIO } from 'config/const';
import { LoaderOverlay } from 'components/loader';
import { errorToast, successToast } from 'components/toast/toast';
import { getResponseErrorMessage } from 'utils/common';
import InfluencerDetailsForm from 'components/forms/influencer-details';
import PageTitle from 'components/PageTitle';

const imageMimeTypes = mimeTypes.image.join(', ');

const AccountPage = () => {
  const currentUser = useSelector(userSelector);
  const intl = useIntl();
  const isInfluencer = useSelector(isInfluencerSelector);
  const dispatch = useDispatch();
  const [userData, setUserData] = React.useState<any>(currentUser);
  const [isLoading, setIsLoading] = React.useState(false);
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const mediaUploadButton = React.useRef<any>(null);

  const onError = (newError: string) => {
    setError(newError);
  };

  const updateUserData = (filedName: string, value: any) => {
    setUserData({ ...userData, [filedName]: value });
  };

  const onSubmit = async () => {
    try {
      setIsLoading(true);
      const imageUrl: string = await mediaUploadButton.current.uploadFile();
      const userDataWithImg = {
        ...userData,
        profilePictureUrl: imageUrl,
      };
      await dispatch(updateUserSettings(userDataWithImg));
      successToast('User has been updated');
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const isValidAccountPic = async (file: File) => {
    let isValid = false;
    let error = '';

    try {
      const { width, height } = await getImageDimensions(file);
      const aspectRatio = width / height;
      isValid =
        aspectRatio >= PROFILE_PIC_ASPECT_RATIO.MIN &&
        aspectRatio <= PROFILE_PIC_ASPECT_RATIO.MAX;
    } catch (e) {
      error = intl.formatMessage({ id: 'upload.cannotLoadFile' });
    }

    return { isValid, error };
  };

  const onFileSelected = async ({ file }: OnFileSelectedParam) => {
    const { isValid, error } = await isValidAccountPic(file);
    let nextError: string | null = null; // By default: reset error which could be caused by previously selected file, being too large
    if (error.length) {
      nextError = error;
    } else if (!isValid) {
      nextError = intl.formatMessage({
        id: 'account.profilePic.invalidAspect',
      });
    }
    errorToast(nextError);
    return isValid && !error.length;
  };

  return (
    <>
      {isModalOpen && (
        <Modal onExit={() => setIsModalOpen(false)} withExitButton>
          <ChangePassword onSuccessCallback={() => setIsModalOpen(false)} />
        </Modal>
      )}
      <Layout>
        <Header>
          <div className="flex items-center justify-between">
            <SubmitButton disabled={isLoading} onClick={onSubmit}>
              <TranslatedText
                id={isLoading ? 'account.saving' : 'account.save'}
              />
            </SubmitButton>
          </div>
        </Header>
        <Content>
          <div>
            {isLoading && <LoaderOverlay />}
            <Input
              label={intl.formatMessage({ id: 'account.nameEnglish' })}
              value={userData.nameEnglish}
              onChange={evt => updateUserData('nameEnglish', evt.target.value)}
            />
            <Input
              label={intl.formatMessage({ id: 'account.nameArabic' })}
              value={userData.nameArabic}
              onChange={evt => updateUserData('nameArabic', evt.target.value)}
            />
            <Input
              label={intl.formatMessage({ id: 'account.familyNameEnglish' })}
              value={userData.familyNameEnglish}
              onChange={evt =>
                updateUserData('familyNameEnglish', evt.target.value)
              }
            />
            <Input
              label={intl.formatMessage({ id: 'account.familyNameArabic' })}
              value={userData.familyNameArabic}
              onChange={evt =>
                updateUserData('familyNameArabic', evt.target.value)
              }
            />
            {isInfluencer && (
              <>
                <Input
                  label={intl.formatMessage({
                    id: 'account.influencerNameEnglish',
                  })}
                  value={userData.influencerNameEnglish}
                  onChange={evt =>
                    updateUserData('influencerNameEnglish', evt.target.value)
                  }
                />
                <Input
                  label={intl.formatMessage({
                    id: 'account.influencerNameArabic',
                  })}
                  value={userData.influencerNameArabic}
                  onChange={evt =>
                    updateUserData('influencerNameArabic', evt.target.value)
                  }
                />
              </>
            )}
            <Input
              label={intl.formatMessage({ id: 'account.email' })}
              readOnly
              disabled
              value={userData.email}
            />
            <div className="flex items-center">
              <Input
                label={intl.formatMessage({ id: 'account.password' })}
                readOnly
                type="password"
                value="**********"
                disabled
                sideElement={
                  <SmallButton
                    className="ms-2"
                    onClick={() => setIsModalOpen(true)}
                  >
                    {intl.formatMessage({ id: 'account.change' })}
                  </SmallButton>
                }
              />
            </div>
            <Input
              label={intl.formatMessage({ id: 'account.phone' })}
              value={userData.phone}
              readOnly
              disabled
            />
            <div>
              <div className="text-secondary">
                {intl.formatMessage({ id: 'account.profilePic' })}
              </div>
              <MediaUploadButton
                acceptedMime={imageMimeTypes}
                onFileSelected={onFileSelected}
                fileUrl={userData.profilePictureUrl}
                isVideo={false}
                initialFile={null}
                onError={onError}
                ref={mediaUploadButton}
                name={'profilePictureUrl'}
                picAspectX={16}
                picAspectY={9}
                toCrop={true}
              />
            </div>
          </div>
        </Content>
      </Layout>
    </>
  );
};

const AccountPageUpdated = () => {
  const currentUser: any = useSelector(userSelector)!;

  return (
    <Layout>
      <Header>
        <PageTitle title="Account"/>
      </Header>
      <Content>
        <InfluencerDetailsForm
          initialInfluencerData={currentUser}
          editedBy="influencer"
        />
      </Content>
    </Layout>
  );
};

export default function AccountPageMain() {
  const isInfluencer = useSelector(isInfluencerSelector);
  return isInfluencer ? <AccountPageUpdated /> : <AccountPage />;
}
