import * as React from 'react';
import { AVAILABLE_COUNTRIES } from 'config/const';
import { useIntl, FormattedMessage } from 'react-intl';
import Heading from './heading';
import are from 'assets/img/flags/are.svg';
import bhr from 'assets/img/flags/bhr.svg';
import en from 'assets/img/flags/en.svg';
import kwt from 'assets/img/flags/kwt.svg';
import omn from 'assets/img/flags/omn.svg';
import qat from 'assets/img/flags/qat.svg';
import sau from 'assets/img/flags/sau.svg';

const countryCodeToFlag = {
  are,
  bhr,
  en,
  kwt,
  omn,
  qat,
  sau,
};

type Props = {
  onSelect: (country: Country) => void;
  skipCountries?: Array<Country>;
  noTitle?: boolean;
};

const CountryButton = (
  props: React.ButtonHTMLAttributes<HTMLButtonElement>
) => {
  return (
    <button
      className="flex items-center text-black underline border-b last:border-b-0 border-border px-4 py-2 md:px-8 md:py-8 outline-none focus:outline-none"
      {...props}
    />
  );
};

const CountryList = ({ onSelect, skipCountries = [], noTitle }: Props) => {
  const intl = useIntl();

  return (
    <div className="flex flex-col">
      {!noTitle && (
        <Heading className="mb-1">
          <FormattedMessage id="labels.selectCountry" />
        </Heading>
      )}
      {Object.entries(AVAILABLE_COUNTRIES)
        .filter(([key, value]) => !skipCountries.includes(value as Country))
        .map(([key, value]) => {
          const countryToLower = value.toLocaleLowerCase();
          return (
            <CountryButton onClick={() => onSelect(value as Country)} key={key}>
              <img
                className="w-8 h-8"
                src={countryCodeToFlag[countryToLower]}
                alt={countryToLower}
              />
              <span className="ml-4">
                {intl.formatMessage({
                  id: `common.countries.${countryToLower}`,
                })}
              </span>
            </CountryButton>
          );
        })}
    </div>
  );
};

export default CountryList;
