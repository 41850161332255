import * as React from 'react';
import {
  isModeratorSelector,
  liveCollectionsCountSelector,
  preLiveCollectionsCountSelector,
  userSelector,
} from 'lib/redux';
import { useDispatch, useSelector } from 'react-redux';
import Layout from 'components/layout';
import Header from 'components/header';
import Content from 'components/content';
import UserDetails from 'components/user-details';
import Card from 'components/summary-card';
import { FormattedMessage, useIntl } from 'react-intl';
import {
  fetchInfluencerCollections,
  fetchModeratorCollections,
} from 'lib/redux/collections/actions';
import { LoaderOverlay } from 'components/loader';
import ErrorBox from 'components/error-box';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { useHistory } from 'react-router-dom';
import { COLLECTION_STATE } from 'config/const';
import Heading from 'components/heading';
import PageTitle from 'components/PageTitle';

const DashboardPage = () => {
  const user = useSelector(userSelector);
  const isModerator = useSelector(isModeratorSelector);
  const preLiveCollectionsCount = useSelector(preLiveCollectionsCountSelector);
  const liveCollectionsCount = useSelector(liveCollectionsCountSelector);
  const [fpCount, setFpCount] = React.useState(0);
  const intl = useIntl();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const history = useHistory();

  const fetchCollectionsData = async () => {
    try {
      setIsLoading(true);
      if (isModerator) {
        await dispatch(fetchModeratorCollections());
        const featuredProducts = await restClient.get(
          endpoints.featuredProducts,
          { queryStringParameters: { state: COLLECTION_STATE.IN_REVIEW } }
        );
        if (!featuredProducts || !featuredProducts.length) return;
        setFpCount(featuredProducts.length);
      } else {
        await dispatch(fetchInfluencerCollections());
      }
    } catch (error:any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchCollectionsData();
  }, []);

  const getModeratorContent = () => {
    return (
      <>
        <Card
          title={intl.formatMessage({
            id: 'dashboard.collections',
          })}
          color="brandBlue"
          details={preLiveCollectionsCount}
          onClick={() => history.push('/collections')}
        />
        <Card
          color="higlight"
          title={intl.formatMessage({
            id: 'featuredProducts.title',
          })}
          details={fpCount}
          iconName="product"
          onClick={() => history.push('/collections')}
        />
      </>
    );
  };

  const getInfluencerContent = () => {
    return (
      <>
        <Card
          title={intl.formatMessage({
            id: 'dashboard.liveCollections',
          })}
          details={liveCollectionsCount}
          onClick={() => history.push('/collections')}
        />
        <Card
          color="higlight"
          title={intl.formatMessage({
            id: 'dashboard.draftCollections',
          })}
          details={preLiveCollectionsCount}
          onClick={() => history.push('/collections')}
        />
      </>
    );
  };

  return (
    <Layout>
      <Header>
        <PageTitle title="Dashboard"/>
      </Header>
      <Content>
        <div>
          {isLoading && <LoaderOverlay />}
          <Heading>
            <FormattedMessage id="dashboard.overview" />
          </Heading>
          {!isLoading && (
            <div className="mt-8 md:mt-10">
              {error && <ErrorBox>{error}</ErrorBox>}
              {!error && (
                <div className="grid grid-cols-2 gap-2 md:flex md:space-x-2">
                  {isModerator ? getModeratorContent() : getInfluencerContent()}
                </div>
              )}
            </div>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default DashboardPage;
