import { useState } from 'react';
import { useHistory } from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSignOutAlt} from '@fortawesome/free-solid-svg-icons';
import useWindowDimensions from 'hooks/useWindowDimensions';

import UserDetails from 'components/user-details';

type Props = {
    title: string;
}

const Navbar = ({}) => {
    const {width, height} = useWindowDimensions();
    const history = useHistory();
    return (
        <div className="flex items-center justify-between flex-grow md:flex-grow-0 p-2 md:pd-0">
            <UserDetails/>
            <button className="flex items-center bg-red-50 ml-6 hover:bg-red-500 text-red-500 font-semibold hover:text-white py-2 px-4 border border-red-300 hover:border-transparent rounded" onClick={() => history.push('/logout')}>
                <FontAwesomeIcon icon={faSignOutAlt}/>
                {width > 400 && <span className="ml-2">Logout</span>}
            </button>
        </div>
    )
}

export default Navbar;