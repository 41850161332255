import * as React from 'react';

type Props = {
  onClick: () => void;
  icon: React.ReactNode;
  title: React.ReactNode;
};

export default function ActionButton({ onClick, icon, title }: Props) {
  return (
    <div
      className="flex flex-col items-center cursor-pointer ms-2 hover:text-higlight transition-colors duration-300"
      onClick={onClick}
    >
      <span className="w-6 h-6">{icon}</span>
      <span className="text-xs text-center mt-1 hidden sm:block">{title}</span>
    </div>
  );
}
