import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LoaderOverlay } from 'components/loader';
import { fetchUserDetails } from 'lib/redux/user/actions';
import { userSelector } from 'lib/redux';
import { useIntl } from 'react-intl';
import Button from 'components/button';

type Props = {
  children: React.ReactElement;
};

const UserFetcher = ({ children }: Props) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const [error, setError] = React.useState(false);
  const user = useSelector(userSelector);

  const fetchData = async () => {
    try {
      setError(false);
      await dispatch(fetchUserDetails());
    } catch (error) {
      setError(true);
    }
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      {!error && user && children}
      {!error && !user && <LoaderOverlay />}
      {error && (
        <div className="w-screen h-screen flex flex-col justify-center items-center bg-white">
          <span className="my-4">
            {intl.formatMessage({ id: 'auth.fetchProfileFailureMsg' })}
          </span>
          <Button onClick={fetchData}>
            {intl.formatMessage({ id: 'common.retry' })}
          </Button>
        </div>
      )}
    </>
  );
};

export default UserFetcher;
