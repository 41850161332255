import * as React from 'react';
import { identity as mute } from 'ramda';
import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import HeaderMenu from 'components/header-menu';
import UserDetails from 'components/user-details';
import Input from 'components/input';
import { LoaderOverlay } from 'components/loader';
import { DeleteButton, SubmitButton } from 'components/button';
import { CollectionHeaderMenu } from 'components/header-menu';

import ErrorBox from 'components/error-box';
import { useSelector } from 'react-redux';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { useIntl, FormattedMessage } from 'react-intl';
import ProductsSerch from 'components/products-search';
import {
  AVAILABLE_LANGUAGES,
  COLLECTION_STATE,
  TARGET_AUDIENCE,
} from 'config/const';
import { useHistory, useLocation } from 'react-router-dom';
import { StateBadge } from 'components/badge';
import { isModeratorSelector, userSelector } from 'lib/redux';
import Select from 'components/select';
import {
  useCountries,
  getResponseErrorMessage,
  useTargetAudience,
  checkIsCollectionReadOnly,
} from 'utils/common';
import { fetchFeaturedProductsWithDetails } from 'utils/fetured-products';
import { errorToast, successToast, infoToast } from 'components/toast/toast';
import { RejectionModal } from 'components/modal';
import { getRejectedReason, useActiveTab } from 'utils/collection';
import Duplication from 'components/duplication';
import PageTitle from 'components/PageTitle';
import are from 'assets/img/flags/are.svg';
import bhr from 'assets/img/flags/bhr.svg';
import en from 'assets/img/flags/en.svg';
import kwt from 'assets/img/flags/kwt.svg';
import omn from 'assets/img/flags/omn.svg';
import qat from 'assets/img/flags/qat.svg';
import sau from 'assets/img/flags/sau.svg';

const countryCodeToFlag = {
  are,
  bhr,
  en,
  kwt,
  omn,
  qat,
  sau,
};

const headerMenuItems = [{ name: 'Products', value: 'products', active: true }];

const FeaturedProductsPage = () => {
  const intl = useIntl();
  const history = useHistory();
  const { search } = useLocation();
  const user = useSelector(userSelector)!;
  const isModerator = useSelector(isModeratorSelector);
  const countryOptions = useCountries();
  const audienceOptions = useTargetAudience();

  const searchParams = new URLSearchParams(search);
  const influencerName = searchParams.get('inflName');
  const influencerId = searchParams.get('inflId')!;
  const editId = searchParams.get('editId')!;
  const [isLoading, setIsLoading] = React.useState(false);
  const [fetchError, setFetchError] = React.useState(null);
  const [errorItems, setErrorItems] = React.useState(null);
  const [activeTab, setActiveTab] = useActiveTab();
  const [details, setDetails] =
    React.useState<FeaturedProductsCollection | null>(null);
  const [permissionErr, setPermissionErr] = React.useState<null | string>(null);
  const [products, setProducts] = React.useState<Array<ProductResponse>>([]);
  const selectedCountry = searchParams.get('country');
  const [audienceToDisplay, setAudienceToDisplay] =
    React.useState<TargetAudience>(TARGET_AUDIENCE.WOMEN);
  const [audience, setAudience] = React.useState<Array<TargetAudience>>([]);
  const [showRejectModal, setShowRejectModal] = React.useState(false);

  const [showDuplicationModa, setShowDuplicationModal] = React.useState(false);

  const fetchFeaturedProductsDetails = async () => {
    try {
      setIsLoading(true);
      setDetails(null);
      setProducts([]);
      setAudience([]);
      setFetchError(null);
      setErrorItems(null);
      const { details, products, items } = await fetchFeaturedProductsWithDetails({
        country: selectedCountry,
        audience: audienceToDisplay,
        isModerator,
        editId,
        createInfluencerId: influencerId,
      });
      if(items){
          setErrorItems(items);
      }
      if(products && details) checkPremissions(details);

      setDetails(details || null);
      setProducts(products || []);
      setAudience((details && details.audience) || [audienceToDisplay]);
    } catch (e) {
      setFetchError(getResponseErrorMessage(e));
    } finally {
      setIsLoading(false);
    }
  };

  const checkPremissions = async (details) => {
    setPermissionErr(null);
    setIsLoading(true);
    try{
      if(isModerator){
        await restClient.get(`${endpoints.featuredProducts}/permissions/${details?.owner.id}`);
      }else{
        await restClient.get(`${endpoints.featuredProducts}/permissions`);
      }
    }catch(e:any){
      infoToast(e.response.data.message)
    }finally{
      setIsLoading(false);
    }
  }

  const createFeaturedProducts = async () => {
    try {
      if (audience.length === 0) {
        throw new Error('Target audience is required');
      }
      setIsLoading(true);
      const body = {
        items: products.map(({ sku }) => ({ sku })),
        country: selectedCountry,
        influencerId: isModerator ? influencerId : null,
        audience,
      };
      let newFP = await restClient.post(endpoints.featuredProducts, { body });
      if (isModerator) {
        const { state: newState } = await restClient.put(
          `${endpoints.featuredProducts}/${newFP.id}/approval`
        );
        newFP.state = newState;
      }
      setDetails(newFP);
      successToast('Featured Products has been created');
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const updateFeaturedProducts = async (shouldThrow?: boolean) => {
    try {
      if (audience.length === 0) {
        throw new Error('Target audience is required');
      }
      if (!details) {
        throw new Error('Could not retreive Featured Products details');
      }
      setIsLoading(true);
      const updatedCollectionDetails = await restClient.put(
        `${endpoints.featuredProducts}/${details.id}`,
        {
          body: {
            items: products.map(({ sku }) => ({ sku })),
            audience,
          },
        }
      );
      setDetails(updatedCollectionDetails);
      successToast('Featured Products has been updated');
    } catch (error) {
      if (shouldThrow) {
        throw error;
      } else {
        errorToast(getResponseErrorMessage(error));
      }
    } finally {
      setIsLoading(false);
    }
  };

  const updateLiveFeatureProducts = async () => {
    try {
      if (audience.length === 0) {
        throw new Error('Target audience is required');
      }
      if (!details) {
        throw new Error('Could not retreive Featured Products details');
      }
      setIsLoading(true);
      let newFeaturedProducts = await restClient.put(
        `${endpoints.featuredProducts}/${details.id}/live`,
        {
          body: {
            items: products.map(({ sku }) => ({ sku })),
            audience,
          },
        }
      );
      if (isModerator) {
        await restClient.put(
          `${endpoints.featuredProducts}/${newFeaturedProducts.id}/submission`
        );
        newFeaturedProducts = await restClient.put(
          `${endpoints.featuredProducts}/${newFeaturedProducts.id}/approval`
        );
      }
      setDetails(newFeaturedProducts);
      successToast('Live Featured Products has been updated');
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const influencerSaveAndSubmit = async () => {
    try {
      if (!details) {
        throw new Error('Could not retreive Featured Products details');
      }
      await updateFeaturedProducts(true);
      const { state: newState } = await restClient.put(
        `${endpoints.featuredProducts}/${details.id}/submission`
      );
      setDetails({ ...details, state: newState });
      successToast('Featured Products has been submitted');
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const influencerWithdraw = async () => {
    try {
      if (!details) {
        throw new Error('Could not retreive Featured Products details');
      }
      setIsLoading(true);
      const { state: newState } = await restClient.put(
        `${endpoints.featuredProducts}/${details.id}/withdrawal`
      );
      setDetails({ ...details, state: newState });
      successToast('Featured Products has been withdrawn');
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const moderatorReject = async (rejectReason: RejectionReason) => {
    try {
      if (!details) {
        throw new Error('Could not retreive Featured Products details');
      }
      setIsLoading(true);
      await restClient.put(
        `${endpoints.featuredProducts}/${details.id}/rejection`,
        { body: rejectReason }
      );
      successToast('Featured Products has been rejected');
      history.push('/collections');
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
      setIsLoading(false);
      setShowRejectModal(false);
    }
  };

  const moderatorSaveAndApprove = async () => {
    try {
      if (!details) {
        throw new Error('Could not retreive Featured Products details');
      }
      setIsLoading(true);
      await updateFeaturedProducts(true);
      const { state: newState } = await restClient.put(
        `${endpoints.featuredProducts}/${details.id}/approval`
      );
      setDetails({ ...details, state: newState });
      successToast('Featured Products has been approved');
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };
  const moderatorSave = async () => {
    try{
      setIsLoading(true);
      const response = await updateFeaturedProducts(true);
    }catch(e: any){
      errorToast(e);
    }finally{
      setIsLoading(false);
    }
  }
  const moderatorSubmitandAprove = async () => {
    try {
      if (!details) {
        throw new Error('Could not retreive Featured Products details');
      }
      setIsLoading(true);
      await updateFeaturedProducts(true);
      await restClient.put(
        `${endpoints.featuredProducts}/${details.id}/submission`
      );
      const { state: newState } = await restClient.put(
        `${endpoints.featuredProducts}/${details.id}/approval`
      );
      setDetails({ ...details, state: newState });
    }catch(e: any){
      errorToast(e);
    }finally{
      setIsLoading(false);
    }
  }

  const onProductRemove = (productToRemove: ProductResponse) => {
    const updatedProducts = [...products].filter(
      product => product.sku !== productToRemove.sku
    );
    setProducts(updatedProducts);
  };

  const onProductSelect = (newProduct: ProductResponse) => {
    if (products.find(product => product.sku === newProduct.sku)) {
      return;
    }
    setProducts([...products, newProduct]);
  };

  const onProductsOrderChage = (orderedProducts: Array<ProductResponse>) => {
    setProducts(orderedProducts);
  };

  React.useEffect(() => {
    fetchFeaturedProductsDetails();
  }, [selectedCountry, audienceToDisplay]);

  const state = details && details.state;

  const isReadOnly = checkIsCollectionReadOnly(
    state as CollectionState,
    user.role
  );

  const rejectReason = getRejectedReason(details as CollectionWithProducts);

  return (
    <>
      <Layout>
        <Header>
          <PageTitle title="Featured Products"/>
        </Header>
        {permissionErr && <p className="text-white border bg-red-500 border-red p-1 text-center m-1 rounded">{permissionErr}</p>}
        {!fetchError && (
              <div className="flex justify-end">
                <div className="me-6">
                  <StateBadge state={state!} />
                </div>
                <HeaderButtons
                  details={details}
                  createFeaturedProducts={createFeaturedProducts}
                  updateFeaturedProducts={updateFeaturedProducts}
                  influencerSaveAndSubmit={influencerSaveAndSubmit}
                  influencerWithdraw={influencerWithdraw}
                  moderatorReject={() => setShowRejectModal(true)}
                  moderatorSaveAndApprove={moderatorSaveAndApprove}
                  updateLiveFeatureProducts={updateLiveFeatureProducts}
                  influencerDuplicate={() => setShowDuplicationModal(true)}
                  moderatorSave={moderatorSave}
                  moderatorSubmitAndApprove={moderatorSubmitandAprove}
                />
              </div>
            )}
        <Content>
          <div className="mb-10">
          <CollectionHeaderMenu
            activeTab={activeTab}
            onChange={item => setActiveTab(item.value)}
          />
          </div>
          {isLoading && <LoaderOverlay />}
          {showRejectModal && (
            <RejectionModal
              onRejectSubmit={moderatorReject}
              onExit={() => setShowRejectModal(false)}
            />
          )}
          {rejectReason && <RejectReason reason={rejectReason} />}
          {isModerator && details && (
            <div className="mb-4">
              <UserDetails user={details.owner} />
            </div>
          )}
          {influencerId && isModerator && (
            <Input
              label={intl.formatMessage({ id: 'account.influencerName' })}
              readOnly
              value={influencerName!}
            />
          )}
          
          {fetchError && <ErrorBox withIcon>{fetchError}</ErrorBox>}
          {!fetchError && activeTab === 'info' && (
            <>
              <div className="mb-6 flex items-center">
                <div 
                  className={"flex p-2 px-4 justify-between items-center bg-yellow-50 text-yellow-400 cursor-pointer rounded"} 
                >
                  <img className="w-7 mr-2" src={countryCodeToFlag[selectedCountry!.toLocaleLowerCase()]} alt={selectedCountry!} />
                 <span>{selectedCountry}</span>
                </div>
              </div>
              <TargetAudience
                onChange={setAudience}
                currentAudience={audience}
                isInflReview={isReadOnly}
              />
            </>
          )}
          {!fetchError && activeTab === 'products' && (
            <>
              {/* <TargetAudience
                onChange={setAudience}
                currentAudience={audience}
                isInflReview={isReadOnly}
              /> */}
              <ProductsSerch
                onProductRemove={onProductRemove}
                onProductSelect={onProductSelect}
                onProductsOrderChage={onProductsOrderChage}
                country={selectedCountry}
                selectedProducts={products}
                readOnly={isReadOnly}
                itemsErrors={errorItems}
              />
              {!isLoading && !products.length && (
                <h1 className="py-8">
                  <FormattedMessage id="featuredProducts.noProducts" />
                </h1>
              )}
            </>
          )}
        </Content>
      </Layout>
      {showDuplicationModa && details && details.id && (
        <Duplication
          id={details.id}
          onExit={() => setShowDuplicationModal(false)}
          sourceCountry={details.country}
          type="featuredProducts"
        />
      )}
    </>
  );
};

const HeaderButtons = ({
  details,
  createFeaturedProducts,
  updateFeaturedProducts,
  influencerSaveAndSubmit,
  influencerWithdraw,
  moderatorReject,
  moderatorSaveAndApprove,
  updateLiveFeatureProducts,
  influencerDuplicate,
  moderatorSave,
  moderatorSubmitAndApprove
}) => {
  const { state, isFrozen } = (details as any) || {};
  const isModerator = useSelector(isModeratorSelector);

  return (
    <div className="my-4 md:my-0 flex flex-start items-center">
      {!state && (
        <SubmitButton onClick={createFeaturedProducts}>
          <FormattedMessage
            id={
              isModerator
                ? 'collection.saveButton.text'
                : 'collection.saveDraftButton.text'
            }
          />
        </SubmitButton>
      )}
      {!isModerator && state === COLLECTION_STATE.DRAFT && (
        <div className="flex space-x-2">
          <SubmitButton onClick={() => influencerDuplicate()}>
            <FormattedMessage id="common.duplicate" />
          </SubmitButton>
          <SubmitButton onClick={() => updateFeaturedProducts()}>
            <FormattedMessage id="collection.updateButton.text" />
          </SubmitButton>
          <SubmitButton onClick={() => influencerSaveAndSubmit()}>
            <FormattedMessage id="collection.saveButton.text" />
          </SubmitButton>
        </div>
      )}
      {isModerator && state === COLLECTION_STATE.DRAFT && (
        <div className='flex space-x-2'>
          <SubmitButton onClick={moderatorSave}>
            <FormattedMessage id="collection.updateButton.text" />
          </SubmitButton>
          <SubmitButton onClick={moderatorSubmitAndApprove}>
            <FormattedMessage id="collection.saveButton.text" />
          </SubmitButton>
        </div>
      )}
      {state === COLLECTION_STATE.IN_REVIEW && !isModerator && (
        <div className="flex space-x-2">
          <DeleteButton onClick={influencerWithdraw}>
            <FormattedMessage id="collection.withdrawButton.text" />
          </DeleteButton>
          <SubmitButton onClick={() => influencerDuplicate()}>
            <FormattedMessage id="common.duplicate" />
          </SubmitButton>
        </div>
      )}
      {state === COLLECTION_STATE.IN_REVIEW && isModerator && (
        <div className="flex space-x-2">
          {!isFrozen && (
            <DeleteButton onClick={moderatorReject}>
              <FormattedMessage id="collection.rejectButton.text" />
            </DeleteButton>
          )}
          <SubmitButton onClick={() => influencerDuplicate()}>
            <FormattedMessage id="common.duplicate" />
          </SubmitButton>
          <SubmitButton onClick={moderatorSaveAndApprove} className="ms-2">
            <FormattedMessage id="collection.approveButton.text" />
          </SubmitButton>
        </div>
      )}
      {state === COLLECTION_STATE.LIVE && (
        <div className="flex space-x-2">
          <SubmitButton onClick={() => influencerDuplicate()}>
            <FormattedMessage id="common.duplicate" />
          </SubmitButton>
          <SubmitButton onClick={updateLiveFeatureProducts}>
            <FormattedMessage id="collection.saveDraftButton.text" />
          </SubmitButton>
        </div>
      )}
    </div>
  );
};

const TargetAudience = ({ onChange, currentAudience, isInflReview }) => {
  const handleChangeAudience = event => {
    const { checked: value, name } = event.target;
    let newAudience = [...currentAudience];
    if (value && !newAudience.includes(name)) {
      newAudience = [...newAudience, name];
    }
    if (!value && newAudience.includes(name)) {
      newAudience = newAudience.filter(a => a !== name);
    }
    onChange(newAudience);
  };

  return (
    <div className="flex flex-col">
      <span>
        <FormattedMessage id="collection.targetAudience" />
      </span>
      <div className="flex space-x-2">
        <label>
          <input
            type="checkbox"
            checked={currentAudience.includes(TARGET_AUDIENCE.WOMEN)}
            onChange={handleChangeAudience}
            name={TARGET_AUDIENCE.WOMEN}
            disabled={isInflReview}
          />
          <span className="ms-2">
            <FormattedMessage id="collection.audience.women" />
          </span>
        </label>
      </div>
      <div className="flex space-x-2">
        <label>
          <input
            type="checkbox"
            checked={currentAudience.includes(TARGET_AUDIENCE.MEN)}
            onChange={handleChangeAudience}
            name={TARGET_AUDIENCE.MEN}
            disabled={isInflReview}
          />
          <span className="ms-2">
            <FormattedMessage id="collection.audience.men" />
          </span>
        </label>
      </div>
      <div className="flex space-x-2">
        <label>
          <input
            type="checkbox"
            checked={currentAudience.includes(TARGET_AUDIENCE.KIDS)}
            onChange={handleChangeAudience}
            name={TARGET_AUDIENCE.KIDS}
            disabled={isInflReview}
          />
          <span className="ms-2">
            <FormattedMessage id="collection.audience.kids" />
          </span>
        </label>
      </div>
    </div>
  );
};

const RejectReason = ({ reason }: { reason?: RejectionReason }) => {
  const { locale } = useIntl();
  if (!reason) return null;
  const isEn = locale.toUpperCase() === AVAILABLE_LANGUAGES.ENGLISH;

  if (reason && reason.alreadySeen) return null;

  return (
    <div className="max-w-screen-sm mb-6">
      <ErrorBox>
        <>
          <span className="font-bold">
            <FormattedMessage id="common.rejectReason" />
          </span>
          <span>{isEn ? reason.messageEnglish : reason.messageArabic}</span>
        </>
      </ErrorBox>
    </div>
  );
};

export default FeaturedProductsPage;
