import * as React from 'react';
import cn from 'classnames';
import icons from 'components/icons';

type Props = any;

const commonClassNames = 'transition-colors duration-200';

const defaultClassNames =
  'p-4 md:px-12 flex justify-center items-center leading-4 rounded-md uppercase transition-colors duration-300 border border-transparent font-semibold text-xs';

const BaseButton = ({ className, disabled, fullWidth, ...rest }: Props) => {
  return (
    <button
      className={cn(
        defaultClassNames,
        'text-white bg-black hover:bg-secondary',
        {
          'bg-border': disabled,
          'cursor-not-allowed': disabled,
          'w-full': fullWidth,
          'hover:bg-border': disabled,
        }
      )}
      disabled={disabled}
      {...rest}
    />
  );
};

const MainButton = ({ className, useCustomOnly, ...rest }: Props) => (
  <BaseButton
    className={cn(
      {
        [defaultClassNames]: !useCustomOnly,
        'bg-primary hover:bg-border uppercase': !useCustomOnly,
      },
      className
    )}
    {...rest}
  />
);

const SmallButton = ({ className, ...rest }: Props) => {
  return (
    <button
      className={cn(commonClassNames, 'text-higlight underline', className)}
      {...rest}
    />
  );
};

const SubmitButton = ({ className, ...rest }: any) => (
  <button
    className={cn(
      defaultClassNames,
      'bg-green text-white hover:bg-white hover:text-green hover:border-green',
      className
    )}
    {...rest}
  />
);

const DeleteButton = ({
  className,
  children,
  hideableText,
  withIcon,
  ...rest
}: any) => {
  return (
    <button
      className={cn(
        defaultClassNames,
        'text-error border-error hover:bg-error hover:text-white',
        className
      )}
      {...rest}
    >
      {withIcon && (
        <span className="w-4 h-4 md:me-2 inline-block align-middle">
          {icons.bin}
        </span>
      )}
      <div className={cn('md:block', { hidden: hideableText && withIcon })}>
        {children}
      </div>
    </button>
  );
};

type AuthButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  color?: 'black' | 'green';
  fullWidth?: boolean;
};

const AuthButton = ({
  color = 'black',
  fullWidth,
  className,
  disabled,
  ...rest
}: AuthButtonProps) => {
  return (
    <button
      className={cn(
        'inline-block px-6 py-4 uppercase font-semibold text-xs rounded-md transition-colors ease-in-out duration-300 whitespace-nowrap',
        {
          'cursor-pointer': !disabled,
          'bg-border cursor-not-allowed text-white': disabled,
          'bg-black text-white hover:bg-opacity-75':
            color === 'black' && !disabled,
          'bg-green text-white hover:bg-opacity-75':
            !disabled && color === 'green',
          'w-full': fullWidth,
        }
      )}
      disabled={disabled}
      {...rest}
    />
  );
};

export {
  MainButton as default,
  SmallButton,
  SubmitButton,
  DeleteButton,
  AuthButton,
};
