import * as React from 'react';
import { AuthInput } from 'components/input';
import { AuthButton } from 'components/button';
import { useIntl } from 'react-intl';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useValidation } from 'utils/validation';
import { getError } from 'utils/validation';

type FormData = {
  password: string;
  confirmPassword: string;
};

const useSchema = () => {
  const { passwordValidation, requiredValidation } = useValidation();
  const intl = useIntl();

  return React.useMemo(
    () =>
      yup.object({
        password: passwordValidation,
        confirmPassword: requiredValidation.oneOf(
          [yup.ref('password')],
          intl.formatMessage({ id: 'validation.passwordsDoNotMatch' })
        ),
      }),
    [intl, passwordValidation, requiredValidation]
  );
};

type Props = {
  onSubmit: (formData: FormData) => Promise<any>;
  isLoading: boolean;
};

export default function SetupFirstPasswordForm({ onSubmit, isLoading }: Props) {
  const intl = useIntl();
  const schema = useSchema();
  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  return (
    <div className="flex flex-col">
      <div className="text-center mt-5 mb-10">
        <h1 className="text-xl">
          <FormattedMessage id="auth.setupNewPassword" />
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <AuthInput
          type="password"
          name="password"
          inputRef={register}
          placeholder={intl.formatMessage({ id: 'changePassword.newPassword' })}
          error={getError('password', errors)}
        />
        <AuthInput
          type="password"
          name="confirmPassword"
          inputRef={register}
          placeholder={intl.formatMessage({
            id: 'changePassword.confirmNewPassword',
          })}
          error={getError('confirmPassword', errors)}
          containerClassName="mt-4"
        />
        <div className="mt-8">
          <AuthButton type="submit" fullWidth>
            <FormattedMessage
              id={isLoading ? 'common.pleaseWait' : 'common.submit'}
            />
          </AuthButton>
        </div>
      </form>
    </div>
  );
}
