import * as React from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'lib/redux';
import icons from './icons';
import { errorToast, successToast } from './toast/toast';
import Modal from './modal';
import Heading from './heading';
import { FormattedMessage } from 'react-intl';

export default function ShareInfluencerLink() {
  const currentUser = useSelector(userSelector);
  const [isCreatorOpen, setIsCreatorOpen] = React.useState(false);
  const linkRef = React.useRef<HTMLInputElement>();

  const copyLinkToClipboard = () => {
    if (!linkRef.current) {
      errorToast(<FormattedMessage id="account.storelinkNotAvailable" />);
      return;
    }

    linkRef.current.select();
    document.execCommand('copy');
    successToast(<FormattedMessage id="account.storeLinkCopied" />);
  };

  const openInNewTab = () => {
    if (!linkRef.current) {
      errorToast(<FormattedMessage id="account.storelinkNotAvailable" />);
      return;
    }

    window.open(linkRef.current.value, '_blank');
  };

  if (!currentUser || !currentUser.storeLink) return null;

  return (
    <>
      <div
        onClick={() => setIsCreatorOpen(true)}
        className=" flex items-center text-secondary hover:text-higlight transition-colors duration-300 cursor-pointer"
      >
        <span className="w-4 h-4 inline-block">{icons.share}</span>
        <span className="ms-2 text-sm">
          <FormattedMessage id="account.shareStoreLink" />
        </span>
      </div>
      {isCreatorOpen && (
        <Modal withExitButton onExit={() => setIsCreatorOpen(false)}>
          <Heading>
            <FormattedMessage id="account.storeLink" />
          </Heading>
          <div className="mt-2 md:mt-4 flex items-center space-x-2">
            <input
              ref={linkRef as any}
              value={currentUser.storeLink}
              readOnly
              className="p-2 border border-border text-secondary w-full"
            />
            <span
              onClick={() => copyLinkToClipboard()}
              className="cursor-pointer text-higlight"
            >
              {icons.duplicate}
            </span>
            <span
              onClick={() => openInNewTab()}
              className="cursor-pointer text-higlight"
            >
              {icons.externalLink}
            </span>
          </div>
        </Modal>
      )}
    </>
  );
}
