import * as React from 'react';
import { path } from 'ramda';
import { useIntl } from 'react-intl';
import * as yup from 'yup';

const REGEXES = {
  SPECIALCHARS: /(?=.*[\^$*.[\]{}()?\-“!@#%&/,><’:;|_~`])/,
  LOWERCASE: /(?=.*[a-z])/,
  UPPERCASAE: /(?=.*[A-Z])/,
  NUMBER: /(?=.*[0-9])/,
};

export const checkErrorArray = (errorObj, cb) => {
  if(errorObj.errors.length > 0){
    return cb(errorObj.errors.join(', '))
  }
  return cb(errorObj.message);
}

export const useValidation = () => {
  const intl = useIntl();

  return React.useMemo(
    () => ({
      passwordValidation: yup
        .string()
        .required(intl.formatMessage({ id: 'validation.fieldRequired' }))
        .min(
          8,
          intl.formatMessage(
            {
              id: 'validation.minChars',
            },
            { length: 8 }
          )
        )
        .matches(
          REGEXES.NUMBER,
          intl.formatMessage({ id: 'validation.requireNumbers' })
        )
        .matches(
          REGEXES.LOWERCASE,
          intl.formatMessage({ id: 'validation.requireLowerCase' })
        )
        .matches(
          REGEXES.UPPERCASAE,
          intl.formatMessage({ id: 'validation.requireUpperCase' })
        )
        .matches(
          REGEXES.SPECIALCHARS,
          intl.formatMessage({ id: 'validation.requireSpecialChars' })
        ),
      requiredValidation: yup.string().required(
        intl.formatMessage({
          id: 'validation.fieldRequired',
        })
      ),
    }),
    [intl]
  );
};

export const getError = (name: string, errors: any): string | undefined =>
  path([name, 'message'], errors);
