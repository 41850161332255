import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router';
import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import UserDetails from 'components/user-details';
import CollectionCard from 'components/collection-card';
import AddCollectionButton from 'components/add-collection-button';
import {
  fetchModeratorCollections,
  fetchInfluencerCollections,
} from 'lib/redux/collections/actions';
import { FormattedMessage, useIntl } from 'react-intl';
import { COLLECTION_STATE, USER_ROLES } from 'config/const';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { LoaderOverlay } from 'components/loader';
import { collectionsSelector, userSelector } from 'lib/redux';
import ErrorBox from 'components/error-box';
import Heading from 'components/heading';
import PageTitle from 'components/PageTitle';

const CollectionsPage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const user = useSelector(userSelector);
  const isModerator = user && user.role === USER_ROLES.MODERATOR;
  const intl = useIntl();
  const collectionsByState = useSelector(collectionsSelector);
  const [featuredProductsToReview, setFeaturedProductsToReview] =
    React.useState<Array<FeaturedProductsCollection>>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const fetchData = async () => {
    try {
      setError(null);
      setIsLoading(true);
      if (isModerator) {
        await dispatch(fetchModeratorCollections());
        const featuredProducts = await restClient.get(
          endpoints.featuredProducts,
          { queryStringParameters: { state: COLLECTION_STATE.IN_REVIEW } }
        );
        setFeaturedProductsToReview(featuredProducts);
      } else {
        await dispatch(fetchInfluencerCollections());
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const onCollectionSelect = (collection: CollectionResponse) => {
    history.push(`/collections/edit/${collection.id}`);
  };

  const onModFeatureProductsSelect = (
    collection: FeaturedProductsCollection
  ) => {
    history.push({
      pathname: '/featured-products',
      search: `editId=${collection.id}`,
    });
  };

  const onDuplicationSuccess = () => {
    fetchData();
  };

  React.useEffect(() => {
    fetchData();
  }, []);

  const hasLiveCollections = !!(
    collectionsByState.live && collectionsByState.live.length
  );

  const emptyCollections =
    !hasLiveCollections &&
    !collectionsByState.draft.length &&
    !collectionsByState.inReview.length &&
    !collectionsByState.publishing.length;

  return (
    <Layout>
      <Header>
        <PageTitle title={isModerator ? "Pending approvals" : "Collections"}/>
      </Header>
      <Content>
        {error && <ErrorBox>{error}</ErrorBox>}
        <div className="flex flex-col md:flex-row">
          {isLoading && <LoaderOverlay />}
          {!isModerator && (
            <div className="mb-4 md:mb-0 md:h-full">
              <AddCollectionButton
                onClick={() => history.push('/create-collection')}
              />
            </div>
          )}
          <div className="md:ms-4 flex flex-col items-start">
            {emptyCollections && (
              <div className="py-4 px-2">
                <Heading>
                  <FormattedMessage id="collection.noCollections" />
                </Heading>
              </div>
            )}
            {isModerator && !emptyCollections && (
              <Heading>
                <FormattedMessage id="collection.collections" />
              </Heading>
            )}
            <div className="flex flex-wrap w-full">
              {!isModerator &&
                collectionsByState.draft.map(collection => (
                  <CollectionCard
                    collection={collection}
                    onClick={onCollectionSelect as any}
                    key={collection.id}
                    canDuplicate
                    duplicationCallback={onDuplicationSuccess}
                  />
                ))}
              {collectionsByState.inReview.map(collection => (
                <CollectionCard
                  collection={collection}
                  onClick={onCollectionSelect as any}
                  key={collection.id}
                  canDuplicate
                  duplicationCallback={onDuplicationSuccess}
                />
              ))}
              {collectionsByState.publishing.map(collection => (
                <CollectionCard
                  collection={collection}
                  onClick={onCollectionSelect as any}
                  key={collection.id}
                  canDuplicate
                  duplicationCallback={onDuplicationSuccess}
                />
              ))}
            </div>
            {isModerator && !!featuredProductsToReview.length && (
              <div className="mt-4 md:mt-8">
                <Heading>
                  <FormattedMessage id="featuredProducts.title" />
                </Heading>
                <div className="flex flex-row justify-start flex-wrap">
                  {featuredProductsToReview.map(collection => (
                    <CollectionCard
                      key={collection.id}
                      collection={collection}
                      onClick={onModFeatureProductsSelect as any}
                      isFP={true}
                      canDuplicate
                    />
                  ))}
                </div>
              </div>
            )}
            {!isModerator && hasLiveCollections && (
              <div className="mt-4 md:mt-8">
                <Heading>
                  <FormattedMessage id="collection.liveCollections" />
                </Heading>
                <div className="flex flex-wrap md:mt-2">
                  {collectionsByState.live.map(collection => (
                    <div className="flex flex-col">
                      <CollectionCard
                        collection={collection}
                        onClick={onCollectionSelect as any}
                        key={collection.id}
                        canDuplicate
                        duplicationCallback={onDuplicationSuccess}
                      />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </div>
      </Content>
    </Layout>
  );
};

export default CollectionsPage;
