import * as React from 'react';
import * as FileSaver from 'file-saver';
import { useState } from 'react';

import XLSX from 'sheetjs-style';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';

import './Popup.style.css';

export const Popup = ({
  couponDetail,
  hideCouponDetial,
  closeCouponPopup,
  filteredFields,
  setFilteredFields,
  setisClickedapply,
  setIntermediateField,
  intermediateField,
  mappedField,
  setMappedField,
  isClickedapply,
  isClickedFilter,
  setisClickedFilter,
  setisClickedReport,
  isClickedReport,
  setData,
  data,
  allcolumnClicked,
  setallcolumnClicked,
}) => {
  const [lastYear, setLastYear] = useState<boolean>(false);
  const [currentYear, setCurrentYear] = useState<boolean>(false);
  const [lastmonth, setLastMonth] = useState<boolean>(false);
  const [currentMonth, setCurrentMonth] = useState<boolean>(false);
  const [requestedData, setRequestedData] = useState([]);

  const [fileName, setFileName] = useState<string>('');
  const [requestDownload, setRequestDownload] = useState<boolean>(true);
  const [sendMail, setSendMail] = useState<boolean>(false);
  const couponDetailPopup = React.createRef<HTMLInputElement>();

  const HandleCheck = e => {
    if (!intermediateField.includes(e.target.id)) {
      setIntermediateField([...intermediateField, e.target.id]);
    } else {
      setIntermediateField(
        intermediateField.filter(item => item !== e.target.id)
      );
      setallcolumnClicked(false);
    }
  };

  const temporayObject = {
    '1': 'totalFollowers',
    '2': 'totalCollections',
    '3': 'ClicksonStore',
    '4': 'ClicksonCollection',
    '5': 'InCartProducts',
    '6': 'WishlistedProducts',
    '7': 'productSold',
    '8': 'revenueGenerated',
  };

  const handleApply = e => {
    setisClickedapply(true);
    setisClickedFilter(true);
    closeCouponPopup(e);
    setFilteredFields(intermediateField);
    setallcolumnClicked(allcolumnClicked);
    let temp: any = [];
    intermediateField.map(val => temp.push(temporayObject[val]));
    setMappedField(temp);
  };

  const handleAllColumns = e => {
    if (e.target.checked) {
      setallcolumnClicked(true);
      setIntermediateField(['1', '2', '3', '4', '5', '6', '7', '8']);
    } else {
      setallcolumnClicked(false);
      setIntermediateField([]);
    }
  };

  const handleUnSelect = () => {
    setallcolumnClicked(false);
    setIntermediateField([]);
  };

  const handleLastYear = async e => {
    setLastMonth(false);
    setCurrentYear(false);
    setLastYear(true);
    setCurrentMonth(false);
    setFileName('last-year-influencer-report');
    try {
      const response = await restClient.get(
        `${endpoints.influencerDashboard.downloadReport}?PerformanceReportType=LAST_YEAR`
      );
      setRequestedData(response.items);
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const handleCurrentYear = async e => {
    setLastMonth(false);
    setCurrentYear(true);
    setLastYear(false);
    setCurrentMonth(false);
    setFileName('current-year-influencer-report');
    try {
      const response = await restClient.get(
        `${endpoints.influencerDashboard.downloadReport}?PerformanceReportType=CURR_YEAR`
      );
      setRequestedData(response.items);
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const handleLastMonth = async e => {
    setLastMonth(true);
    setCurrentYear(false);
    setLastYear(false);
    setCurrentMonth(false);
    setFileName('last-month-influencer-report');
    try {
      const response = await restClient.get(
        `${endpoints.influencerDashboard.downloadReport}?PerformanceReportType=LAST_MONTH`
      );
      setRequestedData(response.items);
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const handleCurrentMonth = async e => {
    setLastMonth(false);
    setCurrentYear(false);
    setLastYear(false);
    setCurrentMonth(true);
    setFileName('current-month-influencer-report');
    try {
      const response = await restClient.get(
        `${endpoints.influencerDashboard.downloadReport}?PerformanceReportType=CURR_MONTH`
      );
      setRequestedData(response.items);
    } catch (e: any) {
      console.error(e.message);
    }
  };

  const ExportExcel = fileName => {
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(requestedData);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const Tempdata = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(Tempdata, fileName + fileExtension);
  };

  return (
    <div className="couponDetailPopup">
      <div className="couponDetailOverlay">
        <div className="couponDetialPopupBlock" ref={couponDetailPopup}>
          <p className="couponItemCode">
            <button
              onClick={e => {
                closeCouponPopup(e);
              }}
              className="closePopupbtn"
            >
              &#x2715;
            </button>
          </p>
          {isClickedFilter && (
            <p className="couponItemName">
              <div className="table-columns">
                <div className="tableHead">
                  <div className="showColumn">Show Columns</div>
                  <div className="unselectAll" onClick={handleUnSelect}>
                    Unselect All
                  </div>
                </div>

                <div className="columns">
                  <div className="AllColumns">
                    <input
                      type="checkbox"
                      name="allcolumn"
                      onChange={handleAllColumns}
                      checked={
                        allcolumnClicked === true ||
                        intermediateField?.length === 8
                      }
                    />
                    <label>All Columns</label>
                  </div>
                  <div className="followers">
                    <input
                      type="checkbox"
                      name="totalFollowers"
                      onChange={HandleCheck}
                      id="1"
                      checked={intermediateField.includes('1')}
                    />
                    <label>Followers</label>
                  </div>
                  <div className="collections">
                    <input
                      type="checkbox"
                      name="totalCollections"
                      onChange={HandleCheck}
                      id="2"
                      checked={intermediateField.includes('2')}
                    />
                    <label>Collections</label>
                  </div>
                  <div className="clicksonstore">
                    <input
                      type="checkbox"
                      name="ClicksonStore"
                      onChange={HandleCheck}
                      id="3"
                      checked={intermediateField.includes('3')}
                    />
                    <label>Clicks on store</label>
                  </div>
                  <div className="clickoncollections">
                    <input
                      type="checkbox"
                      name="ClicksonCollection"
                      onChange={HandleCheck}
                      id="4"
                      checked={intermediateField.includes('4')}
                    />
                    <label>Clicks on collection</label>
                  </div>
                  <div className="cartproducts">
                    <input
                      type="checkbox"
                      name="InCartProducts"
                      onChange={HandleCheck}
                      id="5"
                      checked={intermediateField.includes('5')}
                    />
                    <label>In cart products</label>
                  </div>
                  <div className="wishlistproduct">
                    <input
                      type="checkbox"
                      name="WishlistedProducts"
                      onChange={HandleCheck}
                      id="6"
                      checked={intermediateField.includes('6')}
                    />
                    <label>Wishlisted products</label>
                  </div>
                  <div className="soldproducts">
                    <input
                      type="checkbox"
                      name="productSold"
                      onChange={HandleCheck}
                      id="7"
                      checked={intermediateField.includes('7')}
                    />
                    <label>Sold products</label>
                  </div>
                  <div className="revenuegenerated">
                    <input
                      type="checkbox"
                      name="revenueGenerated"
                      onChange={HandleCheck}
                      id="8"
                      checked={intermediateField.includes('8')}
                    />
                    <label>Revenue generated</label>
                  </div>
                </div>
                <button className="apply-button" onClick={handleApply}>
                  APPLY
                </button>
              </div>
            </p>
          )}
          {isClickedReport && (
            <div className="table-columns">
              <h2 className="select-report-duration">Select Report Duration</h2>
              <div className="columns requestReportBlock">
                <div className="requestReportItem">
                  <input
                    type="radio"
                    onChange={handleLastYear}
                    checked={lastYear}
                  />
                  <label className="last-year">Last year</label>
                </div>
                <div className="requestReportItem">
                  <input
                    type="radio"
                    onChange={handleCurrentYear}
                    checked={currentYear}
                  />
                  <label className="current-year">Current year</label>
                </div>
                <div className="requestReportItem">
                  <input
                    type="radio"
                    onChange={handleLastMonth}
                    checked={lastmonth}
                  />
                  <label className="last-month">Last month</label>
                </div>
                <div className="requestReportItem">
                  <input
                    type="radio"
                    onChange={handleCurrentMonth}
                    checked={currentMonth}
                  />
                  <label className="last-month">Current month</label>
                </div>
              </div>
              <div className="requestButtons">
                <button
                  className={
                    'request-download-btn ' +
                    (requestDownload ? 'active' : null)
                  }
                  onClick={e => {
                    ExportExcel(fileName);
                    setSendMail(false);
                    setRequestDownload(true);
                  }}
                >
                  REQUEST DOWNLOAD
                  <FontAwesomeIcon
                    icon={faDownload}
                    size="lg"
                    className="icon"
                  />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
