import * as React from 'react';
import cn from 'classnames';
import { useIntl } from 'react-intl';

interface Item {
  name: string;
  active?: boolean;
}

type Props = {
  items: Array<Item>;
  onChange: (items: Item) => void;
};

const MenuButton = ({ active, ...rest }: any) => (
  <button
    className={cn(
      'border-b-2 border-transparent px-2 md:px-10 relative text-sm md:text-base',
      {
        'border-higlight': active,
        'text-higlight': active,
      }
    )}
    style={{ top: '1px' }}
    {...rest}
  />
);

const HeaderMenu = ({ items, onChange }: Props) => (
  <div className="border-b border-border md:px-1 md:px-10">
    {items.map(item => (
      <MenuButton
        onClick={() => onChange(item)}
        active={item.active}
        key={item.name}
      >
        {item.name}
      </MenuButton>
    ))}
  </div>
);

const CollectionHeaderMenu = ({ activeTab, ...rest }: any) => {
  const intl = useIntl();

  const menuItems = React.useMemo(
    () => [
      {
        name: intl.formatMessage({ id: 'collection.tabs.info' }),
        active: activeTab === 'info',
        value: 'info',
      },
      {
        name: intl.formatMessage({ id: 'collection.tabs.products' }),
        active: activeTab === 'products',
        value: 'products',
      },
    ],
    [activeTab, intl]
  );

  return <HeaderMenu items={menuItems} {...rest} />;
};

export { HeaderMenu as default, CollectionHeaderMenu };
