import { Fragment } from 'react';
import ReactPaginate from 'react-paginate';

import ChevronLeft from './ChevronLeftIcon';
import ChevronRight from './ChevronRightIcon';

type Props = {
    numberOfPages: number,
    itemsPerPage: number,
    pageChange: (pageNumber: number) => void,
    fixed?: boolean,
    initialPageNumber?: number;
    disablePrev: boolean;
    disableNext: boolean;
}

const Pagination = ({itemsPerPage, pageChange, numberOfPages, fixed, initialPageNumber, disableNext, disablePrev} : Props) => {
    return (
        <Fragment>
            {numberOfPages > 1 && 
            <div className="width-full flex justify-center mt-10">
                <ReactPaginate 
                initialPage={initialPageNumber ? initialPageNumber : 0}
                pageCount={numberOfPages} 
                pageRangeDisplayed={3} 
                marginPagesDisplayed={3}
                previousLabel={<ChevronLeft disabled={disablePrev}/>}
                nextLabel={<ChevronRight disabled={disableNext}/>}
                pageClassName="flex justify-center px-2 sm:px-6 font-semibold text-black"
                containerClassName="flex text-lg"
                nextClassName="text-black"
                activeClassName="text-gray"
                disabledClassName="text-gray"
                // pageLinkClassName="text-black"
                onPageChange={(obj) => pageChange(obj.selected + 1)}
                />
            </div>}
        </Fragment>
        )
}

export default Pagination;
