import * as React from 'react';
import Menu from './menu';

type Props = {
  children?: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  return (
    <>
      <Menu />
      <div className="ms-16 md:ms-64 flex flex-col border-s border-border min-h-screen">
        {children}
      </div>
    </>
  );
};

export default Layout;
