import { useRef } from 'react';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons'
import icons from 'components/icons';
import Modal from 'components/modal';
import { Slider } from '@mui/material';
import { LoaderOverlay } from 'components/loader';
import mimeTypes from 'utils/mime-types';
import Cropper from 'react-easy-crop';
import getCroppedImg from 'utils/imageCrop';



type Props = {
  initialImageSrc?: string | null;
  onChange: (image: File | null) => void;
};

export default function ImageInput({
  initialImageSrc = null,
  onChange,
}: Props) {
  const imageInputRef = useRef<any>();
  const [image, setImage] = useState<any>(null);
  const [previewImageSrc, setPreviewImageSrc] = useState<string | null>(
    initialImageSrc
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isImageModalOpen, setIsImageModalOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [croppedArea, setCroppedArea] = useState(null);
	const [crop, setCrop] = useState({ x: 0, y: 0 });
	const [zoom, setZoom] = useState<any>(1);
  const [showCropper, setShowCropper] = useState(false);


  const handleAddImage = evt => {
    evt.preventDefault();
    setError(null);
    imageInputRef.current.click();
  };

  const dataUrlToFile = async (dataUrl: any, fileName: string): Promise<File> => {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  }

  const onSelectFile = (event) => {
    setIsLoading(true);
      const reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]);
      reader.addEventListener("load", () => {
        setImage(reader.result);
        setIsLoading(false);
      });
  };

  const onCropEnd = async () => {
    setIsLoading(true);
    setError('');
    const blob : any = await getCroppedImg(image, croppedArea, 0);
    const selectedFile: any = await dataUrlToFile(blob, 'newimg.png');
    setPreviewImageSrc(blob);
    setImage(null);
    setZoom(1);
    setIsLoading(false);
    onChange(selectedFile);
  }
  const closeCropper = () => {
    setShowCropper(false);
    setZoom(1);
  }
  // const handleInputChange = evt => {
  //   const file = evt.target.files![0] || null;

  //   if (file) {
  //     const tempImage = new Image();
  //     const objectUrlFile = URL.createObjectURL(file);
  //     tempImage.onload = () => {
  //       URL.revokeObjectURL(objectUrlFile);
  //       const isCorrectRation =
  //         Math.floor((tempImage.width / tempImage.height) * 100) ===
  //         Math.floor((16 / 9) * 100);
  //       if (isCorrectRation) {
  //         setImage(file);
  //         onChange(file);
  //       } else {
  //         setPreviewImageSrc(null);
  //         setImage(null);
  //         setError('Image aspect ratio is incorrect');
  //       }
  //     };
  //     tempImage.src = objectUrlFile;
  //   }
  // };

  const onCropComplete = (croppedAreaPercentage, croppedAreaPixels) => {
    setCroppedArea(croppedAreaPixels);
  };

  const handleShowImageModal = evt => {
    evt.preventDefault();
    setIsImageModalOpen(true);
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      {image && showCropper &&
        <div className='z-40 w-full h-full fixed top-0 left-0 flex flex-col bg-white items-center p-2'>
        <FontAwesomeIcon className="absolute top-5 right-5" size='3x' color='royalblue' icon={faTimesCircle} onClick={closeCropper}/>
              <div className="relative h-4/6 w-3/4">
                <Cropper
                  image={image}
                  aspect={16/9}
                  crop={crop}
                  zoom={zoom}
                  onCropChange={setCrop}
                  onZoomChange={setZoom}
                  onCropComplete={onCropComplete}
                />
              </div>
              <div className="relative h-1/6 w-full p-5 flex flex-col items-center justify-center">
                <p className="mb-3 pt-1"><h1>Usage Info: </h1>Drag n drop to change crop position. You can zoom in and out by using slider/mouse wheel. Click 'CROP ME' to crop and save the picture.</p>
                <div className="w-full flex items-center">
                  <span className="mr-5">ZOOM: </span>
                  <Slider
                    aria-label="Small steps"
                    defaultValue={1}
                    value={zoom}
                    step={0.05}
                    min={1}
                    max={3}
                    onChange={(event, value) => setZoom(value)}
                  />
                </div>
              </div>
              <div className="realtive h-1/6 w-full p-5">
                <div className="button bg-blue-500 p-8 text-center text-white cursor-pointer rounded" onClick={onCropEnd}>CROP ME</div>
              </div>
				</div>
        // <ReactCrop imageToCrop={image} onImageCropped={() => {}}/>
        }
      {!!previewImageSrc && isImageModalOpen && (
        <Modal onExit={() => setIsImageModalOpen(false)} withExitButton>
          <h1 className="mb-4">Profile Image</h1>
          <img
            src={previewImageSrc!}
            alt="preview"
            className="object-cover object-center w-full h-full shadow-md"
          />
        </Modal>
      )}
      <div className="flex flex-col w-80">
        <span className="text-sm mb-1">
          <FormattedMessage id="account.profilePic" />
        </span>
        <div className="h-44">
          {previewImageSrc ? (
            <div className="w-full h-full relative">
              <img
                src={previewImageSrc}
                className="object-cover object-center w-full h-full rounded-md"
                alt="preview"
              />
              <div className="absolute inset-0 flex justify-between items-end">
                <button
                  onClick={(e) => {
                    handleAddImage(e);
                    setShowCropper(true)
                  }}
                  className="bg-green w-10 h-10 text-white rounded-tr-md rounded-bl-md bg-opacity-80 p-2 hover:bg-opacity-100 transition-all duration-300"
                >
                  {icons.plus}
                </button>
                <button
                  onClick={handleShowImageModal}
                  className="bg-green w-10 h-10 text-white rounded-tl-md rounded-br-md bg-opacity-80 p-2 hover:bg-opacity-100 transition-all duration-300"
                >
                  {icons.search}
                </button>
              </div>
            </div>
          ) : (
            <button
              onClick={handleAddImage}
              className="w-full h-full border-2 border-dotted border-green rounded-md text-green p-4 bg-green bg-opacity-5 hover:bg-opacity-10 transition-all"
            >
              <span className="inline-block w-20 h-20">{icons.plus}</span>
            </button>
          )}
          <input
            type="file"
            ref={imageInputRef}
            className="hidden"
            accept={mimeTypes.image.join(',')}
            onChange={onSelectFile}
            value=""
          />
        </div>
        {error && <span className="text-sm text-error mt-1">{error}</span>}
      </div>
    </>
  );
}
