import * as React from 'react';
import MainRouter from 'routes/main-router';
import UserFetcher from 'components/providers/user-fetcher';

const ProtectedApp = () => {
  return (
    <UserFetcher>
      <MainRouter />
    </UserFetcher>
  );
};

export { ProtectedApp as default };
