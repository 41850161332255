import { path } from 'ramda';
import { AVAILABLE_LANGUAGES, ISO_CODE_BY_COUNTRY } from 'config/const';
import { endpoints } from 'config/api';
import restClient from 'lib/rest-client';

export const getFormattedPriceFromProduct = (
  product: ProductResponse,
  currency: Currency = 'AED'
): string | undefined =>
  path(['price', '0', currency, 'default_formated'], product);

const getProductsLocaleSubPath = (country: Country, lang: Language) => {
  const localeSubPath =
    `/${ISO_CODE_BY_COUNTRY[country]}_${lang}`.toLowerCase();
  return localeSubPath;
};

export const getProductsPath = (
  country: Country,
  lang: Language,
  subpath: string = ''
) => {
  return `${getProductsLocaleSubPath(country, lang)}${
    endpoints.products
  }${subpath}`;
};

export const createProductBySku = (products: Array<ProductResponse>) => {
  return products.reduce((products, product) => {
    return { ...products, [product.sku]: product };
  }, {});
};

export const fetchProductsBySkus = async (
  skus: Array<string>,
  country: Country,
  lang: Language = AVAILABLE_LANGUAGES.ENGLISH
): Promise<Array<ProductResponse>> => {
  if (!skus || !skus.length) return [];

  const { data: products } = await restClient.post(
    `${getProductsLocaleSubPath(country, lang)}${endpoints.products}/skus`,
    { body: { ids: skus } }
  );
  return handleMisingProducts(skus, products);
};

function handleMisingProducts(
  skus: Array<string> = [],
  fetchedProducts: Array<ProductResponse> = []
): Array<any> {
  return skus.map(sku => {
    return (
      fetchedProducts.find(p => p.sku === sku) || {
        sku,
        name: null,
        thumbnail_url: null,
      }
    );
  });
}
