import * as React from 'react';
import { IntlProvider } from 'react-intl';
import enMessages from 'locales/en';
import arMessages from 'locales/ar';
import { useSelector } from 'react-redux';
import { langSelector } from 'lib/redux';
import { AVAILABLE_LANGUAGES } from 'config/const';

type Props = {
  children: React.ReactNode;
};

const Intl = ({ children }: Props) => {
  const lang = useSelector(langSelector);
  const messages =
    lang === AVAILABLE_LANGUAGES.ARABIC ? arMessages : enMessages;

  return (
    <IntlProvider
      messages={messages}
      locale={lang}
      defaultLocale={AVAILABLE_LANGUAGES.ENGLISH}
    >
      {children}
    </IntlProvider>
  );
};

export default Intl;
