import * as React from 'react';

type Props = {
  acceptedMime: string;
  children: React.ReactNode;
  className?: string;
  onFileSelected?: (param: any) => void;
};

const FileInput = ({
  acceptedMime,
  children,
  onFileSelected,
  className,
}: Props) => {
  const onChange = (event: any) => {
    onFileSelected && onFileSelected(event.target.files[0]);
  };
  return (
    <label className={`${className || ''}`}>
      {children}
      <input
        type="file"
        className={`opacity-0 hidden ${className || ''}`}
        accept={acceptedMime}
        onChange={onChange}
      />
    </label>
  );
};

export default FileInput;
