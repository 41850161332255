import {useState, useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';

import {collectionOrderFilterSelector} from 'lib/redux';
import {pickCountry} from 'lib/redux/collectionsOrderFilter/actions';
import restClient from 'lib/rest-client';
import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import PageTitle from 'components/PageTitle';
import { LoaderOverlay } from 'components/loader';
import CountryPickerList from 'components/CountryPicker/CountryPickerList';
import SliderPanel from './SliderPanel';
import AdditionalPanel from './AdditionalPanel';
import { getResponseErrorMessage, useCountries } from 'utils/common';
import Modal from 'components/modal';
import Switch from 'components/SwitchInput/Switch';
import RadioButton from 'components/radio-button';



const SliderControl = ({}) => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState<null | string>(null);
    const [sliderItems, setSliderItems] = useState<any>([]);
    const [sliderItemsone, setSliderItemsOne] = useState<any>([]);
    const [sliderItemstwo, setSliderItemsTwo] = useState<any>([]);
    const [sliderLength, setSliderLength] = useState(0);
    const [sliderLengthone, setSliderLengthOne] = useState(0);
    const [sliderLengthtwo, setSliderLengthTwo] = useState(0);
    const [additionalItems, setAdditionalItems] = useState<any>([]);
    const [sliderIsFull, setSliderIsFull] = useState(false);
    const [pickedItem, setPickedItem] = useState<any>(null);
    const [isSuperstar, setIsSuperstar] = useState(false);
    const [slidernumzero,setSlidernumzero] = useState(0);
    const [slidernumone,setSlidernumone] = useState(1);
    const [slidernumtwo,setSlidernumtwo] = useState(2);

    const countries = useCountries();
    const dispatch = useDispatch();
    const { pickedCountry } = useSelector(collectionOrderFilterSelector);

    useEffect(() => {
        fetchSlider();
        fetchSliderOne();
        fetchSliderTwo();
        fetchAdditionalItems();
    }, [pickedCountry, isSuperstar])

    const handleCountryPick = (country: Country) => {
        dispatch(pickCountry(country));
    }

    const fetchSlider = async () => {
        setError(null);
        setIsLoading(true);
        try{
            const data = await restClient.get(`/collections/order/${pickedCountry}/sliderNumber?starStatus=${isSuperstar ? 'SUPERSTAR' : 'STAR'}&sliderNum=${slidernumzero}`);

            setSliderItems(data.items);
            setSliderLength(data.items.length);
        } catch (error:any) {
            setError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const fetchSliderOne = async () => {
        setError(null);
        setIsLoading(true);
        try{
            const data = await restClient.get(`/collections/order/${pickedCountry}/sliderNumber?starStatus=${isSuperstar ? 'SUPERSTAR' : 'STAR'}&sliderNum=${slidernumone}`);

            setSliderItemsOne(data.items);
            setSliderLengthOne(data.items.length);
        } catch (error:any) {
            setError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const fetchSliderTwo = async () => {
        setError(null);
        setIsLoading(true);
        try{
            const data = await restClient.get(`/collections/order/${pickedCountry}/sliderNumber?starStatus=${isSuperstar ? 'SUPERSTAR' : 'STAR'}&sliderNum=${slidernumtwo}`);

            setSliderItemsTwo(data.items);
            setSliderLengthTwo(data.items.length);
        } catch (error:any) {
            setError(error)
        } finally {
            setIsLoading(false);
        }
    }

    const fetchAdditionalItems = async () => {
        setError(null);
        setIsLoading(true);
        try{
            const data = await restClient.get(`/collections/order/${pickedCountry}/banners?starStatus=${isSuperstar ? 'SUPERSTAR' : 'STAR'}`, {
            body: {
                starStatus: isSuperstar ? 'SUPERSTAR' : 'STAR'
            }
        });
            setAdditionalItems(data.items);
        } catch(error: any) {
            setError(error);
        } finally {
            setIsLoading(false);
        }
    }

    const updateSlider = async (collectionId, order) => {
        setIsLoading(true);
        setError(null);
        
        try{
            const data = await restClient.put(`/collections/order/${pickedCountry}/sliderNumber?starStatus=${isSuperstar ? 'SUPERSTAR' : 'STAR'}&sliderNum=${slidernumzero}`,{
                body:{ id: collectionId, order: sliderLength < order ? sliderLength : order }
            });
            fetchSlider();
            fetchSliderOne();
            fetchSliderTwo();
            fetchAdditionalItems();
        }catch(error: any){
            setError(error);
            setSliderIsFull(true);
        }finally{
            setIsLoading(false);
        }
    }

    const updateSliderOne = async (collectionId, order) => {
        setIsLoading(true);
        setError(null);
        
        try{
            const data = await restClient.put(`/collections/order/${pickedCountry}/sliderNumber?starStatus=${isSuperstar ? 'SUPERSTAR' : 'STAR'}&sliderNum=${slidernumone}`,{
                body:{ id: collectionId, order: sliderLengthone < order ? sliderLengthone : order }
            });
            fetchSlider();
            fetchSliderOne();
            fetchSliderTwo();
            fetchAdditionalItems();
        }catch(error: any){
            setError(error);
            setSliderIsFull(true);
        }finally{
            setIsLoading(false);
        }
    }

    const updateSliderTwo = async (collectionId, order) => {
        setIsLoading(true);
        setError(null);
        
        try{
            const data = await restClient.put(`/collections/order/${pickedCountry}/sliderNumber?starStatus=${isSuperstar ? 'SUPERSTAR' : 'STAR'}&sliderNum=${slidernumtwo}`,{
                body:{ id: collectionId, order: sliderLengthtwo < order ? sliderLengthtwo : order }
            });
            fetchSlider();
            fetchSliderOne();
            fetchSliderTwo();
            fetchAdditionalItems();
        }catch(error: any){
            setError(error);
            setSliderIsFull(true);
        }finally{
            setIsLoading(false);
        }
    }

    const updateAdditionalItems = async (collectionId, order) => {
        setIsLoading(true);
        setError(null);
        try{
            const data = await restClient.put(`/collections/order/${pickedCountry}/banners`,{
                body:{ id: collectionId, order: order }
            });
            fetchSlider();
            fetchSliderOne();
            fetchSliderTwo();
            fetchAdditionalItems();
        }catch(error: any){
            setError(error);
        }finally{
            setIsLoading(false);
        }
    }

    const pickItem = (place, itemId) => {
        setPickedItem(itemId);
    }

    const setItem = (place, orderNumber) => {
        switch(place)
        {
            case 'SLIDER':
                updateSlider(pickedItem, orderNumber); 
                break;
            case 'SLIDER1':
                updateSliderOne(pickedItem, orderNumber);
                break;
            case 'SLIDER2':
                updateSliderTwo(pickedItem, orderNumber);
                break;
            default:
                updateAdditionalItems(pickedItem, orderNumber);
        }
        setPickedItem(null);
    }

    const onExitModal = () => {
        setError(null);
        setSliderIsFull(false);
    }


    return (
        <Layout>
            <Header>
                <PageTitle title="Collections Order"/>
            </Header>
            <Content>
                {isLoading && <LoaderOverlay />}
                {sliderIsFull && <Modal onExit={onExitModal} withExitButton><div className="p-20 pt-24 flex flex-col"><p className="mb-10">Slider is full! Please make a free spot first.</p><button onClick={onExitModal} className="bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:text-white py-2 px-4 border border-blue-500 hover:border-transparent rounded">Close</button></div></Modal>}
                <div className="flex justify-between">
                    <CountryPickerList countryList={countries} onCountryPick={handleCountryPick} pickedCountry={pickedCountry}/>
                    {/* <Switch firstValue="Star" secondValue="Superstar" isOn={isSuperstar} handleToggle={() => setIsSuperstar(!isSuperstar)}/> */}
                </div>
                <div className="flex flex-col sm:ml-2 mt-5">
                    <h5>Type:</h5>
                    <RadioButton 
                      checked={isSuperstar} 
                      label="Superstars" 
                      onChange={() => setIsSuperstar(true)} 
                      value='SUPERSTAR'
                    />
                    <RadioButton 
                      checked={!isSuperstar} 
                      label="Stars" 
                      onChange={() => setIsSuperstar(false)} 
                      value='STAR'
                    />
                </div>
                <SliderPanel sliderItems={sliderItems} sliderItemsone={sliderItemsone} sliderItemstwo={sliderItemstwo} pickedItem={pickedItem} pickItem={pickItem} setItem={setItem}/>
                <AdditionalPanel items={additionalItems} pickedItem={pickedItem} pickItem={pickItem} setItem={setItem} hasPinnedCollections={sliderItems.length < 0 && additionalItems.length < 0}/>
            </Content>
        </Layout>
    );
}

export default SliderControl;