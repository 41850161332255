import { Dispatch } from 'redux';
import restClient from 'lib/rest-client';
import { changeLanguage } from 'lib/redux/lang/actions';
import { endpoints } from 'config/api';

const TYPES = {
  SET_USER: 'SET_USER',
};

const setUser = payload => {
  return { type: TYPES.SET_USER, payload };
};

const fetchUserDetails =
  () =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      const user = await restClient.get(endpoints.user);
      dispatch(setUser(user));
      dispatch(changeLanguage(user.language));
    } catch (error) {
      throw error;
    }
  };

const updateUserSettings =
  (body: User) =>
  async (dispatch: Dispatch): Promise<any> => {
    try {
      const updatedUser: User = await restClient.patch(endpoints.user, {
        body,
      });
      dispatch(setUser(updatedUser));
      dispatch(changeLanguage(updatedUser.language));
    } catch (error) {
      throw error;
    }
  };

export { TYPES, setUser, fetchUserDetails, updateUserSettings };
