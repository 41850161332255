import englishTranslations from './en';

const fromEnglish = Object.keys(englishTranslations).reduce(
  (translations, key) => {
    translations[key] = englishTranslations[key];
    return translations;
  },
  {}
);

const ar = {
  'common.companyName': '6ستريت',
  'common.countries.qat': 'قطر',
  'common.countries.are': 'الإمارات العربية المتحدة',
  'common.countries.sau': 'السعودية',
  'common.countries.kwt': 'الكويت',
  'common.countries.omn': 'عمان',
  'common.countries.bhr': 'البحرين',
  'common.countries.egy':"مصر",
  'common.languages.en': 'إنجليزي',
  'common.languages.ar': 'عربي',
  'common.selectOption': 'حدد الخيار',
  'common.retry': 'أعد المحاولة',
  'common.pinCollection': 'جمع دبوس',
  'common.unpinCollection': 'إلغاء تثبيت مجموعة',
  'auth.welcomeBack': 'مرحبا بعودتك',
  'auth.usernamePlaceholder': 'البريد الإلكتروني أو رقم الهاتف',
  'auth.passwordPlaceholder': 'كلمه السر',
  'auth.signIn': 'تسجيل الدخول',
  'auth.setupNewPassword': 'إعادة تعيين كلمة مرور جديدة',
  'auth.resetPassword': 'إعادة تعيين كلمة المرور ',
  'menu.dashboard': 'لوحة القيادة',
  'menu.collections': 'التشكيلات',
  'menu.featuredProducts': 'منتجات مميزة',
  'menu.account': 'الحساب',
  'menu.settings': 'إعدادات',
  'menu.logout': 'تسجيل خروج',
  'menu.helpCenter': 'مركز المساعدة',
  'menu.createUser': 'إنشاء مستخدم',
  'menu.influencers': 'المشاهير',
  'menu.pendingApprovals': 'الموافقات المعلقة',
  'settings.language': 'اللغة',
  'settings.country': 'الدولة',
  'settings.english': 'English / اللغة الانجليزية',
  'settings.arabic': 'Arabic / العربية',
  'account.name': 'الإسم',
  'account.familyName': 'إسم العائلة',
  'account.influencerName': 'المدونةالمدون',
  'account.phone': 'رقم الهاتف',
  'account.email': 'البريد الإلكتروني',
  'account.password': 'كلمة مرور',
  'account.change': 'تعديل',
  'account.profilePic': 'الصورة الشخصية, معيار 16:9 (.jpg .png)',
  'account.profilePic.invalidAspect':
    'Profile picture should have aspect ratio 16:9',
  'account.save': 'حفظ',
  'account.saving': '...يتم حفظ الصورة',
  'changePassword.كلمة المرور الحالية': '',
  'changePassword.newPassword': 'كلمة مرور جديدة',
  'changePassword.confirmNewPassword': 'تأكيد كلمة المرور الجديدة',
  'changePassword.passwordsDoNotMatch': 'كلمة المرور غير متطابقة',
  'changePassword.change': 'تعديل',
  'changePassword.changing': 'يتم تعديل كلمة المرور...',
  'collection.title.label': 'إسم المجموعة (20 حرف كحد أقصى)',
  'collection.title.placeholder': 'إسم المجموعة',
  'collection.arabicTitle.label': 'إسم المجموعة بالعربي',
  'collection.arabicTitle.placeholder': 'إسم المجموعة العربية',
  'collection.liveCollections': 'التشكيلات المتوفرة حالياً',
  'collection.desc.label': 'وصف المجموعة (20 حرف كحد أقصى)',
  'collection.desc.placeholder': 'اكتب الوصف هنا...',
  'collection.arabicDesc.label': 'وصف المجموعة بالعربي',
  'collection.arabicDesc.placeholder': 'اكتب الوصف هنا...',
  'collection.saveButton.text': 'حفظ الوصف وإرساله',
  'collection.saveDraftButton.text': 'حفظ',
  'collection.submitButton.text': 'إرسال',
  'collection.updateButton.text': 'تحديث',
  'collection.withdrawButton.text': 'إلغاء',
  'collection.deleteButton.text': 'حذف',
  'collection.rejectButton.text': 'رفض',
  'collection.approveButton.text': ',موافق',
  'collection.coverImage.label': 'صورة الغلاف 500px X 700px (.jpg .png)',
  'collection.collections': 'المجموعات',
  'collection.tabs.info': 'معلومات',
  'collection.tabs.products': 'منتجات',
  'collection.media': 'ملف وسائط: Image(png, jpeg), Video(mp4, 3gpp, 3gpp2)',
  'collection.feedback': 'ملاحظات',
  'collection.arabicFeedback': 'ملاحظات بالعربي',
  'collection.sendFeedback': 'إرسال الملاحظات',
  'collection.noCollections': 'لا يوجد تشكيلات',
  'products.search.placeholder': 'البحث عن طريق الإسم, الماركة SKU',
  'collection.products.addtoCollection': 'أضف إلى المجموعة',
  'collection.influencer': 'المؤثرالمؤثرة',
  'collection.addNew': 'إضافة',
  'upload.tooLargeFile': 'الملف يتجاوز الحجم المسموح ',
  'upload.codecNotSupported': 'المعيار غير صالح',
  'upload.url': 'عنوان url: ',
  'upload.fileName': 'اسم الملف: ',
  'upload.cannotLoadFile': 'لا يمكن تحميل الملف',
  'collection.wrongCoverImageSize':
    'حجم صورة الغلاف غير صحيح - يجب أن يكون 500px X 700px (عرضx طول)',
  'collection.badge.draft': 'مسودة ',
  'collection.badge.inReview': 'قيد التحميل',
  'collection.badge.live': 'Live',
  'collection.badge.rejected': 'تم رفضه',
  'featuredProducts.noProducts': 'لا توجد منتجات',
  'featuredProducts.title': 'منتجات مميزة',
  'dashboard.overview': 'نبذة',
  'dashboard.liveCollections': 'التشكيلات المتوفرة حالياً',
  'dashboard.draftCollections': 'مجموعة مسودة',
  'dashboard.collections': 'المجموعات',
  'helpCenter.title': 'خدمة العملاء',
  'helpCenter.availabilityText': 'متواجدون من: 9 صباحًا - 9 مساءً',
  'influencers.emptyList': 'لا يوجد ',
  'influencers.create': 'ابتكر',
  'influencers.featuredProducts': 'منتجات مميزة',
  'influencers.collection': 'المجموعة',
  'loader.text': 'الرجاء الانتظار قد يستغرق هذا بعض الوقت',
};

export default { ...fromEnglish, ...ar };
