import are from 'assets/img/flags/are.svg';
import bhr from 'assets/img/flags/bhr.svg';
import en from 'assets/img/flags/en.svg';
import kwt from 'assets/img/flags/kwt.svg';
import omn from 'assets/img/flags/omn.svg';
import qat from 'assets/img/flags/qat.svg';
import sau from 'assets/img/flags/sau.svg';
import {COUNTRY_PICKER_ITEMS} from 'config/const';

type Props = {
    countrySc: Country;
    imagePath: string;
    countryName: string;
    onCountryPick: (countryName: Country) => void;
    active: boolean;
}

const countryCodeToFlag = {
    are,
    bhr,
    en,
    kwt,
    omn,
    qat,
    sau,
  };

const CountryPickerItem = ({countrySc, imagePath, countryName, onCountryPick, active} : Props) => {
    return (
        <div 
            className={active ? "rounded flex justify-between items-center bg-yellow-100 text-yellow-600 p-2 px-4 cursor-pointer" : "flex p-2 px-4 justify-between items-center hover:bg-yellow-50 hover:text-yellow-400 cursor-pointer"} 
            onClick={() => onCountryPick(countrySc)}
        >
            <img className="w-7 mr-2" src={countryCodeToFlag[countrySc.toLocaleLowerCase()]} alt={countryName} />
            <span>{countrySc}</span>
        </div>
    )
}

export default CountryPickerItem;