import * as React from 'react';
import { useSelector } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { endpoints } from 'config/api';
import { AuthButton as Button } from 'components/button';
import UserDetails from 'components/user-details';
import { userSelector } from 'lib/redux';
import Content from 'components/content';
import ErrorBox from 'components/error-box';
import Header from 'components/header';
import Layout from 'components/layout';
import { LoaderOverlay } from 'components/loader';
import restClient from 'lib/rest-client';
import { getResponseErrorMessage } from 'utils/common';
import { successToast } from 'components/toast/toast';
import { useMemo } from 'react';
import PageTitle from 'components/PageTitle';

type SynchronizationEntry = {
  createdAt: string;
  id: string;
  state: 'CREATED' | 'RUNNING' | 'COMPLETED' | 'FAILED';
};

const SynchronizationPage = () => {
  const currentUser = useSelector(userSelector);

  const [error, setError] = React.useState<null | string>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const [synchronizations, setSynchronizations] = React.useState<
    Array<SynchronizationEntry>
  >([]);

  const fetchSynchornizations = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const response: { items: Array<SynchronizationEntry> } =
        await restClient.get(endpoints.adminSynchronizations);
      setSynchronizations(response.items);
    } catch (error) {
      setError(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }, []);

  const handleCreateSynchronization = async () => {
    try {
      setIsLoading(true);
      const response = await restClient.post(endpoints.adminSynchronizations);
      successToast('Synchonization has been created');
    } catch (error) {
      setError(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchSynchornizations();
  }, [fetchSynchornizations]);

  const isListEmpty = useMemo(
    () => !synchronizations || !synchronizations.length,
    [synchronizations]
  );
  
  return (
    <>
      {isLoading && <LoaderOverlay />}
      <Layout>
        <Header>
          <PageTitle title="Synchronization"/>
        </Header>
        <Content>
          <h1 className="mb-8 flex justify-between items-center">
            <FormattedMessage id="menu.synchronization" />
            <span>Commit hash: {process.env.REACT_APP_GIT_SHA}</span>
          </h1>
          {error && <ErrorBox>{error}</ErrorBox>}
          {!error && !isLoading && (
            <div className="flex flex-col items-start space-y-6">
              <div className="flex space-x-4">
                <Button
                  color="green"
                  onClick={async () => {
                    await handleCreateSynchronization();
                    await fetchSynchornizations();
                  }}
                >
                  <FormattedMessage id="synchronization.synchronize" />
                </Button>
                <Button color="green" onClick={fetchSynchornizations}>
                  <FormattedMessage id="common.refresh" />
                </Button>
              </div>
              {isListEmpty ? (
                <h1>
                  <FormattedMessage id="synchronization.listEmpty" />
                </h1>
              ) : (
                <table className="w-full">
                  <thead>
                    <tr>
                      <th>
                        <FormattedMessage id="synchronization.createdAt" />
                      </th>
                      <th>
                        <FormattedMessage id="synchronization.status" />
                      </th>
                      <th>ID</th>
                    </tr>
                  </thead>
                  <tbody>
                    {synchronizations.map(synchronization => (
                      <tr key={synchronization.id}>
                        <td>
                          {new Date(synchronization.createdAt).toLocaleString()}
                        </td>
                        <td>{synchronization.state}</td>
                        <td>{synchronization.id}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          )}
        </Content>
      </Layout>
    </>
  );
};

export default SynchronizationPage;
