import * as React from 'react';
import cn from 'classnames';
import { getFormattedPriceFromProduct } from 'utils/product';
import icons from 'components/icons';
import { motion } from 'framer-motion';
import { DndContext, DragEndEvent, closestCenter } from '@dnd-kit/core';
import {
  SortableContext,
  useSortable,
  arrayMove,
  rectSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToParentElement } from '@dnd-kit/modifiers';
import { FormattedMessage } from 'react-intl';
import CollectionStatusWarn from './UI/collectionStatus/collectionStatusWarn';
import { divide } from 'ramda';

type ContainerProps = {
  products: Array<ProductResponse>;
  onProductRemove: (product: ProductResponse) => void;
  onOrderChange: (sortedProducts: Array<ProductResponse>) => void;
  readOnly?: boolean;
  itemsErrors?: any;
};

export const SelectedProductsContainer = ({
  products,
  onProductRemove,
  onOrderChange,
  readOnly = false,
  itemsErrors
}: ContainerProps) => {
  const [orderedSkus, setOrderedSkus] = React.useState(
    products.map(product => product.sku)
  );
  const productBySku: { [key: string]: any } = React.useMemo(() => {
    return products.reduce((acc, product) => {
      return { ...acc, [product.sku]: product };
    }, {});
  }, [products]);
  const handleDragEnd = ({ over, active }: DragEndEvent) => {
    const oldIndex = orderedSkus.indexOf(active.id);
    const newIndex = over ? orderedSkus.indexOf(over.id) : -1;
    const newSkus = arrayMove(orderedSkus, oldIndex, newIndex);
    setOrderedSkus(newSkus);
    onOrderChange(newSkus.map(sku => productBySku[sku]));
  };

  const handleProductRemove = (product: ProductResponse) => {
    setOrderedSkus(orderedSkus.filter(sku => sku !== product.sku));
    onProductRemove(product);
  };

  React.useEffect(() => {
    setOrderedSkus(products.map(product => product.sku));
  }, [products]);

  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragEnd={handleDragEnd}
      modifiers={[restrictToParentElement]}
    >
      <SortableContext strategy={rectSortingStrategy} items={orderedSkus}>
        <div className="mt-8 md:mt-16">
          {!!orderedSkus.length && (
            <div className="">
              <span className="text-xl font-bold">
                <FormattedMessage
                  id="products.productsCountAdded"
                  values={{ count: orderedSkus.length }}
                />
              </span>
              {!readOnly && (
                <span className="text-base text-secondary">
                  {' - '}
                  <FormattedMessage id="products.dragAndDrop" />
                </span>
              )}
            </div>
          )}
          <div className="flex flex-wrap mt-4 md:mt-12">
            {orderedSkus.map(sku => (
              <Product
                itemErrors={itemsErrors?.find(item => item.sku == sku)}
                key={sku}
                onRemove={handleProductRemove}
                product={productBySku[sku]}
                readOnly={readOnly}
              />
            ))}
          </div>
        </div>
      </SortableContext>
    </DndContext>
  );
};

type ProductProps = {
  product?: ProductResponse;
  onRemove: (product: ProductResponse) => void;
  readOnly?: boolean;
  itemErrors?: any;
};

const Product = ({ readOnly = false, onRemove, product, itemErrors }: ProductProps) => {
  const { attributes, setNodeRef, listeners, transform, isDragging } =
    useSortable({
      id: product ? product.sku : 'temp-id',
      transition: null,
      disabled: readOnly,
    });
  const [imageError, setImageError] = React.useState<boolean|null>(null);
  if (!product) return null;

  const price = getFormattedPriceFromProduct(product);
  return (
    <motion.div
      className={cn(
        'w-200 flex flex-col justify-start items-center relative bg-white m-2',
        { 'z-10 shadow-lg': isDragging }
      )}
      ref={setNodeRef}
      layoutId={product.sku}
      animate={{
        x: transform ? transform.x : 0,
        y: transform ? transform.y : 0,
      }}
      transition={{
        duration: !isDragging ? 0.25 : 0,
        easings: {
          type: 'spring',
        },
        scale: {
          duration: 0.25,
        },
        zIndex: {
          delay: isDragging ? 0 : 0.25,
        },
      }}
      {...attributes}
      {...listeners}
    >
      {!readOnly && <CloseIcon onClick={() => onRemove(product)} />}
      <div className="h-product-card-image w-full">
        {itemErrors?.errors && !!itemErrors.errors && <div className="absolute bg-white m-2 p-1 text-black rounded">{itemErrors.errors[0].type} <CollectionStatusWarn/></div>}
        {product.thumbnail_url && !imageError ? (
          <img
            src={product.thumbnail_url}
            alt={product.name}
            className="w-full h-full object-cover object-center rounded-t-md"
            onError={(e) => setImageError(true)}
          />
        ) : (
          <div className="text-5xl bg-lightGray text-secondary h-full w-full flex justify-center items-center">
            N/A
          </div>
        )}
      </div>
      <div className="p-3">
        {product.brand_name && (
          <span className="inline-block mt-3 text-sm leading-5 uppercase font-bold text-center">
            {product.brand_name}
          </span>
        )}
        <span className="inline-block text-sm text-secondary text-center">
          {product.name || product.sku}
        </span>
        {price && (
          <span className="inline-block mt-3 text-sm leading-5 uppercase font-bold text-center">
            {price}
          </span>
        )}
      </div>
    </motion.div>
  );
};

const CloseIcon = ({ onClick }: any) => {
  return (
    <span
      className="inline-block absolute top-0 right-0 m-2 w-8 h-8 p-2 bg-lightGray hover:bg-error hover:text-white transition-colors duration-500 rounded-full cursor-pointer shadow-md"
      onClick={onClick}
    >
      {icons.cross}
    </span>
  );
};

export default Product;
