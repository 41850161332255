import { useEffect, useState } from 'react';
import CollectionsOrderItem from './CollectionsOrderItem';
import { PlaceholderItem } from './CollectionsOrderItem';

const SliderPanel = ({sliderItems, pickItem, setItem, pickedItem ,sliderItemsone, sliderItemstwo}) => {

    const [data, setData] = useState<any>([]);
    const [dataone,setDataOne] = useState<any>([]);
    const [datatwo,setDataTwo] = useState<any>([]);

    useEffect(() => {
        generatePanel();
        generatePanelOne();
        generatePanelTwo();
    }, [sliderItems,sliderItemsone,sliderItemstwo]);

    const generatePanel = () => {
        const panelItems = ['','','','',''];
        const generatedArray = panelItems.map((item, index) => {
            const findItem = sliderItems.find(item => item.position === index);
            return findItem ? findItem.data : null;
        })
        setData(generatedArray);
    }

    const generatePanelOne = () => {
        const panelItems = ['','','','',''];
        const generatedArray = panelItems.map((item, index) => {
            const findItem = sliderItemsone.find(item => item.position === index);
            return findItem ? findItem.data : null;
        })
        setDataOne(generatedArray);
    }

    const generatePanelTwo = () => {
        const panelItems = ['','','','',''];
        const generatedArray = panelItems.map((item, index) => {
            const findItem = sliderItemstwo.find(item => item.position === index);
            return findItem ? findItem.data : null;
        })
        setDataTwo(generatedArray);
    }
    return (
        <div className="flex mt-10 flex-col">
            <h1>First Slider</h1>
            <div className="flex mt-5">
                {(data && data.length > 0) && data.map((item, index) => item ? 
                <div onClick={!!pickedItem ? () => setItem("SLIDER", index) : () => pickItem("SLIDER", item.id)}><CollectionsOrderItem pickedItem={pickedItem} data={item} key={index}/></div> 
                :
                <div onClick={!!pickedItem ? () => setItem("SLIDER", index) : () => {}}><PlaceholderItem key={index} text="- Empty Slot -"/></div>)}
            </div>
            <h1>Second Slider</h1>
            <div className="flex mt-5">
                {(dataone && dataone.length > 0) && dataone.map((item, index) => item ? 
                <div onClick={!!pickedItem ? () => setItem("SLIDER1", index) : () => pickItem("SLIDER1", item.id)}><CollectionsOrderItem pickedItem={pickedItem} data={item} key={index}/></div> 
                :
                <div onClick={!!pickedItem ? () => setItem("SLIDER1", index) : () => {}}><PlaceholderItem key={index} text="- Empty Slot -"/></div>)}
            </div>
            <h1>Third Slider</h1>
            <div className="flex mt-5">
                {(datatwo && datatwo.length > 0) && datatwo.map((item, index) => item ? 
                <div onClick={!!pickedItem ? () => setItem("SLIDER2", index) : () => pickItem("SLIDER2", item.id)}><CollectionsOrderItem pickedItem={pickedItem} data={item} key={index}/></div> 
                :
                <div onClick={!!pickedItem ? () => setItem("SLIDER2", index) : () => {}}><PlaceholderItem key={index} text="- Empty Slot -"/></div>)}
            </div>
        </div>
    )
}



export default SliderPanel;