import { Dispatch } from 'redux';

export const types = {
    VALIDATE_COLLECTION_INPUT: "VALIDATE_COLECTION_INPUT"
}

export const validateCollectionInput = (valid : string, name: string) => (dispatch : Dispatch) => {
    dispatch({ type: types.VALIDATE_COLLECTION_INPUT, value: valid, name });
}




