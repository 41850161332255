import * as React from 'react';
import { Route, Switch, BrowserRouter as Router } from 'react-router-dom';
import { getMenuItemByRole } from './routes-config';
import { NotFoundPage } from 'pages';
import { useSelector } from 'react-redux';
interface RouteType {
  path: string | Array<string>;
  component: any;
  exact?: boolean;
}

const notFoundRoute: RouteType = {
  path: '*',
  component: NotFoundPage,
};

const MainRouter = () => {
  const userRole = useSelector((state: any) => state.user.role);

  const routes: Array<RouteType> = [
    ...getMenuItemByRole(userRole),
    notFoundRoute,
  ];

  return (
    <Router>
      <Switch>
        {routes.map((route, key) => (
          <Route
            path={route.path}
            component={route.component}
            key={key}
            exact={route.exact}
          />
        ))}
      </Switch>
    </Router>
  );
};

export default MainRouter;
