import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack } from '@fortawesome/free-solid-svg-icons';
import { FormattedMessage } from 'react-intl';

type Props = {
    isPinned: string;
    handlePin: () => void;
}

const PinCollection = ({isPinned, handlePin}) => {
    return (
        <div onClick={handlePin} className="flex justify-center items-center text-lg mt-2 cursor-pointer">
            <FontAwesomeIcon icon={faThumbtack} size="1x" className="mr-2"/>
            {isPinned ? 
                <div className="break-all">
                    <FormattedMessage id="common.unpinCollection"/>
                </div> 
                : 
                <div>
                    <FormattedMessage id="common.pinCollection"/>
                </div>
                }
        </div>
    );
}
export default PinCollection;