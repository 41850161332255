import {useState, useEffect} from 'react';
import { useHistory} from 'react-router-dom';

import { endpoints } from 'config/api';
import restClient from 'lib/rest-client';
import { getResponseErrorMessage, useCountries } from 'utils/common';
import { AVAILABLE_COUNTRIES } from 'config/const';

import Layout from 'components/layout';
import Content from 'components/content';
import Header from 'components/header';
import PageTitle from 'components/PageTitle';
import { LoaderOverlay } from 'components/loader';
import {AddCollectionButtonCard} from 'components/add-collection-button';
import CollectionCard from 'components/collection-card';
import CountryPickerList from 'components/CountryPicker/CountryPickerList';

const FeaturedProductsList = ({}) => {

    const history = useHistory();
    const countries = useCountries();

    const [error, setError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState<null | Array<FeaturedProductsCollection>>(null);
    const [selectedCountry, setSelectedCountry] = useState<Country>('ARE');
    const [button, setButton] = useState<boolean>(true);
    

    useEffect(() => {
        setButton(true);
        fetchFeaturedProducts();
    }, [selectedCountry])

    const checkButton = (data) => {
        const checkArr : any = [];
        data!.forEach((item) => {
            item.audience.forEach((item) => ['WOMEN', 'MEN', 'KIDS'].includes(item) && !checkArr.includes(item) && checkArr.push(item));
        })
        if(checkArr.length === 3){
            setButton(false);
        }
    }

    const fetchFeaturedProducts = async () => {
        try{
            setIsLoading(true);
            const response: Array<FeaturedProductsCollection> = await restClient.get(endpoints.featuredProducts, {
                queryStringParameters:{
                    country: selectedCountry
                }
            });
            checkButton(response);
            setData(response);
        }catch(e: any){
            setError(e.message)
        }finally{
            setIsLoading(false);
        }
    }

    const createNewFeaturedProduct = () => {
        history.push(`/featured-edit?country=${selectedCountry}`);
    }

    const handleItemClick = (fp: FeaturedProductsCollection) => {
        history.push(`/featured-edit?editId=${fp.id}&country=${fp.country}`);
      };

    return (
        <Layout>
            <Header>
                <PageTitle title="Featured Products" />
            </Header>
            <Content>
                {isLoading && <LoaderOverlay />}
                <div className="mb-10">
                    <CountryPickerList pickedCountry={selectedCountry} countryList={countries} onCountryPick={setSelectedCountry}/>
                </div>
                {!isLoading && data ? (
                    <div className="flex flex-wrap">
                    <AddCollectionButtonCard onClick={createNewFeaturedProduct} isActive={button}/>
                    {data.map(fp => (
                        <CollectionCard
                        onClick={handleItemClick as any}
                        collection={fp}
                        key={fp.id}
                        isFP
                        canDuplicate
                        />
                    ))}
                    </div>
                ) : <AddCollectionButtonCard onClick={createNewFeaturedProduct} isActive={button}/>}
            </Content>
        </Layout>
    )
}

export default FeaturedProductsList;