import * as React from 'react';
import ProtectedApp from 'protected-app';
import { Provider as ReduxProvider } from 'react-redux';
import AppIntlProvider from 'components/providers/intl-provider';
import Head from 'components/head';
import { store } from 'lib/redux';
import authConfig from 'aws-exports';
import Amplify, { Auth } from 'aws-amplify';
import { AWSAuth } from 'pages';
import ToastContainer from 'components/toast/toast-container';

Amplify.configure(authConfig);

const App: React.FC = () => {
  const [isAuth, setIsAuth] = React.useState(false);
  const [isCheckingAuth, setIsCheckingAuth] = React.useState(true);

  const checkCurrentUser = async () => {
    try {
      setIsCheckingAuth(true);
      await Auth.currentAuthenticatedUser();
      setIsAuth(true);
    } catch (error) {
      setIsAuth(false);
    } finally {
      setIsCheckingAuth(false);
    }
  };

  React.useEffect(() => {
    checkCurrentUser();
  }, []);

  return (
    <ReduxProvider store={store}>
      <AppIntlProvider>
        <>
          <Head />
          {!isCheckingAuth && isAuth && <ProtectedApp />}
          {!isCheckingAuth && !isAuth && (
            <AWSAuth signInCallback={() => setIsAuth(true)} />
          )}
          <ToastContainer />
        </>
      </AppIntlProvider>
    </ReduxProvider>
  );
};

export default App;
