import * as React from 'react';
import Layout from 'components/layout';
import Header from 'components/header';
import UserDetails from 'components/user-details';
import Content from 'components/content';
import { useSelector } from 'react-redux';
import { isArabicSelector, userSelector } from 'lib/redux';
import restClient from 'lib/rest-client';
import { endpoints, getCollectionExposureEnpoint } from 'config/api';
import { LoaderOverlay } from 'components/loader';
import { SubmitButton, DeleteButton } from 'components/button';
import Modal from 'components/modal';
import { List } from 'components/list';
import ErrorBox from 'components/error-box';
import icons from 'components/icons';
import { ITEMS_PER_PAGE } from 'config/const';
import { FormattedMessage, useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import InfluencerName from 'components/influencer-name';
import { getInfluencerName, getResponseErrorMessage } from 'utils/common';
import { hasDisabledCollections } from 'utils/user';
import { UserName } from 'components/user-name';
import Pagination from 'components/Pagination/Pagination';
import PageTitle from 'components/PageTitle';

const ActionButton = ({ onClick, icon, title }) => (
  <div
    className="flex flex-col items-center justify-between cursor-pointer ms-2 hover:text-higlight transition-colors duration-300"
    onClick={onClick}
  >
    <span className="w-6 h-6">{icon}</span>
    <span className="text-xs text-center mt-1">{title}</span>
  </div>
);

type Props = {
  influencer: User;
  onFeaturedProductsClick: (influencer: User) => void;
  onCollectionClick: (influencer: User) => void;
  onCollectionDisplayClick: (influencer: User) => void;
};

const InfluencerDetails = ({
  influencer,
  onFeaturedProductsClick,
  onCollectionClick,
  onCollectionDisplayClick,
  ...rest
}: Props) => {
  const intl = useIntl();
  const disabledCollections = hasDisabledCollections(influencer);
  const history = useHistory();

  return (
    <div className="flex justify-between items-center px-4 py-2" {...rest}>
      <div className="flex items-center">
        <img
          src={influencer.profilePictureUrl}
          alt="Influencer"
          className="h-12 w-12 me-3 rounded-full object-cover"
        />
        <UserName user={influencer} />
        <span className="text-secondary text-sm ms-2">
          <InfluencerName user={influencer} />
        </span>
      </div>
      <div className="flex flex-row">
        <ActionButton
          onClick={() =>
            history.push(`/moderator/influencer-collections/${influencer.id}`)
          }
          icon={icons.collections}
          title={intl.formatMessage({ id: 'influencers.viewCollections' })}
        />
        <ActionButton
          onClick={() =>
            history.push(`/moderator/influencer-fp/${influencer.id}`)
          }
          icon={icons.viewBoards}
          title={intl.formatMessage({ id: 'influencers.viewFP' })}
        />
        {/* <ActionButton
          onClick={() => onCollectionClick(influencer)}
          icon={icons.collection}
          title={
            <>
              {intl.formatMessage({ id: 'influencers.create' })}
              <br />
              {intl.formatMessage({ id: 'influencers.collection' })}
            </>
          }
        /> */}
        {/* <ActionButton
          onClick={() => onFeaturedProductsClick(influencer)}
          icon={icons.product}
          title={
            <>
              {intl.formatMessage({ id: 'influencers.create' })}
              <br />
              {intl.formatMessage({ id: 'influencers.featuredProducts' })}
            </>
          }
        /> */}
        <ActionButton
          onClick={() => onCollectionDisplayClick(influencer)}
          icon={disabledCollections ? icons.show : icons.hide}
          title={
            disabledCollections
              ? intl.formatMessage({ id: 'influencers.enableCollections' })
              : intl.formatMessage({ id: 'influencers.disableCollections' })
          }
        />
      </div>
    </div>
  );
};

const PaginationButton = (props: { dir: 'next' | 'prev'; onClick: any }) => {
  return (
    <span
      onClick={props.onClick}
      className="inline-block w-10 h-10 cursor-pointer rounded-sm hover:text-higlight transition-colors"
    >
      {icons[props.dir === 'next' ? 'chevronRight' : 'chevronLeft']}
    </span>
  );
};

const InfulencersPage = () => {
  const user = useSelector(userSelector);
  const intl = useIntl();
  const history = useHistory();
  const isArabic: boolean = useSelector(isArabicSelector);
  const [influencers, setInfluencers] = React.useState<Array<User>>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState(0);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [selectedInfluencer, setSelectedInfluencer] =
    React.useState<User | null>(null);
  const isEmpty = !(influencers && influencers.length);

  const fetchInfluencers = async () => {
    try {
      setIsLoading(true);
      
      const path = `${endpoints.users}?limit=${ITEMS_PER_PAGE}&page=${currentPage - 1}`;
      const {items, totalPages} = await restClient.get(path);
      setInfluencers(items);
      setTotalPages(totalPages);
      setHasNextPage(!(totalPages === currentPage));
    } catch (error:any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const createOnEntryClick =
    (type: 'collection' | 'feturedProduct') => (influencer: User) => {
      const isFeature = type === 'feturedProduct';
      const inflName = getInfluencerName(influencer, isArabic);
      const params = `?inflName=${inflName}&inflId=${influencer.id}`;
      let path = isFeature ? '/featured-products' : '/create-collection';
      path += params;
      history.push(path);
    };

  const toggleCollectionDisplay = async () => {
    try {
      setIsLoading(true);
      setError(null);
      const newValue = hasDisabledCollections(selectedInfluencer!);
      await restClient.post(
        getCollectionExposureEnpoint(selectedInfluencer!.id),
        {
          body: { displayCollectionsEnabled: newValue },
        }
      );
      await fetchInfluencers();
    } catch (error) {
      setError(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
      setSelectedInfluencer(null);
    }
  };

  React.useEffect(() => {
    fetchInfluencers();
  }, [currentPage]);

  return (
    <Layout>
      <Header>
        <PageTitle title="Influencer List"/>
      </Header>
      <Content>
        {isLoading && <LoaderOverlay />}
        {error && <ErrorBox>{error}</ErrorBox>}
        {selectedInfluencer && (
          <Modal onExit={() => setSelectedInfluencer(null)} withExitButton>
            <div className="flex flex-col">
              <p className="mt-2 mb-4 text-lg">
                <FormattedMessage
                  id={
                    hasDisabledCollections(selectedInfluencer)
                      ? 'influencers.confirmEnableCollectionsMessage'
                      : 'influencers.confirmDisableCollectionsMessage'
                  }
                  values={{
                    name: (
                      <strong>
                        <InfluencerName user={selectedInfluencer} />
                      </strong>
                    ),
                  }}
                />
              </p>
              <div className="flex justify-end">
                <SubmitButton onClick={toggleCollectionDisplay}>
                  <FormattedMessage id="common.confirm" />
                </SubmitButton>
                <DeleteButton
                  onClick={() => setSelectedInfluencer(null)}
                  className="ms-4"
                >
                  <FormattedMessage id="common.cancel" />
                </DeleteButton>
              </div>
            </div>
          </Modal>
        )}
        {!isLoading && !error && isEmpty && (
          <h1>{intl.formatMessage({ id: 'influencers.emptyList' })}</h1>
        )}
        {!isEmpty && (
          <List
            withBg
            items={influencers.map(influencer => (
              <InfluencerDetails
                key={influencer.id}
                influencer={influencer}
                onCollectionClick={createOnEntryClick('collection')}
                onFeaturedProductsClick={createOnEntryClick('feturedProduct')}
                onCollectionDisplayClick={setSelectedInfluencer}
              />
            ))}
          />
        )}
        {!!influencers.length && (
          <Pagination numberOfPages={totalPages} itemsPerPage={ITEMS_PER_PAGE} pageChange={setCurrentPage} disableNext={currentPage === totalPages} disablePrev={currentPage === 1}/>
        )}
      </Content>
    </Layout>
  );
};

export default InfulencersPage;
