import { AuthButton as Button } from 'components/button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import TextInput from 'components/inputs/text-input';
import { useValidation } from 'utils/validation';

type FormData = {
  name: string;
  street: string;
  postCode: string;
  city: string;
  country: string;
};

const emptyFormData: FormData = {
  name: '',
  street: '',
  postCode: '',
  city: '',
  country: '',
};

type Props = {
  initialData?: FormData;
  onFormSubmit: (data: { address: FormData }) => Promise<void>;
};

export default function AddressForm({
  initialData = emptyFormData,
  onFormSubmit,
}: Props) {
  const { requiredValidation } = useValidation();

  const schema: yup.SchemaOf<FormData> = yup.object().shape({
    name: requiredValidation,
    street: requiredValidation,
    city: requiredValidation,
    country: requiredValidation,
    postCode: requiredValidation,
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    control,
  } = useForm<FormData>({
    defaultValues: initialData,
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(data => onFormSubmit({ address: data }))}>
      <div className="flex flex-col items-start mt-4">
        <h3 className="mb-6 text-2xl font-semibold">Address</h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-x-10">
          <Controller
            name="name"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Name"
                  error={errors.name?.message}
                />
              );
            }}
          />
          <Controller
            name="street"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Street"
                  error={errors.street?.message}
                />
              );
            }}
          />
          <Controller
            name="postCode"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Post Code"
                  error={errors.postCode?.message}
                />
              );
            }}
          />
          <Controller
            name="city"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="City"
                  error={errors.city?.message}
                />
              );
            }}
          />
          <Controller
            name="country"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Country"
                  error={errors.country?.message}
                />
              );
            }}
          />
        </div>
        <div className="mt-8">
          <Button type="submit" disabled={!isDirty}>
            <FormattedMessage id="account.submitAddressForm" />
          </Button>
        </div>
      </div>
    </form>
  );
}
