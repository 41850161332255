import { USER_ROLES } from 'config/const';
import { TYPES } from './actions';

const initialState = null;

export type UserState = User | null;

const userReducer = (state = initialState, action): UserState => {
  switch (action.type) {
    case TYPES.SET_USER:
      return action.payload;
    default:
      return state;
  }
};

const selectors = {
  isModeratorSelector: (state: UserState): boolean =>
    !!state && state.role === USER_ROLES.MODERATOR,
  isInfluencerSelector: (state: UserState): boolean =>
    !!state && state.role === USER_ROLES.INFLUENCER,
};

export { userReducer as default, selectors };
