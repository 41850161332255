export const AWS_NEW_PASSWORD_CHALLANGE_NAME = 'NEW_PASSWORD_REQUIRED';

export const AWS_PASSWORD_RESET_REQUIRED_ERROR =
  'PasswordResetRequiredException';
export const AWS_PRODUCTS_INDEX_NAME = '6THSTREET_UAT_ENGLISH_PRODUCTS';

export const USER_ROLES = {
  INFLUENCER: 'INFLUENCER',
  MODERATOR: 'MODERATOR',
  SUPERAGENT: 'SUPER_ADMIN',
};

export const AVAILABLE_COUNTRIES = {
  UAE: 'ARE',
  SAUDI_ARABIA: 'SAU',
  KUWAIT: 'KWT',
  OMAN: 'OMN',
  BAHRAIN: 'BHR',
  QATAR: 'QAT',
};
export const COUNTRY_PICKER_ITEMS = {
  ARE: "UAE",
  SAU: "KSA",
  KWT: "KW",
  OMN: "OM",
  BHR: "BH",
  QAT: "QA",
}

export const ISO_CODE_BY_COUNTRY = {
  [AVAILABLE_COUNTRIES.QATAR]: 'QA',
  [AVAILABLE_COUNTRIES.UAE]: 'AE',
  [AVAILABLE_COUNTRIES.SAUDI_ARABIA]: 'SA',
  [AVAILABLE_COUNTRIES.KUWAIT]: 'KW',
  [AVAILABLE_COUNTRIES.OMAN]: 'OM',
  [AVAILABLE_COUNTRIES.BAHRAIN]: 'BH',
};

export const AVAILABLE_LANGUAGES = {
  ENGLISH: 'EN' as Language,
  ARABIC: 'AR' as Language,
};

export const COUNTRY_CODE_TO_CURRENCY_MAP = {
  [AVAILABLE_COUNTRIES.UAE]: 'AED',
  [AVAILABLE_COUNTRIES.QATAR]: 'QAR',
  [AVAILABLE_COUNTRIES.SAUDI_ARABIA]: 'SAR',
  [AVAILABLE_COUNTRIES.KUWAIT]: 'KWD',
  [AVAILABLE_COUNTRIES.BAHRAIN]: 'BHD',
  [AVAILABLE_COUNTRIES.OMAN]: 'OMR',
  
};

export const COLLECTION_STATE = {
  DRAFT: 'draft',
  LIVE: 'live',
  IN_REVIEW: 'in_review',
  PUBLISHING: 'publishing',
};

export const TARGET_AUDIENCE = {
  WOMEN: 'WOMEN' as TargetAudience,
  MEN: 'MEN' as TargetAudience,
  KIDS: 'KIDS' as TargetAudience,
};

export const FILE_UPLOAD_LIMIT = {
  IMAGE: 3 * 1024 * 1024,
  VIDEO: 200 * 1024 * 1024,
};

export const COVER_IMAGE_SIZE = {
  WIDTH: 500,
  HEIGHT: 700,
};

export const PROFILE_PIC_ASPECT_RATIO = {
  MIN: 1.76,
  MAX: 1.8,
};

export const ITEMS_PER_PAGE = 10;
export const DISPLAYED_ACCOUNTS_COUNT = 20; // So that on mobile it would be possible to store on single page.

export const {
  REACT_APP_CDN_HOST,
  HELP_CENTER_MAIL = 'influencer@6thstreet.com',
} = process.env;
