import * as React from 'react';
import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import UserDetails from 'components/user-details';
import { FormattedMessage } from 'react-intl';
import Heading from 'components/heading';

const NotFoundPage = () => {
  return (
    <Layout>
      <Header>
      </Header>
      <Content>
        <div
          data-testid="not-found-page"
          className="flex flex-col items-center"
        >
          <h1 className="text-higlight text-5xl">404</h1>
          <Heading>
            <FormattedMessage id="common.pageNotFound" />
          </Heading>
        </div>
      </Content>
    </Layout>
  );
};

export default NotFoundPage;
