import * as React from 'react';
import cn from 'classnames';

const ListItem = ({ children, className = null }: any) => (
  <li
    className={cn(
      'inline-block w-full px-2 py-4 border-b border-border text-black ',
      className
    )}
  >
    {children}
  </li>
);

interface Props {
  items: Array<React.ReactNode>;
  withBg?: boolean;
}

const List = ({ items, withBg }: Props) => {
  return (
    <ul>
      {items.map((item, key) => {
        let className;
        if (withBg) {
          className = key % 2 === 0 ? 'bg-lightGray' : 'bg-white';
          className += ' border-none';
        }
        return (
          <ListItem className={className} key={key}>
            {item}
          </ListItem>
        );
      })}
    </ul>
  );
};

export { List, ListItem };
