import * as React from 'react';
import cn from 'classnames';
import icons from 'components/icons';
import { useIntl } from 'react-intl';

type Props = any;

const AddCollectionButton = ({ large, ...rest }: Props) => {
  const intl = useIntl();
  return (
    <button
      className={cn(
        'max-w-200 p-2 md:p-10',
        'flex justify-center box-border items-center border rounded-md border-green border-dashed bg-green text-green bg-opacity-5',
        'outline-none focus:outline-none',
        { 'md:min-h-250': large }
      )}
      {...rest}
    >
      <span className="w-8 h-8 md:w-16 md:h-16">{icons.plus}</span>
      <span className="md:hidden">
        {intl.formatMessage({ id: 'collection.addNew' })}
      </span>
    </button>
  );
};

export const AddCollectionButtonCard = ({onClick, isActive}) => {
  return <div className="mb-7 me-2  overflow-hidden" onClick={ isActive ?  onClick : () => {}}>
    <div className={isActive ? "flex flex-col justify-center cursor-pointer items-center w-200 h-300 rounded-lg pb-6 pt-2 px-2 border shadow-md border-green border-dashed bg-green text-green bg-opacity-5" : "flex flex-col justify-center items-center w-200 h-300 rounded-lg pb-6 pt-2 px-2 border shadow-md border-gray border-dashed bg-gray text-gray bg-opacity-5"}>
    <span className="w-8 h-8 md:w-16 md:h-16">{icons.plus}</span>
    </div>
  </div>
}

export default AddCollectionButton;
