import * as React from 'react';
import { useSelector } from 'react-redux';
import { Auth } from 'aws-amplify';
import { isEmpty } from 'ramda';
import { userSelector } from 'lib/redux';
import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import UserDetails from 'components/user-details';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { ITEMS_PER_PAGE, USER_ROLES } from 'config/const';
import { List } from 'components/list';
import icons from 'components/icons';
import RadioButton from 'components/radio-button';
import { LoaderOverlay } from 'components/loader';
import { getResponseErrorMessage } from 'utils/common';
import Modal from 'components/modal';
import PaginationButtons from 'components/pagination-buttons';
import ActionButton from 'components/action-button';
import AssingModerator from './assign-moderator';
import { successToast, errorToast } from 'components/toast/toast';
import { AuthInput } from 'components/input';
import { UserName } from 'components/user-name';
import { useHistory } from 'react-router-dom';
import SuperStarForm from 'components/forms/super-star-form';
import Pagination from 'components/Pagination/Pagination';
import PageTitle from 'components/PageTitle';

type UserDetailsProps = {
  user: User;
  onTriggerResetPassword?: (user: User) => void;
  onAssignModerator?: (influencer: User) => void;
  onTryResendFirstPassword: (user: User) => void;
  onEditInfluencer: (user: User) => void;
  onToggleSuperStart: (user: User) => void;
};

const UserDetailsEntry = ({
  user,
  onTriggerResetPassword,
  onAssignModerator,
  onTryResendFirstPassword,
  onEditInfluencer,
  onToggleSuperStart,
}: UserDetailsProps) => {
  const isInfluencer = user.role === USER_ROLES.INFLUENCER;

  return (
    <div className="flex justify-between flex-col xl:flex-row items-center px-4 py-2">
      <div className="flex flex-col xl:flex-row items-center">
        <div className="flex items-center">
          {user.profilePictureUrl && <img
            src={user.profilePictureUrl}
            alt="Influencer"
            className="h-12 w-12 me-3 rounded-full object-cover"
          />}
          <UserName user={user} />
        </div>
        <span className="text-secondary text-sm ms-2 break-all">{user.email}</span>
      </div>
      <div className="flex flex-row justify-around sm:justify-end">
        {isInfluencer && onEditInfluencer && (
          <ActionButton
            onClick={() => onEditInfluencer(user)}
            icon={icons.pencil}
            title="Edit Influencer"
          />
        )}
        {isInfluencer && onToggleSuperStart && (
          <ActionButton
            onClick={() => onToggleSuperStart(user)}
            icon={icons.star}
            title="Mark as SuperStar"
          />
        )}
        {/* <ActionButton
          onClick={() => onTriggerResetPassword(user)}
          icon={icons.lockOpen}
          title="Trigger reset password"
        /> */}
        {isInfluencer && onAssignModerator && (
          <ActionButton
            onClick={() => onAssignModerator(user)}
            icon={icons.user}
            title="Assign moderator"
          />
        )}

        {onTryResendFirstPassword && (
          <ActionButton
            onClick={() => onTryResendFirstPassword(user)}
            icon={icons.key}
            title="Reset password"
          />
        )}
      </div>
    </div>
  );
};

const Users = () => {
  const user = useSelector(userSelector);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [totalPages, setTotalPages] = React.useState(0);
  const [users, setUsers] = React.useState<Array<User>>([]);
  const [selectedUserRole, setSelectedUserRole] = React.useState(
    USER_ROLES.INFLUENCER
  );
  const [isLoading, setIsLoading] = React.useState(false);
  const [selectedInfluencer, setSelectedInfluencer] =
    React.useState<User | null>(null);
  const [query, setQuery] = React.useState('');
  const history = useHistory();
  const [isAssignModeratorModalOpen, setIsAssignModeratorModalOpen] =
    React.useState(false);
  const [isSuperStarModalOpen, setIsSuperStarModalOpen] = React.useState(false);

  const fetchUsers = React.useCallback(async () => {
    try {
      if (query && query.length < 3) {
        return;
      }
      setIsLoading(true);
      const { items, totalPages } = await restClient.get(`${endpoints.adminUsers}`, {
        queryStringParameters: {
          limit: ITEMS_PER_PAGE,
          page: currentPage - 1,
          type: selectedUserRole,
          q: query,
        },
      });
      setUsers(items);
      setTotalPages(totalPages)
      setHasNextPage(!(totalPages === currentPage));
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, query, selectedUserRole]);

  const updatePickedUserSuperstarStatus = (pickedUserId, pickedCountries) => {
    const index = users.findIndex(item => item.id === pickedUserId);
    const usersArray = [...users];
    usersArray[index].superstarCountries = pickedCountries;
    setUsers(usersArray);
  }

  const handleTriggerResetPassword = async (user: User) => {
    try {
      setIsLoading(true);
      await Auth.forgotPassword(user.email);
      successToast(`Rest password for ${user.email} has been triggered`);
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleTryResendFirstPassword = async (user: User) => {
    try {
      setIsLoading(true);
      const reset = await restClient.post(`${endpoints.adminUsers}/${user.id}/reset-password`);
      successToast(`New temporary credentials has been sent to ${user.email}`);
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const handleChangeUserRole = newRole => {
    if (newRole === selectedUserRole) return;
    setSelectedUserRole(newRole);
    setUsers([]);
    setCurrentPage(1);
  };

  React.useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  return (
    <Layout>
      <Header>
        <PageTitle title="Users"/>
      </Header>
      <Content>
        {isLoading && <LoaderOverlay />}
        {selectedInfluencer && isAssignModeratorModalOpen && (
          <Modal
            onExit={() => {
              setIsAssignModeratorModalOpen(false);
              setSelectedInfluencer(null);
            }}
            withExitButton
          >
            <AssingModerator
              currentModerator={selectedInfluencer!.moderator!}
              influencer={selectedInfluencer}
              onSuccessCb={() => {
                setSelectedInfluencer(null);
              }}
            />
          </Modal>
        )}
        {selectedInfluencer && isSuperStarModalOpen && (
          <Modal
            onExit={() => {
              setSelectedInfluencer(null);
              setIsSuperStarModalOpen(false);
            }}
            withExitButton
          >
            <SuperStarForm
              initialData={selectedInfluencer as any}
              submitCallback={() => {
                setSelectedInfluencer(null);
                setIsSuperStarModalOpen(false);
              }}
              updateCurrUserState={(pickedUserId, pickedCountries) => updatePickedUserSuperstarStatus(pickedUserId, pickedCountries)}
            />
          </Modal>
        )}
        <div className="flex flex-col justify-start my-4 ms-2">
          <h1>Select user role:</h1>
          <RadioButton
            label="Influencer"
            onChange={() => handleChangeUserRole(USER_ROLES.INFLUENCER)}
            value={USER_ROLES.INFLUENCER}
            checked={selectedUserRole === USER_ROLES.INFLUENCER}
          />
          <RadioButton
            label="Moderator"
            onChange={() => handleChangeUserRole(USER_ROLES.MODERATOR)}
            value={USER_ROLES.MODERATOR}
            checked={selectedUserRole === USER_ROLES.MODERATOR}
          />
        </div>
        <div className="max-w-sm ms-2 mb-8">
          <AuthInput
            label="Search by user name, email, influencer name"
            value={query}
            onChange={evt => setQuery(evt.target.value)}
          />
        </div>
        <div>
          <List
            withBg
            items={users.map(user => (
              <UserDetailsEntry
                key={user.id}
                user={user}
                onAssignModerator={() => {
                  setIsAssignModeratorModalOpen(true);
                  setSelectedInfluencer(user);
                }}
                onTryResendFirstPassword={handleTryResendFirstPassword}
                onEditInfluencer={user => history.push(`/users/${user.id}`)}
                onToggleSuperStart={user => {
                  setSelectedInfluencer(user);
                  setIsSuperStarModalOpen(true);
                }}
              />
            ))}
          />
          {!isEmpty(users) && (
            <Pagination 
              pageChange={setCurrentPage} 
              numberOfPages={totalPages} 
              itemsPerPage={ITEMS_PER_PAGE} 
              disablePrev={currentPage === 1} 
              disableNext={currentPage === totalPages}
            />
          )}
          {isEmpty(users) && !isLoading && (
            <h1 className="ms-2 mt-4">No users</h1>
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default Users;
