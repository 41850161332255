import * as React from 'react';
import icons from 'components/icons';
import { USER_ROLES } from 'config/const';
import { CollectionsPage, SettingsPage, LogoutPage } from 'pages';
import { FormattedMessage } from 'react-intl';
import CreateCollectionPage from 'pages/create-collection';
import EditCollectionPage from 'pages/collection-edit';
import FeaturedProductsPage from 'pages/featured-products';
import FeaturedProductsPagev1 from 'pages/featured-products-list';
import DashboardPage from 'pages/dashboard';
import HelpCenterPage from 'pages/help-center';
import InfluencersPage from 'pages/influencers';
import CollectionsModPage from 'pages/moderator/collections';
import FeaturedProductsModPage from 'pages/moderator/featured-products-list';
import UsersPage from 'pages/superadmin/users';
import CreateUserPage from 'pages/superadmin/create-user';
import SynchronizationPage from 'pages/superadmin/synchronization';
import InfluencersOrderPage from 'pages/superadmin/influencers-order';
import AccountPage from 'pages/account';
import EditInfluencerPage from 'pages/superadmin/edit-influencer';
import CollectionsSupPage from 'pages/superadmin/collections';
import SliderControl from 'pages/superadmin/CollectionsOrder/CollectionsOrder';
import OnboardInfluencer from 'pages/superadmin/OnboardInfluencer/OnboardInfluencer'
import InfluencerDashboard from "pages/superadmin/InfluencerDashboard/InfluencerDashboard"
import EditFP from 'pages/editFP/editFP';

export interface MenuItem {
  path: string | Array<string>;
  name?: React.ReactNode;
  icon?: React.ReactElement;
  component: React.ComponentType;
  noMenu?: boolean;
  exact?: boolean;
  alignBottom?: boolean;
}

const commonRoutes: { [name: string]: MenuItem } = {
  dashboard: {
    path: ['/', '/dashboard'],
    exact: true,
    name: <FormattedMessage id="menu.dashboard" />,
    icon: icons.dashboard,
    component: DashboardPage,
  },
  collections: {
    path: '/collections',
    name: <FormattedMessage id="menu.collections" />,
    exact: true,
    icon: icons.collection,
    component: CollectionsPage,
  },
  createCollection: {
    path: '/create-collection',
    component: CreateCollectionPage,
    noMenu: true,
  },
  editCollection: {
    path: '/collections/edit/:collectionId',
    component: EditCollectionPage,
    noMenu: true,
  },
  featuredProductsEdit: {
    path: '/featured-edit',
    component: FeaturedProductsPage,
    noMenu: true,
  },
  featuredProducts: {
    path: '/featured-products',
    name: <FormattedMessage id="menu.featuredProducts" />,
    exact: true,
    icon: icons.product,
    component: FeaturedProductsPagev1,
  },
  account: {
    path: '/account',
    name: <FormattedMessage id="menu.account" />,
    icon: icons.user,
    component: AccountPage,
  },
  settings: {
    path: '/settings',
    name: <FormattedMessage id="menu.settings" />,
    icon: icons.cog,
    component: SettingsPage,
  },
  logout: {
    path: '/logout',
    name: <FormattedMessage id="menu.logout" />,
    icon: icons.logout,
    component: LogoutPage,
  },
};

const superuserMenu: Array<MenuItem> = [
  {
    path: ['/','/admin/influencer-dashboard'],
    exact: true,
    name: <FormattedMessage id="menu.influencerDashboard" />,
    icon: icons.influencerdashboard,
    component: InfluencerDashboard
  },{
    path: '/create-user',
    exact: true,
    name: <FormattedMessage id="menu.createUser" />,
    icon: icons.addUser,
    component: CreateUserPage,
  },
  {
    path: '/users',
    name: 'Users',
    exact: true,
    icon: icons.users,
    component: UsersPage,
  },
  { path: '/users/:influencerId', noMenu: true, component: EditInfluencerPage },
  {
    path: ['/synchronization'],
    exact: true,
    name: <FormattedMessage id="menu.synchronization" />,
    icon: icons.cog,
    component: SynchronizationPage,
  },
  {
    path: '/influencers-order',
    exact: true,
    name: <FormattedMessage id="menu.influencersOrder" />,
    icon: icons.selector,
    component: InfluencersOrderPage,
  },
  {
    path: '/admin/slider-order',
    exact: true,
    name: 'Collections Order',
    icon: icons.product,
    component: SliderControl
  },
  {
    path: '/admin/onboard-influencer',
    exact: true,
    name: 'Onboard Influencer',
    icon: icons.product,
    component: OnboardInfluencer
  },
  {
    path: '/admin/influencer-collections/:influencerId',
    noMenu: true,
    component: CollectionsSupPage
  },
  commonRoutes.logout,
];

const moderatorMenu: Array<MenuItem> = [
  commonRoutes.dashboard,
  {
    ...commonRoutes.collections,
    name: <FormattedMessage id="menu.pendingApprovals" />,
  },
  {
    icon: icons.users,
    name: <FormattedMessage id="menu.influencers" />,
    path: '/influencers',
    component: InfluencersPage,
  },
  {
    path: '/moderator/influencer-collections/:influencerId',
    component: CollectionsModPage,
    noMenu: true,
  },
  {
    path: '/moderator/influencer-fp/:influencerId',
    component: FeaturedProductsModPage,
    noMenu: true,
  },
  commonRoutes.createCollection,
  commonRoutes.editCollection,
  commonRoutes.featuredProductsEdit,
  {
    ...commonRoutes.featuredProducts,
    noMenu: true,
  },
  commonRoutes.settings,
  commonRoutes.logout,
];

const influencerMenu: Array<MenuItem> = [
  commonRoutes.dashboard,
  commonRoutes.collections,
  commonRoutes.createCollection,
  commonRoutes.editCollection,
  commonRoutes.featuredProducts,
  commonRoutes.account,
  commonRoutes.settings,
  commonRoutes.logout,
  commonRoutes.featuredProductsEdit,
  {
    path: '/help-center',
    name: <FormattedMessage id="menu.helpCenter" />,
    icon: icons.info,
    component: HelpCenterPage,
    alignBottom: true,
  },
];

const getMenuItemByRole = (userRole?: string) => {
  switch (userRole) {
    case USER_ROLES.INFLUENCER:
      return influencerMenu;
    case USER_ROLES.MODERATOR:
      return moderatorMenu;
    case USER_ROLES.SUPERAGENT:
      return superuserMenu;
    default:
      return influencerMenu;
  }
};

export { getMenuItemByRole };
