import {actiontypes} from './actions';

type initialState = {
    pickedCountry: Country
}
const initialState: initialState = {
    pickedCountry: 'ARE'
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case actiontypes.CHANGE_COUNTRY:
            return { ...state, pickedCountry: action.payload };
        default:
            return state;
    }
}

export default reducer;