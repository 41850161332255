import * as React from 'react';
import cn from 'classnames';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { collectionValidationSelector } from 'lib/redux/index'
import { validateCollectionInput } from 'lib/redux/formValidation/actions';

type Props = React.InputHTMLAttributes<HTMLTextAreaElement> & {
  label?: string;
  containerClassName?: string;
  sideElement?: React.ReactElement;
  fullWidth?: boolean;
  name?: string;
};

const Textarea = ({
  containerClassName,
  className,
  sideElement,
  fullWidth,
  label,
  value,
  name,
  ...rest
}: Props) => {

  const validationState = useSelector(collectionValidationSelector);
  const dispatch = useDispatch();
  const onBlur = () => {
    if(!!value && name) dispatch(validateCollectionInput('', name));
  }

  return (
    <div className={cn('flex flex-col my-4', containerClassName)}>
      {label && (
        <label className="text-secondary" htmlFor={rest.id}>
          {label}
        </label>
      )}
      <div className="flex flex-col">
        <textarea
          onBlur={onBlur}
          className={cn(
            'px-4 py-2 md:py-4 inline-block border rounded-md outline-none focus:border-black w-full md:max-w-md md:min-w-300',
            { 
              'w-full': fullWidth,
              'border-border': name && !validationState[name],
              'border-red-500': name && !!validationState[name],
            },
            className
          )}
          maxLength={200}
          {...rest}
        />
        {sideElement && sideElement}
        {name && !!validationState[name] && <p className="p-2 text-red-500">{validationState[name]}</p>}
      </div>
    </div>
  );
};

export default Textarea;
