import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { fetchProductsBySkus } from 'utils/product';
import { pick } from 'ramda';

type FPDetails = {
  details: FeaturedProductsCollection | null | undefined;
  products: Array<ProductResponse>;
  items: any;
};

type FetchArg = {
  country: any;
  audience: TargetAudience;
  isModerator: boolean;
  editId?: string;
  createInfluencerId?: string;
};

export const fetchFeaturedProductsWithDetails = async ({
  country,
  audience,
  isModerator,
  editId,
  createInfluencerId,
}: FetchArg): Promise<FPDetails> => {
  try {
    let details: FeaturedProductsCollection | null | undefined = null;
    let products: Array<ProductResponse> = [];
    let items: any = [];
    const featuredProductsList: Array<FeaturedProductsCollection> =
      await restClient.get(endpoints.featuredProducts, {
        queryStringParameters: {
        },
      });
      const pickedFP = featuredProductsList.find(fp => fp.id === editId);
      items = pickedFP?.items;
      details = pickedFP;
    if (isModerator && createInfluencerId) {
      const alreadyHasFP = featuredProductsList.find(fp => {
        return (
          fp.audience.includes(audience) &&
          fp.country === country &&
          fp.owner.id === createInfluencerId
        );
      });
      // if (alreadyHasFP)
      //   throw new Error(
      //     'Featrued for this country and audience has been already created'
      //   );
    }
    if (isModerator) {
      details = featuredProductsList.find(fp => fp.id === editId);
      items = details!?.items;
    }
    if (details) {
      products = await fetchProductsBySkus(
        details.items.map(item => item.sku),
        details.country
      );
    }
    return { details, products, items };
  } catch (error) {
    throw error;
  }
};

export const getInflDetailsFromParams = (params: URLSearchParams): any => {
  const fallbackValue = {};
  try {
    const influencerName = params.get('inflName');
    const influencerId = params.get('inflId');
    return { influencerName, influencerId };
  } catch (error) {
    return fallbackValue;
  }
};
