import { AuthButton as Button } from 'components/button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import TextInput from 'components/inputs/text-input';
import { useValidation } from 'utils/validation';

type FormData = {
  accountName: string;
  bankName: string;
  iban: string;
  swift: string;
};

const emptyFormData: FormData = {
  accountName: '',
  bankName: '',
  iban: '',
  swift: '',
};

type Props = {
  initialData?: FormData;
  onFormSubmit: (data: { bankAccount: FormData }) => Promise<void>;
  readOnly?: boolean;
};

export default function FinanceForm({
  initialData = emptyFormData,
  onFormSubmit,
  readOnly,
}: Props) {
  const { requiredValidation } = useValidation();

  const schema: yup.SchemaOf<FormData> = yup.object().shape({
    accountName: requiredValidation,
    bankName: requiredValidation,
    iban: requiredValidation,
    swift: requiredValidation,
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    control,
  } = useForm<FormData>({
    defaultValues: initialData,
    resolver: yupResolver(schema),
  });

  return (
    <form onSubmit={handleSubmit(data => onFormSubmit({ bankAccount: data }))}>
      <div className="flex flex-col items-start mt-4">
        <h3 className="mb-6 text-2xl font-semibold">Bank Account</h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-x-10">
          <Controller
            name="accountName"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Account Name"
                  error={errors.accountName?.message}
                  readOnly={readOnly}
                />
              );
            }}
          />
          <Controller
            name="bankName"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Bank Name"
                  error={errors.bankName?.message}
                  readOnly={readOnly}
                />
              );
            }}
          />
          <Controller
            name="iban"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="IBAN"
                  error={errors.iban?.message}
                  readOnly={readOnly}
                />
              );
            }}
          />
          <Controller
            name="swift"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="SWIFT"
                  error={errors.swift?.message}
                  readOnly={readOnly}
                />
              );
            }}
          />
        </div>
        {!readOnly && (
          <div className="mt-8">
            <Button type="submit" disabled={!isDirty}>
              <FormattedMessage id="account.submitBankAccountForm" />
            </Button>
          </div>
        )}
      </div>
    </form>
  );
}
