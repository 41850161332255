import * as React from 'react';
import cn from 'classnames';

type Props = React.HTMLProps<HTMLHeadingElement>;

export default function Heading({ children, className }: Props) {
  return (
    <h2 className={cn('text-black text-lg md:text-3xl font-bold', className)}>
      {children}
    </h2>
  );
}
