import { AuthButton as Button } from 'components/button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import ImageInput from 'components/inputs/image-input';
import { FormattedMessage } from 'react-intl';
import TextInput from 'components/inputs/text-input';
import { useValidation } from 'utils/validation';

type FormData = {
  nameEnglish: string;
  nameArabic: string;
  familyNameEnglish: string;
  familyNameArabic: string;
  influencerNameEnglish: string;
  influencerNameArabic: string;
  profilePicture?: any;
};

const emptyFormData: FormData = {
  nameEnglish: '',
  nameArabic: '',
  familyNameEnglish: '',
  familyNameArabic: '',
  influencerNameEnglish: '',
  influencerNameArabic: '',
  profilePicture: null,
};

type Props = {
  initialData?: FormData;
  profilePictureUrl: string | undefined;
  onFormSubmit: (data: FormData) => Promise<void>;
};

export default function PersonalDetailsForm({
  initialData = emptyFormData,
  profilePictureUrl,
  onFormSubmit,
}: Props) {
  const { requiredValidation } = useValidation();

  const influencerSchema: yup.SchemaOf<FormData> = yup.object({
    nameEnglish: requiredValidation,
    nameArabic: requiredValidation,
    familyNameEnglish: requiredValidation,
    familyNameArabic: requiredValidation,
    influencerNameEnglish: requiredValidation,
    influencerNameArabic: requiredValidation,
    profilePicture: yup.mixed().optional(),
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    control,
  } = useForm<FormData>({
    defaultValues: initialData,
    resolver: yupResolver(influencerSchema),
  });

  return (
    <form onSubmit={handleSubmit(onFormSubmit)}>
      <div className="flex flex-col items-start mt-4">
        <h3 className="mb-6 text-2xl font-semibold">Influencer</h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-x-10">
          <Controller
            name="nameEnglish"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Name - English"
                  error={errors.nameEnglish?.message}
                />
              );
            }}
          />
          <Controller
            name="nameArabic"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Name - Arabic"
                  error={errors.nameArabic?.message}
                />
              );
            }}
          />
          <Controller
            name="familyNameEnglish"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Family Name - English"
                  error={errors.familyNameEnglish?.message}
                />
              );
            }}
          />
          <Controller
            name="familyNameArabic"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Family Name - Arabic"
                  error={errors.familyNameArabic?.message}
                />
              );
            }}
          />
          <Controller
            name="influencerNameEnglish"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Influencer Name - English"
                  error={errors.influencerNameEnglish?.message}
                />
              );
            }}
          />
          <Controller
            name="influencerNameArabic"
            control={control}
            render={field => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Influencer Name - Arabic"
                  error={errors.influencerNameArabic?.message}
                />
              );
            }}
          />
        </div>
        <div className="mt-4">
          <Controller
            name="profilePicture"
            control={control}
            render={field => (
              <ImageInput
                onChange={field.onChange}
                initialImageSrc={profilePictureUrl}
              />
            )}
          />
        </div>
        <div className="mt-8">
          <Button type="submit" disabled={!isDirty}>
            <FormattedMessage id="account.submitInfluencerForm" />
          </Button>
        </div>
      </div>
    </form>
  );
}
