import * as React from 'react';
import { isArabicSelector } from 'lib/redux';
import { useSelector } from 'react-redux';
import { getInfluencerName } from 'utils/common';

type Props = {
  user: User;
  withDetails?: boolean;
};

const InfluencerName = ({ user }: Props) => {
  const isArabic: boolean = useSelector(isArabicSelector);
  if (!user) return null;
  return <>{getInfluencerName(user, isArabic)}</>;
};

export default InfluencerName;
