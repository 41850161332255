import { isSuper } from '@babel/types';
import {Dispatch} from 'redux';

export const types ={
    QUERY: 'QUERY',
    IS_PINNED: 'IS_PINNED',
    IS_SUPERSTAR: 'IS_SUPERSTAR',
    SET_COUNTRY: 'SET_COUNTRY',
    SET_AUDIENCE: 'SET_AUDIENCE'
}

export const setQuery = (query: string) => (dispatch : Dispatch) => {
    dispatch({type: types.QUERY, payload: query});
}

export const setIsPinned = (isPinned : string) => (dispatch : Dispatch) => {
    dispatch({type: types.IS_PINNED, payload: isPinned});
}

export const setIsSuperstar = (isSuperstar : string) => (dispatch : Dispatch) => {
    dispatch({type: types.IS_SUPERSTAR, payload: isSuperstar});
}

export const setPickedCountry = (pickedCountry: string) => (dispatch: Dispatch) => {
    dispatch({type: types.SET_COUNTRY, payload: pickedCountry});
}

export const setAudience = (audience: string) => (dispatch: Dispatch) => {
    dispatch({type: types.SET_AUDIENCE, payload: audience});
}

