import * as React from 'react';
import icons from 'components/icons';

const useEnlargeMedia = (
  isImage: boolean,
  previewUrl: string,
  onVideoError: () => void
) => {
  const background = React.useRef(null);
  const [isEnlarged, setIsEnlarged] = React.useState(false);
  const enlargePreview = () => {
    setIsEnlarged(true);
  };
  const onEnlargeClose = () => {
    background.current = null;
    setIsEnlarged(false);
  };

  const renderEnlargedPreview = () => {
    const closeIfBackground = (event) => {
      event.stopPropagation();
      if (event.target === background.current) {
        onEnlargeClose();
      }
    };

    return (
      <div ref={background} onClick={closeIfBackground} className="fixed z-10 top-0 left-0 w-full h-full bg-black bg-opacity-50 flex justify-center">
        {isImage && (
          <img className="self-center max-h-screen" src={previewUrl} />
        )}
        {!isImage && (
          <video
            className="self-center max-h-screen"
            preload="metadata"
            controls
            autoPlay
            onError={onVideoError}
          >
            <source src={previewUrl} />
          </video>
        )}
        <div
          className="absolute left-0 top-0 w-16 h-16 bg-green text-white cursor-pointer rounded-md ml-4 mt-4"
          onClick={onEnlargeClose}
        >
          {icons.cross}
        </div>
      </div>
    );
  };

  return { enlargePreview, onEnlargeClose, isEnlarged, renderEnlargedPreview };
};

export default useEnlargeMedia;
