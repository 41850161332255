import cn from 'classnames';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

import GripIcon from 'components/UI/PopularityIcons/GripIcon';
import { divide } from 'ramda';

type InfluencerEntry = {
    id: string;
    email: string;
    nameArabic: string;
    nameEnglish: string;
    familyNameArabic: string;
    familyNameEnglish: string;
    profilePictureUrl: string;
    superstar: boolean;
    numberOfTrendingCollections: number;
    position: number;
    numberOfLiveCollections: number;
  };

const ListItem = ({ children, className = null }: any) => (
  <li
    className={cn(
      'inline-block w-full px-2 py-4 border-b border-border text-black ',
      className
    )}>
    {children}
  </li>
);

interface Props {
  items: any;
  withBg?: boolean;
  updateInfluencers: (number, string) => void;
  pageNumber: number;
  itemsPerPage: number;
  influencers: InfluencerEntry[];
  setSelectedInfluencer: (influencer: InfluencerEntry) => void;
}

const List = ({ items, withBg, updateInfluencers, pageNumber, itemsPerPage, influencers, setSelectedInfluencer }: Props) => {
    const handleOnDragEnd = (result) => {
        if(!result.destination){
            return;
        }
        const influencerId = influencers[result.source.index].id;
        updateInfluencers(items[result.destination.index].props.influencer.position, influencerId);
    }
    const handleOnDragStart = (result) => {
        setSelectedInfluencer(influencers[result.draggableId]);
    }
  return (
      <DragDropContext onDragEnd={handleOnDragEnd} onDragStart={handleOnDragStart}>
          <Droppable droppableId={"InfluencersOrder"}>
              {(provided) => (
                  <ul {...provided.droppableProps} ref={provided.innerRef}>
                  {items.map((item, key) => {
                      let className;
                      if (withBg) {
                      className = key % 2 === 0 ? 'bg-lightGray' : 'bg-white';
                      className += ' border-none';
                      }
                      return (
                        <Draggable key={key} draggableId={key.toString()} index={key}>
                            {(provided) => (
                            <li 
                                className={cn('flex inline-block w-full px-2 py-4 border-b border-border text-black ',className)} 
                                key={key} 
                                {...provided.draggableProps} 
                                ref={provided.innerRef} 
                            >
                                {item}
                                <div {...provided.dragHandleProps} className='hidden sm:flex ml-6 flex justify-center items-center'><GripIcon /></div>
                            </li>
                            )}
                        </Draggable>
                      );
                  })}
                  {provided.placeholder}
                  </ul>
              )}
        </Droppable>
    </DragDropContext>
  );
};

export { List, ListItem };
