import * as React from 'react';
import { Auth } from 'aws-amplify';
import { LoaderOverlay } from 'components/loader';

const Logout = () => {
  React.useEffect(() => {
    logOut();
  }, []);

  const logOut = async () => {
    try {
      await Auth.signOut();
    } catch (error) {
    } finally {
      window.location.assign('/');
    }
  };

  return <LoaderOverlay />;
};

export default Logout;
