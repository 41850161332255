import * as React from 'react';
import cn from 'classnames';
import { propOr } from 'ramda';
import Badge from 'components/badge';
import { COLLECTION_STATE } from 'config/const';
import { FormattedMessage, useIntl } from 'react-intl';
import fallbackCollectionImage from 'assets/img/collection-fallback-thumbnail.jpg';
import PinCollection from './PinCollection';
import are from 'assets/img/flags/are.svg';
import bhr from 'assets/img/flags/bhr.svg';
import en from 'assets/img/flags/en.svg';
import kwt from 'assets/img/flags/kwt.svg';
import omn from 'assets/img/flags/omn.svg';
import qat from 'assets/img/flags/qat.svg';
import sau from 'assets/img/flags/sau.svg';
import CollectionStatusWarn from 'components/UI/collectionStatus/collectionStatusWarn';


const countryCodeToFlag = {
  are,
  bhr,
  en,
  kwt,
  omn,
  qat,
  sau,
};

type Props = {
  onClick: (
    collection: CollectionResponse | FeaturedProductsCollection
  ) => void;
  collection: CollectionResponse | FeaturedProductsCollection;
  isFP?: boolean;
  canDuplicate?: boolean;
  duplicationCallback?: Function;
  handlePinCollection: (itemId: string, isPinned: boolean | undefined) => void;
};

const CollectionCard = ({
  onClick,
  collection,
  isFP,
  handlePinCollection
}: Props) => {
  const intl = useIntl();
  const thumbnailUrl = propOr(
    fallbackCollectionImage,
    'thumbnailUrl',
    collection
  );
  const title = propOr('', 'titleEnglish', collection);
  const productsLabel = intl
    .formatMessage({ id: 'collection.tabs.products' })
    .toLowerCase();
  const stateToBadge = {
    [COLLECTION_STATE.DRAFT]: intl.formatMessage({
      id: 'collection.badge.draft',
    }),
    [COLLECTION_STATE.IN_REVIEW]: intl.formatMessage({
      id: 'collection.badge.inReview',
    }),
    [COLLECTION_STATE.PUBLISHING]: intl.formatMessage({
      id: 'collection.badge.publishing',
    }),
    [COLLECTION_STATE.LIVE]: intl.formatMessage({
      id: 'collection.badge.live',
    }),
  };
  return (
    <div className="mb-7 me-2  overflow-hidden">
      <div
        onClick={() => onClick(collection)}
        className={cn(
          'flex flex-col justify-between w-200 h-300 rounded-lg pb-6 pt-2 px-2 cursor-pointer bg-cover shadow-md'
        )}
        style={{
          backgroundImage: `url("${thumbnailUrl}")`,
        }}
      >
        <div className="flex-col">
            <div className="flex justify-between items-start mb-2">
                <Badge color="white">
                    {collection.items.length}{' '}
                    <span className="hidden md:inline">{productsLabel}</span>
                </Badge>
                <Badge
                    color={
                    collection.state === COLLECTION_STATE.LIVE ? 'green' : 'orange'
                    }
                >
                    {stateToBadge[collection.state]}
                    {collection.hasErrors && <CollectionStatusWarn/>}
                    {collection.wasRejected && (
                    <div>
                        {intl.formatMessage({ id: 'collection.badge.rejected' })}
                    </div>
                    )}
                </Badge>
            </div>
            <div className="flex justify-between items-end flex-col">
                {collection.trending && <div className="bg-yellow-300 px-2 rounded mb-2 text-sm translate-x-3 transform font-bold">PINNED</div>}
                <div className="flex bg-white py-1 px-2 justify-center items-center rounded translate-x-3 transform text-sm font-bold">
                    <img className="w-5 h-5 mr-1" src={countryCodeToFlag[collection.country.toLowerCase()]} alt="" />
                    {collection.country}
                </div>
        </div>
        </div>
        <div className="font-bold text-white flex flex-col items-center">
          {isFP && (
            <>
              <CardTitle>{collection.owner.influencerNameEnglish}</CardTitle>
              <CardTitle>
                <FormattedMessage
                  id={`common.countries.${collection.country.toLocaleLowerCase()}`}
                />
              </CardTitle>
              <CardTitle>
                {collection.audience.map(a => (
                  <span key={a}>
                    <FormattedMessage
                      id={`collection.audience.${a.toLowerCase()}`}
                    />
                  </span>
                ))}
              </CardTitle>
            </>
          )}
          {!isFP && <CardTitle>{title}</CardTitle>}
        </div>
      </div>
      {collection.state === COLLECTION_STATE.LIVE && <PinCollection isPinned={collection.trending} handlePin={() => handlePinCollection(collection.id, collection.trending)}/>}
    </div>
  );
};

const CardTitle = ({ children }: any) => (
  <span style={{ textShadow: '#000 0 0 3px' }}>{children}</span>
);

export default CollectionCard;
