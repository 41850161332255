import * as React from 'react';
import Input from 'components/input';
import { AuthButton } from 'components/button';
import cn from 'classnames';
import { useIntl } from 'react-intl';
import { endpoints } from 'config/api';
import { SelectedProductsContainer } from 'components/product';
import Loader from 'components/loader';
import { getFormattedPriceFromProduct, getProductsPath } from 'utils/product';
import { throttle } from 'throttle-debounce';
import icons from 'components/icons';
import restClient from 'lib/rest-client';
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useEffect } from 'react';


const PAGE_ITEMS_LIMIT = 10;

type Props = {
  country: any;
  selectedProducts: Array<ProductResponse>;
  onProductSelect: (product: ProductResponse) => void;
  onProductRemove: (product: ProductResponse) => void;
  onProductsOrderChage: (products: Array<ProductResponse>) => void;
  readOnly?: boolean;
  productsError?: string;
  itemsErrors: any;
};

const ProductsSerch = ({
  country,
  onProductSelect,
  onProductRemove,
  selectedProducts,
  onProductsOrderChage,
  readOnly,
  productsError,
  itemsErrors
}: Props) => {
  const lang = useSelector((state: any) => state.lang);
  const [error, setError] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [currPage, setCurrPage] = React.useState(0);
  const [dataLength, setDataLength] = React.useState(0);
  const [totalPages, setTotalPages] = React.useState(0);
  const [searchValue, setSearchValue] = React.useState('');
  const [results, setResults] = React.useState<ProductResponse[] | []>([]);

  const onSearch = async (value: string) => {
    try {
      if (value.length < 3) {
        return;
      }
      setIsLoading(true);
      setError(null);
      const path = getProductsPath(
        country,
        lang,
        endpoints.productsSubpaths.query
      );
      const { items: products, totalPages, totalItems } = await restClient.get(path, {
        queryStringParameters: {
          queryString: value,
          page: currPage,
          limit: PAGE_ITEMS_LIMIT
        },
      });
      setDataLength(totalItems);
      setTotalPages(totalPages);
      setIsLoading(false);
      setResults(prev => [...prev, ...products]);
    } catch (error: any) {
      setIsLoading(false);
      setError(error.message);
    }
  };
  const onSearchChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setResults([]);
    const { value } = evt.target;
    setSearchValue(value);
    debouncedSearch(value);
  };

  const debouncedSearch = React.useCallback(throttle(1000, onSearch), [
    country,
  ]);

  const clearSearchBox = () => {
    setSearchValue('');
    setResults([]);
  };

  useEffect(() => {
    onSearch(searchValue);
  },[currPage]);

  const hasResults = !!(results && results.length);

  return (
    <div>
      <div className="relative w-full max-w-screen-sm">
        {results && !isLoading && <RemoveIcon onClick={clearSearchBox} />}
        <SearchInput
          searchValue={searchValue}
          onSearchChange={onSearchChange}
          hasResults={hasResults}
          readOnly={readOnly}
        />
        {results.length > 0 && (
          <ResultsContainer  totalItems={dataLength} currPage={currPage} handleFetch={() => setCurrPage((prev) => prev + 1)}>
            {results.map((product, index) => (
                <ProductResultCard
                  product={product}
                  onProductSelect={onProductSelect}
                  key={index}
                />
              ))}
            {isLoading && (
              <div className="flex justify-center items-center p-4">
                <Loader />
              </div>
            )}
          </ResultsContainer>
        )}
      </div>
      {!!selectedProducts.length && (
        <SelectedProductsContainer
          itemsErrors={itemsErrors}
          products={selectedProducts}
          readOnly={readOnly}
          onProductRemove={onProductRemove}
          onOrderChange={onProductsOrderChage}
        />
      )}
    </div>
  );
};

const RemoveIcon = ({ onClick }: any) => (
  <span
    className="w-6 h-6 md:w-8 md:h-8 inline-block absolute top-0 right-0 m-2 md:m-4 cursor-pointer"
    onClick={onClick}
  >
    {icons.cross}
  </span>
);

const SearchInput = ({
  searchValue,
  onSearchChange,
  hasResults,
  readOnly = false,
}: any) => {
  const intl = useIntl();

  return (
    <Input
      value={searchValue}
      onChange={onSearchChange}
      placeholder={intl.formatMessage({
        id: 'products.search.placeholder',
      })}
      containerClassName="mb-0"
      fullWidth
      style={
        hasResults
          ? {
              borderBottom: 'none',
              borderBottomRightRadius: 0,
              borderBottomLeftRadius: 0,
            }
          : {}
      }
      disabled={readOnly}
    />
  );
};

const ResultsContainer = ({ children, isLoading, totalItems, currPage, handleFetch }: any) => {
  return (
    <div
      className={cn(
        'h-half-screen w-full border p-4 border-border rounded-lg border-t-none absolute z-10 left-0 bg-white',
        { 'overflow-y-scroll': !isLoading, 'overflow-hidden': isLoading }
      )}
      id="scrollableDiv"
      style={{
        borderTop: 'none',
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
    >
      <InfiniteScroll 
        dataLength={PAGE_ITEMS_LIMIT * (currPage + 1)}
        next={handleFetch} 
        hasMore={(totalItems - (currPage * PAGE_ITEMS_LIMIT)) > 0}
        loader={<h4>Loading ...</h4>}
        endMessage={
          <p style={{ textAlign: 'center' }}>
            <b>Yay! You have seen it all</b>
          </p>
        }
        scrollThreshold='50px'
        scrollableTarget="scrollableDiv"
      >
        {children}
      </InfiniteScroll>
    </div>
  );
};

const ProductResultCard = ({
  product,
  onProductSelect,
}: {
  product: ProductResponse;
  onProductSelect: Function;
}) => {
  const intl = useIntl();
  if (!product) return null;
  const price = getFormattedPriceFromProduct(product);

  return (
    <div className="flex items-center border-t border-border py-6 flex-col sm:flex-row">
      <img
        className="w-32 max-h-300 object-cover object-center rounded-md"
        src={product.thumbnail_url}
        alt={product.name}
      />
      <div className="flex flex-col items-start ml-6 max-w-xs items-center sm:items-start ">
        {product.brand_name && (
          <span className="uppercase text-sm md:text-base">
            {product.brand_name}
          </span>
        )}
        {product.name && (
          <span className="inline-block mt-3 text-sm text-secondary leading-5 uppercase font-bold">
            {product.name}
          </span>
        )}
        {price && (
          <span className="mb-2 uppercase text-xs md:text-base font-bold">
            {price}
          </span>
        )}
        <AuthButton
          onClick={() => onProductSelect(product)}
          className="mt-3"
          fullWidth={false}
        >
          {intl.formatMessage({
            id: 'collection.products.addtoCollection',
          })}
        </AuthButton>
      </div>
    </div>
  );
};

export default ProductsSerch;
