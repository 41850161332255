import * as React from 'react';
import { path } from 'ramda';
import { AuthInput as Input } from 'components/input';
import { AuthButton as Button } from 'components/button';
import { useIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

export type FormData = {
  username: string;
  password: string;
};

const useLoginSchema = () => {
  const intl = useIntl();

  return React.useMemo(
    () =>
      yup.object().shape({
        username: yup.string().required(
          intl.formatMessage({
            id: 'validation.fieldRequired',
          })
        ),
        password: yup.string().required(
          intl.formatMessage({
            id: 'validation.fieldRequired',
          })
        ),
      }),
    [intl]
  );
};

type Props = {
  onSubmit: (formData: FormData) => Promise<any>;
  error?: string;
  isLoading: boolean;
  onResetPasswordClick: () => void;
  onReceivedConfirmationCodeClick: () => void;
};

const LoginForm = ({
  onSubmit,
  isLoading,
  onResetPasswordClick,
  onReceivedConfirmationCodeClick,
}: Props) => {
  const intl = useIntl();
  const schema = useLoginSchema();
  const { register, handleSubmit, errors } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  return (
    <div>
      <div className="text-center mb-5 md:mb-10">
        <h1 className="text-xl">
          <FormattedMessage
            id="auth.welcomeBack"
            defaultMessage="Welcome Back"
          />
        </h1>
      </div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          inputRef={register}
          name="username"
          placeholder={intl.formatMessage({
            id: 'auth.usernamePlaceholder',
          })}
          error={path(['username', 'message'], errors)}
        />
        <Input
          inputRef={register}
          name="password"
          type="password"
          placeholder={intl.formatMessage({
            id: 'auth.passwordPlaceholder',
          })}
          containerClassName="mt-4"
          error={path(['password', 'message'], errors)}
        />
        <div className="flex flex-col text-end">
          <span
            onClick={onResetPasswordClick}
            className="text-xs text-secondary underline cursor-pointer"
          >
            <FormattedMessage id="auth.forgotPassword" />
          </span>
          <span
            onClick={onReceivedConfirmationCodeClick}
            className="text-xs text-secondary underline cursor-pointer mt-1"
          >
            <FormattedMessage id="auth.receivedConfirmationCode" />
          </span>
        </div>
        <div className="mt-5">
          <Button type="submit" className="mt-5" fullWidth>
            <FormattedMessage
              id={isLoading ? 'common.pleaseWait' : 'auth.signIn'}
            />
          </Button>
        </div>
      </form>
    </div>
  );
};

export { LoginForm as default };
