import CountryPickerItem from './CountryPickerItem';

type Props = {
    countryList: any;
    onCountryPick: (country: Country) => void;
    pickedCountry: Country;
}
const CountryPickerList = ({countryList, onCountryPick, pickedCountry} : Props) => {
    return (
        <div className="flex flex-wrap sm:justify-start justify-center">
            {countryList.map((item) => (
                <CountryPickerItem 
                key={item.value}
                imagePath={item.fileImageSrc} 
                countryName={item.name} 
                countrySc={item.value} 
                onCountryPick={onCountryPick}
                active={pickedCountry === item.value}
                />
                ))}
        </div>
    )
}

export default CountryPickerList;