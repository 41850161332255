import * as React from 'react';
import {useHistory} from 'react-router';

const Logo = ({
  lang,
  width = '146',
  ...rest
}: any & { lang?: string; width?: string }) => {
  const history = useHistory();
  const src = 'https://media.6media.me/media/logo/stores/1/en_logo.png';
  return <img className="cursor-pointer" src={src} alt="Logo" width={width} {...rest} onClick={() => history.push('/')} />;
};

export { Logo as default };
