import * as React from 'react';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Header from 'components/header';
import Layout from 'components/layout';
import PageTitle from 'components/PageTitle';
import { userSelector } from 'lib/redux';

import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { USER_ROLES } from 'config/const';

import Content from 'components/content';
import { Popup } from '../Popup/Popup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
  faChevronLeft,
} from '@fortawesome/free-solid-svg-icons';
import './InfluencerDashboard.style.css';

const InfluencerDashboard = () => {
  const [isLoading, setIsLoading] = React.useState(false);
  const user = useSelector(userSelector);
  const isAdmin = user && user.role === USER_ROLES.SUPERAGENT;
  const [data, setData] = React.useState<any[]>([]);
  let [sortfield, setSortfield] = React.useState<string>('revenueGenerated');
  const [sortFieldHeader, setSortFieldHeader] =
    React.useState<string>('Revenue Generated');
  const [sortFieldSelectedHeader, setSortFieldSelectedHeader] =
    React.useState<string>('Revenue Generated');
  const [influencerName, setInfluencerName] = React.useState<string>('');
  const [isCouponPopupOpen, setIsCouponPopupOpen] =
    React.useState<boolean>(false);
  const [perpage, setPerpage] = React.useState<any>(10);
  const [currentPage, setCurrentPage] = React.useState<any>(1);
  const [filteredFields, setFilteredFields] = React.useState<string[]>([]);
  const [intermediateField, setIntermediateField] = React.useState<string[]>([
    '1',
    '2',
    '3',
    '4',
    '5',
    '6',
    '7',
    '8',
  ]);
  const [mappedField, setMappedField] = React.useState<string[]>([]);
  const [isClickedapply, setisClickedapply] = React.useState<boolean>(false);
  const [isClickedFilter, setisClickedFilter] = React.useState<boolean>(false);
  const [isClickedReport, setisClickedReport] = React.useState<boolean>(false);
  const [totalItems, setTotalItems] = React.useState<number>(0);
  const [sortType, setSortType] = React.useState('HL');
  const [allcolumnClicked, setallcolumnClicked] = useState<boolean>(true);
  const [showSortingPopUp, setShowSortingPopUp] =
    React.useState<Boolean>(false);
  const wrapperRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    fetchFeaturedProducts();
  }, [currentPage, influencerName, sortType, sortFieldSelectedHeader]);

  useEffect(() => {
    window.addEventListener('mousedown', outsideCouponPopupClick);
    return () => {
      window.removeEventListener('mousedown', outsideCouponPopupClick);
    };
  }, [wrapperRef]);

  const outsideCouponPopupClick = e => {
    if (
      showSortingPopUp &&
      wrapperRef.current &&
      !wrapperRef?.current?.contains(e.target)
    ) {
      setShowSortingPopUp(false);
    }
  };

  const closeCouponPopup = e => {
    e.stopPropagation();
    setIsCouponPopupOpen(false);
    setisClickedFilter(false);
    setisClickedReport(false);
  };

  const openCouponPopup = () => {
    setIsCouponPopupOpen(true);
  };

  const hideCouponDetial = e => {
    e.stopPropagation();
    setIsCouponPopupOpen(true);
  };

  const SortingCheck = (value, index) => {
    setShowSortingPopUp(!showSortingPopUp);
    switch (index) {
      case 0:
        setSortfield('followers');
        setSortFieldHeader(value);
        break;
      case 1:
        setSortfield('collections');
        setSortFieldHeader(value);
        break;
      case 2:
        setSortfield('clickOnStores');
        setSortFieldHeader(value);
        break;
      case 3:
        setSortfield('clickOnCollections');
        setSortFieldHeader(value);
        break;
      case 4:
        setSortfield('inCartProducts');
        setSortFieldHeader(value);
        break;
      case 5:
        setSortfield('wishlistedProducts');
        setSortFieldHeader(value);
        break;
      case 6:
        setSortfield('productsSold');
        setSortFieldHeader(value);
        break;
      case 7:
        setSortfield('revenueGenerated');
        setSortFieldHeader(value);
        break;
      case 8:
        setSortfield('commissionEarned');
        setSortFieldHeader(value);
        break;
      default:
        setSortfield('revenueGenerated');
        setSortFieldHeader('Revenue Generated');
    }
  };

  const fetchFeaturedProducts = async () => {
    try {
      setIsLoading(true);
      const response = await restClient.get(
        `${
          endpoints.influencerDashboard.performanceReport
        }?q=${influencerName}&limit=${perpage}&page=${
          currentPage - 1
        }&sortType=${sortType}&sortBy=${sortfield}`
      );
      setData(response?.items);
      setTotalItems(response?.totalItems);
    } catch (e: any) {
      console.error(e.message);
    } finally {
      setIsLoading(false);
    }
  };

  const adminDashboardFields = [
    { header: 'Followers', isvisible: true, id: 1 },
    { header: 'Collections', isvisible: true, id: 2 },
    { header: 'Clicks on Store', isvisible: true, id: 3 },
    { header: 'Clicks on Collection', isvisible: true, id: 4 },
    { header: 'In Cart Products', isvisible: true, id: 5 },
    { header: 'Wishlisted Products', isvisible: true, id: 6 },
    { header: 'Products Sold', isvisible: true, id: 7 },
    { header: 'Revenue Generated', isvisible: true, id: 8 },
  ];

  const handleSortIngClick = (val, header) => {
    setSortType(val);
    setShowSortingPopUp(false);
    setSortFieldSelectedHeader(header);
  };

  const sortingPopUp = header => {
    return (
      <div className="sortingPopUp" ref={wrapperRef}>
        <div className="sortingPopUpElement">
          <input
            id="LH"
            type="radio"
            value={'LH'}
            onChange={e => handleSortIngClick(e.target.value, header)}
            className="inputBox"
            checked={sortType === 'LH' && sortFieldSelectedHeader === header}
          />
          <label htmlFor="LH">{'Low to high'}</label>
        </div>
        <div className="sortingPopUpElement">
          <input
            id="HL"
            type="radio"
            value={'HL'}
            onChange={e => handleSortIngClick(e.target.value, header)}
            className="inputBox"
            checked={sortType === 'HL' && sortFieldSelectedHeader === header}
          />
          <label htmlFor="HL">{'High to low'}</label>
        </div>
      </div>
    );
  };

  const renderInfluencerDashboard = () => {
    let content =
      data &&
      data?.filter(val => {
        if (
          val.influencerName
            .toLowerCase()
            .includes(influencerName.toLocaleLowerCase())
        ) {
          return val;
        }
      });

    const filtererCount = content.map(
      (
        {
          influencerName,
          followers,
          collections,
          clickOnStores,
          clickOnCollections,
          inCartProducts,
          wishlistedProducts,
          productsSold,
          revenueGenerated,
          commissionEarned,
        },
        i
      ) => {
        return (
          <tr key={i}>
            <td>{influencerName}</td>
            {(mappedField.length === 0 ||
              mappedField.includes('totalFollowers')) &&
              intermediateField.includes('1') && (
                <td>{followers === null ? '--' : followers}</td>
              )}

            {(mappedField.length === 0 ||
              mappedField.includes('totalCollections')) &&
              intermediateField.includes('2') && (
                <td>{collections === null ? '--' : collections}</td>
              )}
            {(mappedField.length === 0 ||
              mappedField.includes('ClicksonStore')) &&
              intermediateField.includes('3') && (
                <td>{clickOnStores === null ? '--' : clickOnStores}</td>
              )}
            {(mappedField.length === 0 ||
              mappedField.includes('ClicksonCollection')) &&
              intermediateField.includes('4') && (
                <td>
                  {clickOnCollections === null ? '--' : clickOnCollections}
                </td>
              )}
            {(mappedField.length === 0 ||
              mappedField.includes('InCartProducts')) &&
              intermediateField.includes('5') && (
                <td>{inCartProducts === null ? '--' : inCartProducts}</td>
              )}
            {(mappedField.length === 0 ||
              mappedField.includes('WishlistedProducts')) &&
              intermediateField.includes('6') && (
                <td>
                  {wishlistedProducts === null ? '--' : wishlistedProducts}
                </td>
              )}
            {(mappedField.length === 0 ||
              mappedField.includes('productSold')) &&
              intermediateField.includes('7') && <td>{productsSold}</td>}
            {(mappedField.length === 0 ||
              mappedField.includes('revenueGenerated')) &&
              intermediateField.includes('8') && (
                <td>{revenueGenerated === null ? '--' : revenueGenerated}</td>
              )}
            {
              <td
                className={`${
                  sortFieldHeader !== 'Commission Earned' &&
                  'commisionColumnactive'
                }`}
              >
                {commissionEarned === null ? '--' : commissionEarned}
              </td>
            }
          </tr>
        );
      }
    );
    return filtererCount;
  };

  const handlePreviousButton = () => {
    Number(currentPage);
    if (currentPage > 0) {
      setCurrentPage(Number(currentPage - 1));
    }
  };

  const handleNextButton = () => {
    Number(currentPage);
    if (currentPage > 0) {
      setCurrentPage(Number(currentPage + 1));
    }
  };

  const handleRequestReport = () => {
    setisClickedFilter(false);
    setisClickedReport(true);
  };

  const displayRange = (totalItems, perpage, currentPage) => {
    const start = Math.max(perpage * (currentPage - 1), 0);
    const end = Math.min(perpage * currentPage, totalItems);

    return `${start + 1} - ${end} of ${totalItems}`;
  };

  return (
    <>
      <Layout>
        <Header>
          <PageTitle
            title={isAdmin ? 'Influencer Dashboard' : 'Page Not Found'}
          />
        </Header>
        <Content>
          <div className="influencer-table-header-section">
            <div className="headerSectionLeftContainer">
              <div className="search-influncer-name">
                <input
                  className="search-influencer-name-input"
                  type="text"
                  placeholder="Search by Influencer name"
                  onChange={e => {
                    setInfluencerName(e.target.value);
                    setCurrentPage(1);
                  }}
                ></input>
              </div>
              <div className="filtering-section">
                <button
                  className="all-column-shown-button"
                  onClick={openCouponPopup}
                >
                  {isCouponPopupOpen && (
                    <div>
                      <Popup
                        couponDetail={isCouponPopupOpen}
                        hideCouponDetial={hideCouponDetial}
                        closeCouponPopup={closeCouponPopup}
                        filteredFields={filteredFields}
                        setFilteredFields={setFilteredFields}
                        isClickedapply={isClickedapply}
                        setisClickedapply={setisClickedapply}
                        setIntermediateField={setIntermediateField}
                        intermediateField={intermediateField}
                        setMappedField={setMappedField}
                        mappedField={mappedField}
                        isClickedFilter={isClickedFilter}
                        setisClickedFilter={setisClickedFilter}
                        setisClickedReport={setisClickedReport}
                        isClickedReport={isClickedReport}
                        setData={setData}
                        data={data}
                        allcolumnClicked={allcolumnClicked}
                        setallcolumnClicked={setallcolumnClicked}
                      />
                    </div>
                  )}
                  <div
                    className="all-column-shown"
                    onClick={() => {
                      setisClickedFilter(true);
                    }}
                  >
                    {intermediateField.length === 8
                      ? 'All Column Showing'
                      : `${mappedField.length} columns showing`}{' '}
                    <span className="dropDownIcon">
                      <FontAwesomeIcon icon={faChevronDown} size="sm" />
                    </span>
                  </div>
                </button>
              </div>
            </div>

            <div className="request-report">
              <button
                className="all-column-shown-button"
                onClick={openCouponPopup}
              >
                {isCouponPopupOpen && (
                  <div>
                    <Popup
                      couponDetail={isCouponPopupOpen}
                      hideCouponDetial={hideCouponDetial}
                      closeCouponPopup={closeCouponPopup}
                      filteredFields={filteredFields}
                      setFilteredFields={setFilteredFields}
                      isClickedapply={isClickedapply}
                      setisClickedapply={setisClickedapply}
                      setIntermediateField={setIntermediateField}
                      intermediateField={intermediateField}
                      setMappedField={setMappedField}
                      mappedField={mappedField}
                      isClickedFilter={isClickedFilter}
                      setisClickedFilter={setisClickedFilter}
                      setisClickedReport={setisClickedReport}
                      isClickedReport={isClickedReport}
                      setData={setData}
                      data={data}
                      allcolumnClicked={allcolumnClicked}
                      setallcolumnClicked={setallcolumnClicked}
                    />
                  </div>
                )}
                <div className="request-content" onClick={handleRequestReport}>
                  {'REQUEST REPORT'}
                  <span className="request-content">
                    <FontAwesomeIcon icon={faChevronRight} size="sm" />
                  </span>
                </div>
              </button>
            </div>
          </div>
          <div className="admin-dashboard">
            <table className={'table-container'}>
              <tbody>
                <tr>
                  <th>{'influencers Name'}</th>
                  {adminDashboardFields.map(({ header, isvisible, id }, i) => {
                    if (
                      (filteredFields.length === 0 ||
                        filteredFields.includes(id.toString())) &&
                      intermediateField.includes(`${i + 1}`)
                    ) {
                      return (
                        <th
                          key={header}
                          className={`${
                            intermediateField.length < 3 && 'removeLeft'
                          }`}
                        >
                          <span>
                            {header}
                            <button onClick={() => SortingCheck(header, i)}>
                              <img
                                src="https://cdn-icons-png.flaticon.com/512/37/37808.png"
                                alt="sorting button"
                              />
                            </button>
                            {showSortingPopUp &&
                              sortFieldHeader === header &&
                              sortingPopUp(header)}
                          </span>
                        </th>
                      );
                    }
                  })}
                  <th
                    className={`${
                      intermediateField.length < 3 && 'removeLeft'
                    }`}
                  >
                    <span>
                      {'Commission Earned'}
                      <button
                        onClick={() =>
                          SortingCheck(
                            'Commission Earned',
                            adminDashboardFields.length
                          )
                        }
                      >
                        <img
                          src="https://cdn-icons-png.flaticon.com/512/37/37808.png"
                          alt="sorting button"
                        />
                      </button>
                      {showSortingPopUp &&
                        sortFieldHeader === 'Commission Earned' &&
                        sortingPopUp('Commission Earned')}
                    </span>
                  </th>
                </tr>

                {renderInfluencerDashboard()}
              </tbody>
            </table>
          </div>
        </Content>
        <div className="bottomSection">
          {data.length > 0 ? (
            <div className="pagination-items">
              {displayRange(totalItems, perpage, currentPage)}
              <div className="prevous-next-buttons">
                <button
                  className="previousIcon"
                  onClick={handlePreviousButton}
                  disabled={currentPage === 1}
                >
                  <FontAwesomeIcon
                    icon={faChevronLeft}
                    size="lg"
                    className={currentPage === 1 ? 'text-gray' : 'text-black'}
                  />
                </button>
                <button
                  className="NextIcon"
                  onClick={handleNextButton}
                  disabled={currentPage === Math.ceil(totalItems / perpage)}
                >
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    size="lg"
                    className={
                      currentPage === Math.ceil(totalItems / perpage)
                        ? 'text-gray'
                        : 'text-black'
                    }
                  />
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </Layout>
    </>
  );
};

export default InfluencerDashboard;
