import * as React from 'react';
import { toast, TypeOptions } from 'react-toastify';

const createToast = (type: TypeOptions) => (
  message: React.ReactNode,
  options?: any
) =>
  toast(message, {
    ...options,
    type,
  });

export const successToast = createToast(toast.TYPE.SUCCESS);
export const errorToast = createToast(toast.TYPE.ERROR);
export const warningToast = createToast(toast.TYPE.WARNING);
export const infoToast = createToast(toast.TYPE.INFO);
