import { AVAILABLE_LANGUAGES } from 'config/const';
import { langSelector } from 'lib/redux';
import * as React from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';

type Props = {
  children?: React.ReactNode;
};

const Head = ({ children }: Props) => {
  const intl = useIntl();
  const lang = useSelector(langSelector);
  const dir = lang === AVAILABLE_LANGUAGES.ARABIC ? 'rtl' : 'ltr';

  return (
    <Helmet
      htmlAttributes={{ lang: lang.toLowerCase() }}
      bodyAttributes={{ dir }}
    >
      <title>{intl.formatMessage({ id: 'common.companyName' })}</title>
      <link rel="icon" type="image/png" href="img/favicon.png" />
      {children}
    </Helmet>
  );
};

export default Head;
