import * as React from 'react';
import icons from 'components/icons';
import cn from 'classnames';
import Heading from './heading';

type Props = {
  color?: 'green' | 'higlight' | 'brandBlue';
  iconName?: string;
  title: React.ReactNode;
  details: React.ReactNode;
  onClick?: (evt: React.MouseEvent<HTMLDivElement>) => void;
};

const SummaryCard = ({
  color = 'green',
  iconName,
  title,
  details,
  onClick,
}: Props) => {
  const icon = icons[iconName || ''] || icons.collection;

  return (
    <div
      onClick={onClick}
      className={cn(
        'flex flex-col justify-center items-center border border-border rounded-xl py-4 px-8 md:w-56',
        {
          'cursor-pointer': onClick,
        }
      )}
    >
      <span
        className={cn(
          'w-10 h-10 md:w-12 md:h-12 inline-block p-2 bg-opacity-25 rounded-full',
          {
            'text-brandBlue': color === 'brandBlue',
            'bg-brandBlue': color === 'brandBlue',
            'text-green': color === 'green',
            'bg-green': color === 'green',
            'text-higlight': color === 'higlight',
            'bg-higlight': color === 'higlight',
          }
        )}
      >
        {icon}
      </span>
      <span className="inline-block text-secondary text-center text-sm md:text-lg mt-2 md:mt-4">
        {title}
      </span>
      <Heading>{details}</Heading>
    </div>
  );
};

export default SummaryCard;
