import { TYPES } from './actions';

type CollectionsState = {
  draft: Array<CollectionResponse>;
  inReview: Array<CollectionResponse>;
  live: Array<CollectionResponse>;
  publishing: Array<CollectionResponse>;
};

const initialState: CollectionsState = {
  draft: [],
  inReview: [],
  live: [],
  publishing: [],
};

const reducer = (state = initialState, action): CollectionsState => {
  switch (action.type) {
    case TYPES.SET_DRAFT_COLLECTIONS:
      return { ...state, draft: action.payload };
    case TYPES.SET_IN_REVIEW_COLLECTIONS:
      return { ...state, inReview: action.payload };
    case TYPES.SET_PUBLISHING_COLLECTIONS:
      return { ...state, publishing: action.payload };
    case TYPES.SET_LIVE_COLLECTIONS:
      const preLiveParentIds = [...state.draft, ...state.inReview]
        .filter(collection => collection.parentId)
        .map(collection => collection.parentId);
      const live = (action.payload || []).filter(
        collection => !preLiveParentIds.includes(collection.id)
      );
      return { ...state, live };
    default:
      return state;
  }
};

const selectors = {
  preLiveCollectionsCountSelector: (state: CollectionsState): number => {
    let count = 0;
    count += state.draft ? state.draft.length : 0;
    count += state.inReview ? state.inReview.length : 0;
    count += state.publishing ? state.publishing.length : 0;
    return count;
  },
  liveCollectionsCountSelector: (state: CollectionsState): number => {
    return state.live ? state.live.length : 0;
  },
};

export { reducer as default, selectors };
