import {types} from "./actions";

const initialState = {
    titleEnglish: '',
    titleArabic: '',
    descriptionEnglish: '',
    descriptionArabic: '',
    audience: '',
    cover: '',
    media: '',
    nameEnglish: '',
    nameArabic: '',
    familyNameEnglish: '',
    familyNameArabic: '',
    items: '',
    influencerNameArabic: '',
    influencerNameEnglish: '',
    email: '',
    phone: '',
    profilePictureUrl: '',
    instagram: '',
    accountModeratorId: '',
}

const returnCollectionFormState = (titleName, state, value) => {
    return { ...state, [titleName]: value };
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case types.VALIDATE_COLLECTION_INPUT:
            return returnCollectionFormState(action.name, state, action.value);
        default:
            return state;
    }
}

export default reducer;