import * as React from 'react';

interface Props {
  children?: React.ReactNode;
}

const Header = ({ children }: Props) => {
  return (
    <div data-testid="page-content" className="px-4 py-6 md:px-16">
      {children}
    </div>
  );
};

export { Header as default };
