import * as React from 'react';
import cn from 'classnames';

type Props = any;

const Label = ({ className, ...rest }: Props) => {
  return <label className={cn('text-secondary', className)} {...rest} />;
};

export default Label;
