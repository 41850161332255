import { Storage } from 'aws-amplify';
import { REACT_APP_CDN_HOST } from 'config/const';
import { v4 as uuid } from 'uuid';

type FileType = 'image';

const mediaPathByType = {
  image: 'resources/images',
};

export const uploadFileToS3 = async (file: File, type: FileType = 'image') => {
  try {
    const fileExt = file.name.split('.').pop();
    const filePath = `${mediaPathByType[type]}/${uuid()}.${fileExt}`;
    const { key }: any = await Storage.put(filePath, file);
    return `${REACT_APP_CDN_HOST}/public/${key}`;
  } catch (error:any) {
    throw new Error(
      `Error when uploading file to S3 Bucket: ${
        error && error.message ? error.message : ''
      }`
    );
  }
};
