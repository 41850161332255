import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { Auth } from 'aws-amplify';
import { changeLanguage } from '../lib/redux/lang/actions';
import SetupFirstPasswordForm from 'components/forms/setup-first-password-form';
import LoginForm, { FormData as LoginFormData } from 'components/login-form';
import { ConfirmResetPasswordForm } from 'components/forms/forgot-password-form';
import {
  AVAILABLE_LANGUAGES,
  AWS_NEW_PASSWORD_CHALLANGE_NAME,
  AWS_PASSWORD_RESET_REQUIRED_ERROR,
} from 'config/const';
import { langSelector } from 'lib/redux';
import { getResponseErrorMessage } from 'utils/common';
import RequestNewPasswordForm from 'components/forms/request-new-password';
import enFlag from 'assets/img/flags/en.svg';
import sauFlag from 'assets/img/flags/sau.svg';
import logoImg from 'assets/img/en_logo.png';

type Props = {
  signInCallback: () => void;
};

const stages = {
  signIn: 'signIn',
  setupFirstPassword: 'firstPassword',
  confirmPasswordReset: 'resetPassword',
  requestPasswordReset: 'requestPassowrdReset',
};

const AuthPage = (props: Props) => {
  const intl = useIntl();
  const [stage, setStage] = React.useState(stages.signIn);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [info, setInfo] = React.useState<string | null>(null);
  const [tempCognitoUser, setTempCogntoUser] = React.useState(null);
  const [tempUsername, setTempUsername] = React.useState<string | null>(null);
  const dispatch = useDispatch();
  const lang = useSelector(langSelector);

  const onSignIn = async ({ username, password }: LoginFormData) => {
    try {
      setIsLoading(true);
      setError(null);
      setTempUsername(username);
      const user = await Auth.signIn(username, password);
      if (user.challengeName === AWS_NEW_PASSWORD_CHALLANGE_NAME) {
        setTempCogntoUser(user);
        setStage(stages.setupFirstPassword);
        setIsLoading(false);
      } else {
        props.signInCallback();
      }
    } catch (error:any) {
      setIsLoading(false);
      if (error.code === AWS_PASSWORD_RESET_REQUIRED_ERROR) {
        setStage(stages.confirmPasswordReset);
      } else {
        setError(getResponseErrorMessage(error));
      }
    }
  };

  const handleSetupFirstPassword = async ({ password }) => {
    try {
      setIsLoading(true);
      await Auth.completeNewPassword(tempCognitoUser, password);
      setIsLoading(false);
      props.signInCallback();
    } catch (error:any) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleResetPassword = async ({ username, code, password }) => {
    try {
      setIsLoading(true);
      await Auth.forgotPasswordSubmit(username, code, password);
      setInfo(intl.formatMessage({ id: 'auth.passwordUpdated' }));
      setIsLoading(false);
      setStage(stages.signIn);
    } catch (error:any) {
      setIsLoading(false);
      setError(error.message);
    }
  };

  const handleRequestPasswordReset = async ({ username }) => {
    try {
      setError(null);
      setIsLoading(true);
      await Auth.forgotPassword(username);
      setIsLoading(false);
      setTempUsername(username);
      setStage(stages.confirmPasswordReset);
      setInfo(intl.formatMessage({ id: 'auth.restMailSent' }));
    } catch (error) {
      setError(getResponseErrorMessage(error));
      setIsLoading(false);
    }
  };

  const onLanguageChange = () => {
    dispatch(
      changeLanguage(
        lang === AVAILABLE_LANGUAGES.ENGLISH
          ? AVAILABLE_LANGUAGES.ARABIC
          : AVAILABLE_LANGUAGES.ENGLISH
      )
    );
  };

  const languageNameField =
    lang === AVAILABLE_LANGUAGES.ENGLISH
      ? 'common.languages.ar'
      : 'common.languages.en';

  const resetMessages = () => {
    setError(null);
    setInfo(null);
  };

  const changeStage = (stage: string) => {
    resetMessages();
    setStage(stage);
  };

  return (
    <>
      <div className="fixed inset-0 bg-center bg-cover bg-authBg" />
      <div className="flex flex-col justify-between w-screen h-screen">
        <div className="md:w-450 m-5 md:mx-auto md:my-56 bg-white rounded-lg shadow-md relative z-20">
          <div className="py-8 flex justify-center bg-lightGray rounded-lg rounded-b-none">
            <img width="180" height="60" src={logoImg} alt="Logo" />
          </div>
          <div className="px-6 py-5 md:p-16 md:pt-10 flex flex-col">
            {stage === stages.signIn && (
              <LoginForm
                isLoading={isLoading}
                onSubmit={onSignIn}
                onResetPasswordClick={() =>
                  changeStage(stages.requestPasswordReset)
                }
                onReceivedConfirmationCodeClick={() =>
                  changeStage(stages.confirmPasswordReset)
                }
              />
            )}
            {stage === stages.setupFirstPassword && (
              <SetupFirstPasswordForm
                onSubmit={handleSetupFirstPassword}
                isLoading={isLoading}
              />
            )}
            {stage === stages.requestPasswordReset && (
              <RequestNewPasswordForm
                onSubmit={handleRequestPasswordReset}
                isLoading={isLoading}
                onFormExit={() => changeStage(stages.signIn)}
              />
            )}
            {stage === stages.confirmPasswordReset && (
              <ConfirmResetPasswordForm
                username={tempUsername!}
                onSubmit={handleResetPassword}
                isLoading={isLoading}
                onFormExit={() => changeStage(stages.signIn)}
              />
            )}
            {error && (
              <div className="mt-4 text-center text-error">{error}</div>
            )}
            {info && (
              <div className="mt-4 text-center text-primary">{info}</div>
            )}
          </div>
        </div>
        <footer className="bg-white w-screen py-4 md:py-6 flex justify-center z-10">
          <button onClick={onLanguageChange} className="flex items-center">
            <img
              className="w-8 h-8"
              src={lang === AVAILABLE_LANGUAGES.ENGLISH ? sauFlag : enFlag}
              alt="Change language"
            />
            <span className="px-4">
              {intl.formatMessage({ id: languageNameField })}
            </span>
          </button>
        </footer>
      </div>
    </>
  );
};

export default AuthPage;
