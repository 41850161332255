import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { Dispatch } from 'redux';
import { COLLECTION_STATE } from 'config/const';

const TYPES = {
  SET_LIVE_COLLECTIONS: 'SET_LIVE_COLLECTIONS',
  SET_DRAFT_COLLECTIONS: 'SET_DRAFT_COLLECTIONS',
  SET_IN_REVIEW_COLLECTIONS: 'SET_IN_REVIEW_COLLECTIONS',
  SET_PUBLISHING_COLLECTIONS: 'SET_PUBLISHING_COLLECTIONS',
};

const actionTypeByCollectionState = {
  [COLLECTION_STATE.LIVE]: TYPES.SET_LIVE_COLLECTIONS,
  [COLLECTION_STATE.DRAFT]: TYPES.SET_DRAFT_COLLECTIONS,
  [COLLECTION_STATE.IN_REVIEW]: TYPES.SET_IN_REVIEW_COLLECTIONS,
  [COLLECTION_STATE.PUBLISHING]: TYPES.SET_PUBLISHING_COLLECTIONS,
};

const preLiveStates = [
  COLLECTION_STATE.DRAFT,
  COLLECTION_STATE.IN_REVIEW,
  COLLECTION_STATE.PUBLISHING,
] as Array<CollectionState>;

const fetchCollectionsByState = (collectionState: CollectionState) => async (
  dispatch: Dispatch
): Promise<any> => {
  try {
    const { items } = await restClient.get(endpoints.collections, {
      queryStringParameters: {
        state: collectionState,
      },
    });
    dispatch({
      type: actionTypeByCollectionState[collectionState],
      payload: items,
    });
  } catch (error) {}
};

const fetchModeratorCollections = () => async (dispatch: Dispatch) =>
  dispatch(
    fetchCollectionsByState(
      COLLECTION_STATE.IN_REVIEW as CollectionState
    ) as any
  );

const fetchInfluencerCollections = () => async (dispatch: Dispatch) => {
  await Promise.all(
    preLiveStates.map(state => dispatch(fetchCollectionsByState(state) as any))
  );
  await dispatch(
    fetchCollectionsByState(COLLECTION_STATE.LIVE as CollectionState) as any
  );
};

export {
  TYPES,
  fetchCollectionsByState,
  fetchModeratorCollections,
  fetchInfluencerCollections,
};
