import { AVAILABLE_LANGUAGES } from 'config/const';
import { langSelector } from 'lib/redux';
import cn from 'classnames';
import * as React from 'react';
import { useSelector } from 'react-redux';
import { ToastContainer as DefaultToastContainer, toast } from 'react-toastify';

type Props = {
  options?: any;
};

const { TYPE } = toast;

export default function ToastContainer({ options }: Props) {
  const lang = useSelector(langSelector);
  return (
    <DefaultToastContainer
      position="top-center"
      rlt={lang === AVAILABLE_LANGUAGES.ARABIC}
      className="md:w-6/12"
      toastClassName={({ type, defaultClassName }: any) => {
        return cn(defaultClassName, 'p-6 rounded-md', {
          'bg-green': type === TYPE.SUCCESS,
          'bg-error': type === TYPE.ERROR,
          'bg-higlight': type === TYPE.WARNING,
        });
      }}
      bodyClassName="text-lg font-avenir"
      progressClassName={({ defaultClassName }: any) =>
        cn(defaultClassName, 'bg-white bg-opacity-75')
      }
      limit={3}
      {...options}
    />
  );
}
