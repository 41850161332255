import * as React from 'react';
import icons from 'components/icons';
import { useIntl } from 'react-intl';
import Textarea from 'components/textarea';
import { SubmitButton } from 'components/button';



type Props = {
  children: React.ReactNode;
  onExit?: () => void;
  withExitButton?: boolean;
  whiteBackground?: boolean;
};

export default function Modal({ children, onExit, withExitButton, whiteBackground }: Props) {
  const keyPressListener = (evt: any) => {
    if (evt.keyCode === 27) {
      onExit && onExit();
    }
  };

  React.useEffect(() => {
    document.addEventListener('keydown', keyPressListener, false);
    return () =>
      document.removeEventListener('keydown', keyPressListener, false);
  }, []);

  return (
    <div className={whiteBackground ? "fixed top-0 left-0 flex justify-center w-screen h-screen m-0 bg-white z-40 overflow-x-auto" : "fixed top-0 left-0 flex justify-center w-screen h-screen m-0 bg-black bg-opacity-25 z-40 overflow-x-auto"}>
      <div className="box-content max-w-screen-md md:min-w-400 md:min-h-200 mx-2 my-auto md:m-auto bg-white rounded-lg border border-gray px-6 py-6 md:px-12 md:py-12 relative">
        {withExitButton && (
          <span
            className="inline-block absolute top-0 right-0 m-4 w-8 h-8 text-border cursor-pointer hover:text-primary transition-colors duration-200"
            onClick={onExit}
          >
            {icons.cross}
          </span>
        )}
        {children}
      </div>
    </div>
  );
}

type RejectionModalProps = {
  onExit: () => void;
  onRejectSubmit?: (reason: RejectionReason) => void;
};

export const RejectionModal = ({
  onExit,
  onRejectSubmit,
}: RejectionModalProps) => {
  const intl = useIntl();
  const [feedback, setFeedback] = React.useState<RejectionReason>({
    messageArabic: '',
    messageEnglish: '',
  });

  return (
    <Modal onExit={onExit} withExitButton>
      <div className="flex flex-col">
        <h1>{intl.formatMessage({ id: 'collection.feedback' })}</h1>
        <Textarea
          value={feedback.messageEnglish}
          label={intl.formatMessage({ id: 'collection.feedback' })}
          fullWidth
          containerClassName="my-2"
          onChange={evt =>
            setFeedback({ ...feedback, messageEnglish: evt.target.value })
          }
        />
        <Textarea
          value={feedback.messageArabic}
          label={intl.formatMessage({ id: 'collection.arabicFeedback' })}
          dir="rtl"
          fullWidth
          containerClassName="my-2"
          onChange={evt =>
            setFeedback({ ...feedback, messageArabic: evt.target.value })
          }
        />
        <SubmitButton
          onClick={() => onRejectSubmit && onRejectSubmit(feedback)}
          className="mt-4"
          fullWidth
        >
          {intl.formatMessage({ id: 'collection.sendFeedback' }).toUpperCase()}
        </SubmitButton>
      </div>
    </Modal>
  );
};
