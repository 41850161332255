import * as React from 'react';
import {useState} from 'react';
import { USER_ROLES } from 'config/const';
import { useSelector } from 'react-redux';
import { isArabicSelector, userSelector } from 'lib/redux';
import ShareInfluencerLink from './share-influencer-link';
import { useHistory } from 'react-router';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faUserCircle} from '@fortawesome/free-solid-svg-icons';

import useWindowDimmensions from 'hooks/useWindowDimensions';

type Props = {
  user?: User;
  loggedIn?: boolean;
};

const fallbackUserImgSrc = 'img/user_profile_pic_fallback.png';

const UserImage = ({
  imgSrc = fallbackUserImgSrc,
  alt = 'user',
  onClick = () => {},
}) => {
  return (
    <img
      className="w-8 h-8 md:w-10 md:h-10 rounded-full object-cover object-center"
      src={imgSrc}
      alt={alt}
      onClick={onClick}
    />
  );
};

const InfluencerName = ({ influencer }: { influencer: User }) => {
  let title, subtitle;
  const isArabic = useSelector(isArabicSelector);

  if (isArabic) {
    const { influencerNameArabic: accountName } = influencer;
    title =
      accountName && accountName.endsWith('@')
        ? accountName
        : `${accountName}@`;
    subtitle = `${influencer.familyNameArabic} ${influencer.nameArabic}`;
  } else {
    const { influencerNameEnglish: accountName } = influencer;
    title =
      accountName && accountName.startsWith('@')
        ? accountName
        : `@${accountName}`;
    subtitle = `${influencer.nameEnglish} ${influencer.familyNameEnglish}`;
  }

  return (
    <div className="flex flex-col">
      <span className="sm:text-lg text-md font-medium">{title}</span>
      {/* <span className="inline-block text-secondary text-sm md:text-base leading-4">
        {subtitle}
      </span> */}
    </div>
  );
};

const ModeratorName = ({ user }: { user: User }) => {
  let name;
  const isArabic = useSelector(isArabicSelector);

  const getName = React.useCallback(
    (key: keyof User) => (user && user[key] ? user[key] : ''),
    [user]
  );

  if (isArabic) {
    name = `${getName('familyNameArabic')} ${getName('nameArabic')}`;
  } else {
    name = `${getName('nameEnglish')} ${getName('familyNameEnglish')}`;
  }

  return <span className="font-medium text-sm md:text-xl">{name}</span>;
};

const UserDetailsWrapped = () => {
  const [isWrapped, setIsWrapped] = useState(true);
  return (
    <>
      <div onClick={() => setIsWrapped(prev => !prev)}>
        <FontAwesomeIcon icon={faUserCircle} size="2x" color="grey"/>
      </div>
      <div>
      </div>
    </>
  );
}

export const UserDetailsMinor = ({user, loggedIn}: Props) => {
  const loggedInUser = useSelector(userSelector)!;
  const userToDisplay = user && !loggedIn ? user : loggedInUser;

  if (!userToDisplay) return null;
  const isInfluencer = userToDisplay.role === USER_ROLES.INFLUENCER;
  const isLoggedInUser = userToDisplay.id === loggedInUser.id;

  return (
    <div className="flex items-center flex-col md:flex-row">
      { userToDisplay.profilePictureUrl && <UserImage
        imgSrc={userToDisplay.profilePictureUrl}
      />}
      <div className="flex justify-center flex-col mx-2">
        {isInfluencer && (
          <>
            <InfluencerName influencer={userToDisplay} />
            {isLoggedInUser && <ShareInfluencerLink/>}
          </>
        )}
        {!isInfluencer && <ModeratorName user={userToDisplay} />}
      </div>
    </div>
  );
}

export default function UserDetails({ user, loggedIn }: Props) {
  const loggedInUser = useSelector(userSelector)!;
  const {width} = useWindowDimmensions();
  const userToDisplay = user && !loggedIn ? user : loggedInUser;

  if (!userToDisplay) return null;
  const isInfluencer = userToDisplay.role === USER_ROLES.INFLUENCER;
  const isLoggedInUser = userToDisplay.id === loggedInUser.id;

  return (
    <React.Fragment>
    <div className="flex items-center flex-col md:flex-row">
      { userToDisplay.profilePictureUrl && <UserImage
        imgSrc={userToDisplay.profilePictureUrl}
      />}
      <div className="flex justify-center flex-col mx-2">
        {isInfluencer && (
          <>
            <InfluencerName influencer={userToDisplay} />
            {isLoggedInUser && <ShareInfluencerLink/>}
          </>
        )}
        {!isInfluencer && <ModeratorName user={userToDisplay} />}
      </div>
    </div>
    </React.Fragment>
  );
}
