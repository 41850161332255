import { API } from 'aws-amplify';
import { apiName } from 'config/api';

type Method = 'get' | 'post' | 'del' | 'patch' | 'put';

const createMethod = (methodName: Method) => (path: string, init = {}) =>
  API[methodName](apiName, path, init);

const restClient = {
  get: createMethod('get'),
  put: createMethod('put'),
  post: createMethod('post'),
  patch: createMethod('patch'),
  delete: createMethod('del'),
};

export default restClient;
