import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import UserDetails from 'components/user-details';
import { userSelector } from 'lib/redux';
import { useSelector } from 'react-redux';
import InfluencerDetailsForm from 'components/forms/influencer-details';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { useCallback, useState } from 'react';
import { useEffect } from 'react';
import { getResponseErrorMessage } from 'utils/common';
import { errorToast } from 'components/toast/toast';
import { LoaderOverlay } from 'components/loader';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';

export default function EditInfluencerPage() {
  const currentUser: any = useSelector(userSelector)!;
  const [influencerDetails, setInfluencerDetails] = useState<any | null>(null);
  const { influencerId } = useParams<{ influencerId: string }>();
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  const fetchInfluencerDetails = useCallback(async () => {
    try {
      setIsLoading(true);
      const data = await restClient.get(
        `${endpoints.superadmin.users}/${influencerId}`
      );
      setInfluencerDetails(data);
      setIsLoading(false);
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
      history.push('/users');
    }
  }, [influencerId]);

  useEffect(() => {
    fetchInfluencerDetails();
  }, [fetchInfluencerDetails]);

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <Layout>
        <Header>
          <UserDetails user={currentUser} />
        </Header>
        <Content>
          <h1 className="text-2xl md:text-4xl mb-4">Edit Influencer Details</h1>
          {influencerDetails && (
            <InfluencerDetailsForm
              initialInfluencerData={influencerDetails}
              editedBy="superadmin"
            />
          )}
        </Content>
      </Layout>
    </>
  );
}
