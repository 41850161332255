import * as React from 'react';
import MenuItem from './menu-item';
import Logo from './logo';
import {
  getMenuItemByRole,
  MenuItem as MenuItemType,
} from '../routes/routes-config';
import { useSelector } from 'react-redux';
import icons from 'components/icons';
import { userSelector } from 'lib/redux';

const getMenuItems = (items: Array<MenuItemType>, onlyIcon = false) => {
  return items.reduce(
    (items, item, key) => {
      if (item.noMenu) return items;
      const Element = <MenuItem item={item} onlyIcon={onlyIcon} key={key} />;
      if (item.alignBottom) {
        items.alignBottomItems.push(Element);
      } else {
        if(item.path !== '/logout') items.alignTopItems.push(Element);
      }
      return items;
    },
    { alignTopItems: [] as any[], alignBottomItems: [] as any[] }
  );
};

const FullScreenMenu = ({ items, onCloseClick }: any) => {
  const { alignTopItems, alignBottomItems } = getMenuItems(items);

  return (
    <div className="md:hidden fixed top-0 left-0 w-screen h-screen flex bg-white z-50">
      <div className="flex flex-col w-full relative">
        <div className="px-5 py-8">
          <Logo />
        </div>
        <div>{alignTopItems}</div>
        <div className="absolute bottom-0 left-0 w-full">
          {alignBottomItems}
        </div>
      </div>
      <div className="border-s border-border py-8 px-4">
        <span
          onClick={onCloseClick}
          className="inline-block w-8 h-8 cursor-pointer"
        >
          {icons.cross}
        </span>
      </div>
    </div>
  );
};

const SmallMenu = ({ items, onOpenClick }: any) => {
  const { alignTopItems, alignBottomItems } = getMenuItems(items, true);

  return (
    <div className="md:hidden flex flex-col w-full">
      <span
        className="inline-block w-full p-5 mt-4 cursor-pointer"
        onClick={onOpenClick}
      >
        {icons.menu}
      </span>
      <div className="mt-16">{alignTopItems}</div>
      <div className="absolute bottom-0 left-0 w-full">{alignBottomItems}</div>
    </div>
  );
};

const LargeMenu = ({ items }: any) => {
  const { alignTopItems, alignBottomItems } = getMenuItems(items);

  return (
    <div className="hidden md:block md:h-full relative">
      <div className="p-12 pb-10">
        <Logo />
      </div>
      <div>{alignTopItems}</div>
      <div className="absolute bottom-0 left-0 w-full">{alignBottomItems}</div>
    </div>
  );
};

const Menu = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const { role: userRole } = useSelector(userSelector)!;
  const items = getMenuItemByRole(userRole);

  return (
    <>
      <div className="fixed h-full w-16 md:w-64">
        <LargeMenu items={items} />
        {!isOpen && (
          <SmallMenu items={items} onOpenClick={() => setIsOpen(true)} />
        )}
      </div>
      {isOpen && (
        <FullScreenMenu items={items} onCloseClick={() => setIsOpen(false)} />
      )}
    </>
  );
};

export default Menu;
