import * as React from 'react';
import { userSelector } from 'lib/redux';
import { useSelector } from 'react-redux';
import Layout from 'components/layout';
import Header from 'components/header';
import Content from 'components/content';
import UserDetails from 'components/user-details';
import { useIntl } from 'react-intl';
import { HELP_CENTER_MAIL } from 'config/const';

const DashboardPage = () => {
  const user = useSelector(userSelector)!;
  const intl = useIntl();

  const getMessage = id => intl.formatMessage({ id: `helpCenter.${id}` });

  return (
    <Layout>
      <Header>
        <UserDetails user={user} />
      </Header>
      <Content>
        <div className="flex flex-col">
          <h1 className="mb-4">{getMessage('title')}</h1>
          <span>{getMessage('availabilityText')}</span>
          <span className="text-higlight font-bold mt-2">
            {HELP_CENTER_MAIL}
          </span>
        </div>
      </Content>
    </Layout>
  );
};

export default DashboardPage;
