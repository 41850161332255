import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import UserDetails from 'components/user-details';
import { List } from 'components/list';
import { SubmitButton, SmallButton } from 'components/button';
import TranslatedText from 'components/translated-text';
import { isModeratorSelector, userSelector } from 'lib/redux';
import Select from 'components/select';
import { useCountries, useLanguages } from 'utils/common';
import { LoaderOverlay } from 'components/loader';
import { updateUserSettings } from 'lib/redux/user/actions';
import ErrorBox from 'components/error-box';
import Modal from 'components/modal';
import ChangePassword from 'components/forms/change-password';
import { useIntl } from 'react-intl';
import PageTitle from 'components/PageTitle';


const SettingsPage = () => {
  const user = useSelector(userSelector);
  const isModerator = useSelector(isModeratorSelector);
  const dispatch = useDispatch();
  const intl = useIntl();
  const [userSettings, setUserSettings] = React.useState({ ...user });
  const [isTouched, setIsTouched] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [isModalOpen, setIsModalOpen] = React.useState(false);

  const countries = useCountries();
  const languages = useLanguages();

  const handleUserSettingChange = (update: any) => {
    setIsTouched(true);
    setUserSettings({ ...userSettings, ...update });
  };

  const saveUserSettings = async () => {
    try {
      setIsLoading(true);
      setError(null);
      await dispatch(updateUserSettings(userSettings as any));
    } catch (error:any) {
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  let settings = [
    <div className="flex justify-between items-center">
      <TranslatedText id="settings.language" className="me-2" />
      <Select
        options={languages}
        value={userSettings.language}
        onChange={language => handleUserSettingChange({ language })}
      />
    </div>,
    <div className="flex justify-between items-center">
      <TranslatedText id="settings.country" className="me-2" />
      <Select
        options={countries}
        value={userSettings.country}
        onChange={country => handleUserSettingChange({ country })}
      />
    </div>,
  ];

  isModerator &&
    settings.push(
      <div className="flex justify-between items-center">
        <TranslatedText id="account.password" className="me-2" />
        <SmallButton onClick={() => setIsModalOpen(true)} className="py-0">
          {intl.formatMessage({ id: 'account.change' })}
        </SmallButton>
      </div>
    );

  return (
    <Layout>
      <Header>
        <div className="flex items-center justify-between">
          <PageTitle title="Settings"/>
        </div>
      </Header>
      <Content>
        {isModalOpen && (
          <Modal onExit={() => setIsModalOpen(false)} withExitButton>
            <ChangePassword onSuccessCallback={() => setIsModalOpen(false)} />
          </Modal>
        )}
        {isLoading && <LoaderOverlay />}
        {error && <ErrorBox>{error}</ErrorBox>}
        <List items={settings} />
        <div className="mt-5 flex justify-end mx-10">
          <SubmitButton onClick={saveUserSettings} disabled={!isTouched}>
            <TranslatedText id={'account.save'} />
          </SubmitButton>
        </div>
      </Content>
    </Layout>
  );
};

export default SettingsPage;
