import * as React from 'react';
import Input from 'components/input';
import Textarea from 'components/textarea';
import Label from 'components/label';
import MediaUploadButton, {
  OnFileSelectedParam,
} from 'components/media-upload-button';
import MediaUploadButtonNoCrop from 'components/media-upload-button-no-crop';
import MediaDisplayer from 'components/media-displayer';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {collectionValidationSelector} from 'lib/redux/index';
import {validateCollectionInput} from 'lib/redux/formValidation/actions';
import { COVER_IMAGE_SIZE, TARGET_AUDIENCE } from 'config/const';
import mimeTypes from 'utils/mime-types';
import { getImageDimensions } from 'lib/file-upload';
import { checkIsCollectionReadOnly } from 'utils/common';
import { useForm } from 'react-hook-form';
import cn from 'classnames';

/** Used in case: user select file, change page */
export type CollectionFiles = {
  media: File | null;
  coverImage: File | null;
  isMediaImage: boolean;
};

export type CollectionStateChange = Partial<CollectionFiles> & {
  collection?: Partial<CollectionResponse>;
};

type Props = {
  collection: CollectionResponse;
  files: CollectionFiles;
  onChange: (param: CollectionStateChange) => void;
  onError: (error: string) => void;
};

const imageMimeTypes = mimeTypes.image.join(', ');
const acceptedMediaMime = [...mimeTypes.image, ...mimeTypes.video].join(', ');

const CollectionForm = ({ collection, onChange, onError, files }: Props) => {
  const intl = useIntl();
  const userRole = useSelector((state: any) => state.user.role);
  const formValidation = useSelector(collectionValidationSelector);
  const dispatch = useDispatch();
  const collectionState = collection && collection.state;

  const {formState: {errors}, control} = useForm();

  const isReadOnly = checkIsCollectionReadOnly(
    collectionState as CollectionState,
    userRole
  );

  const collectionMediaInput = React.useRef(null);
  const coverImageInput = React.useRef(null);

  const onMediaFileSelected = ({ file, isImage }: OnFileSelectedParam) => {
    onChange({
      media: file,
      isMediaImage: isImage,
    });
    return true;
  };

  const isValidCoverSize = async (file: File) => {
    let isValid = false;
    let error = '';

    try {
      const { width, height } = await getImageDimensions(file);
      isValid =
        width === COVER_IMAGE_SIZE.WIDTH && height === COVER_IMAGE_SIZE.HEIGHT;
    } catch (e) {
      error = intl.formatMessage({ id: 'upload.cannotLoadFile' });
    }

    return { isValid, error };
  };

  const onCoverSelected = async ({ file }: OnFileSelectedParam) => {
    const { error, isValid } = await isValidCoverSize(file);
    if (error && error.length) {
      onError(error);
      return false;
    }
    if (!isValid) {
      dispatch(validateCollectionInput(intl.formatMessage({ id: 'collection.wrongCoverImageSize' }), 'cover'))
      onError(intl.formatMessage({ id: 'collection.wrongCoverImageSize' }));
      return false;
    }
    onChange({ coverImage: file });
    return true;
  };

  const handleChangeAudience = (event: any) => {
    const { checked: value, name } = event.target;
    let newAudience = [...collection.audience];
    if (value && !newAudience.includes(name)) {
      newAudience = [...newAudience, name];
    }
    if (!value && newAudience.includes(name)) {
      newAudience = newAudience.filter(a => a !== name);
    }
    dispatch(validateCollectionInput('', 'audience'));
    onChange({ collection: { audience: newAudience }});
  };

  // Depending if from URL, or from File.
  const isVideo = files.media ? !files.isMediaImage : !!collection.videoUrl;

  const collectionMediaUrl = !!collection.imageUrl
    ? collection.imageUrl
    : collection.videoUrl;

  return (
    <div className="flex flex-col">
      <span className="mt-3">Required fields: *</span>
      <Input
        label={intl.formatMessage({ id: 'settings.country' })}
        value={intl.formatMessage({
          id: `common.countries.${collection.country.toLowerCase()}`,
          defaultMessage: 'N/A',
        })}
        readOnly
      />
      <Input
        label={intl.formatMessage({ id: 'collection.title.label' }) + " *"}
        placeholder={intl.formatMessage({
          id: 'collection.title.placeholder',
        })}
        value={collection.titleEnglish}
        maxLength={20}
        onChange={evt =>
          onChange({ collection: { titleEnglish: evt.target.value } })
        }
        readOnly={isReadOnly}
        name="titleEnglish"
      />
      <Input
        value={collection.titleArabic}
        label={intl.formatMessage({ id: 'collection.arabicTitle.label' })  + " *"}
        placeholder={intl.formatMessage({
          id: 'collection.arabicTitle.placeholder',
        })}
        maxLength={20}
        onChange={evt =>
          onChange({ collection: { titleArabic: evt.target.value } })
        }
        name="titleArabic"
        readOnly={isReadOnly}
      />
      <Textarea
        label={intl.formatMessage({ id: 'collection.desc.label' }) + " *"}
        placeholder={intl.formatMessage({
          id: 'collection.desc.placeholder',
        })}
        name='descriptionEnglish'
        value={collection.descriptionEnglish}
        defaultValue={collection.descriptionEnglish}
        maxLength={200}
        onChange={evt =>
          onChange({ collection: { descriptionEnglish: evt.target.value } })
        }
        readOnly={isReadOnly}
      ></Textarea>
      <Textarea
        label={intl.formatMessage({ id: 'collection.arabicDesc.label' }) + " *"}
        placeholder={intl.formatMessage({
          id: 'collection.arabicDesc.placeholder',
        })}
        name='descriptionArabic'
        value={collection.descriptionArabic}
        defaultValue={collection.descriptionArabic}
        maxLength={200}
        onChange={evt =>
          onChange({ collection: { descriptionArabic: evt.target.value } })
        }
        readOnly={isReadOnly}
      />
      <div className={cn('flex flex-col', {
        'text': !!formValidation['audience']
      })}>
        <Label>{intl.formatMessage({ id: 'collection.targetAudience' })}</Label>
        <div className="flex space-x-2">
          <label>
            <input
              type="checkbox"
              checked={collection.audience.includes(TARGET_AUDIENCE.WOMEN)}
              onChange={handleChangeAudience}
              name={TARGET_AUDIENCE.WOMEN}
              disabled={isReadOnly}
            />
            <span className="ms-2">
              <FormattedMessage id="collection.audience.women" />
            </span>
          </label>
        </div>
        <div className="flex space-x-2">
          <label>
            <input
              type="checkbox"
              checked={collection.audience.includes(TARGET_AUDIENCE.MEN)}
              onChange={handleChangeAudience}
              name={TARGET_AUDIENCE.MEN}
              disabled={isReadOnly}
            />
            <span className="ms-2">
              <FormattedMessage id="collection.audience.men" />
            </span>
          </label>
        </div>
        <div className="flex space-x-2">
          <label>
            <input
              type="checkbox"
              checked={collection.audience.includes(TARGET_AUDIENCE.KIDS)}
              onChange={handleChangeAudience}
              name={TARGET_AUDIENCE.KIDS}
              disabled={isReadOnly}
            />
            <span className="ms-2">
              <FormattedMessage id="collection.audience.kids" />
            </span>
          </label>
        </div>
        {!!formValidation['audience'] && <p className=" mt-2 w-max md:min-w-300 text-red-500 border rounded border-red-500 p-4 text-center">{formValidation['audience']}</p>}
      </div>
      {isReadOnly && (
        <>
          <div className="flex flex-col my-4">
            <Label>{intl.formatMessage({ id: 'collection.media' })}</Label>
            {!!collection.imageUrl && (
              <MediaDisplayer fileUrl={collection.imageUrl} isVideo={false} />
            )}
            {!!collection.videoUrl && (
              <MediaDisplayer fileUrl={collection.videoUrl} isVideo={true} />
            )}
          </div>
          <div className="flex flex-col my-4">
            <Label>
              {intl.formatMessage({ id: 'collection.coverImage.label' })}
            </Label>
            <MediaDisplayer
              fileUrl={collection.thumbnailUrl!}
              isVideo={false}
            />
          </div>
        </>
      )}
      {!isReadOnly && (
        <>
          <div className="flex flex-col my-4">
            <Label>{intl.formatMessage({ id: 'collection.media' })}</Label>
            <MediaUploadButtonNoCrop
              ref={collectionMediaInput}
              acceptedMime={acceptedMediaMime}
              fileUrl={collectionMediaUrl}
              initialFile={files.media}
              isVideo={isVideo}
              onFileSelected={onMediaFileSelected}
              onError={onError}
              name="media"  
            />
          </div>
          <div className="flex flex-col my-4">
            <Label>
              {intl.formatMessage({ id: 'collection.coverImage.label' })}
            </Label>
            <MediaUploadButton
              ref={coverImageInput}
              acceptedMime={imageMimeTypes}
              fileUrl={collection.thumbnailUrl}
              initialFile={files.coverImage}
              isVideo={false}
              onFileSelected={onCoverSelected}
              onError={onError}
              name="cover"
              picWidth={500}
              picHeight={700}
              toCrop={false}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default CollectionForm;
