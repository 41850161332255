import * as React from 'react';
import { AuthInput } from 'components/input';
import { AuthButton } from 'components/button';
import { useIntl, FormattedMessage } from 'react-intl';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import icons from 'components/icons';
import { useValidation, getError } from 'utils/validation';

type FormData = {
  username?: string;
  code: string;
  password: string;
  confirmPassword: string;
};

const useSchema = (requiredUsername?: boolean) => {
  const intl = useIntl();
  const { passwordValidation, requiredValidation } = useValidation();

  return React.useMemo(
    () =>
      yup.object({
        username: yup.string()[requiredUsername ? 'required' : 'optional'](),
        code: requiredValidation,
        password: passwordValidation,
        confirmPassword: requiredValidation.oneOf(
          [yup.ref('password')],
          intl.formatMessage({ id: 'validation.passwordsDoNotMatch' })
        ),
      }),
    [intl, passwordValidation, requiredValidation, requiredUsername]
  );
};

type Props = {
  username: string;
  onSubmit: (formData: FormData & { username: string }) => void;
  onFormExit: () => void;
  isLoading: boolean;
};

const ConfirmResetPasswordForm = ({
  username,
  onFormExit,
  onSubmit,
  isLoading,
}: Props) => {
  const intl = useIntl();
  const schema = useSchema(!username);
  const { handleSubmit, register, errors } = useForm<FormData>({
    resolver: yupResolver(schema),
  });

  const handleSubmitForm = (formData: FormData) => {
    onSubmit({ ...formData, username: username || formData.username || '' });
  };

  return (
    <div className="flex flex-col">
      <div className="text-center mt-5 mb-10">
        <h1 className="text-xl">
          <FormattedMessage id="auth.setupNewPassword" />
        </h1>
      </div>
      <form onSubmit={handleSubmit(handleSubmitForm)} className="space-y-4">
        {!username && (
          <AuthInput
            name="username"
            inputRef={register}
            placeholder={intl.formatMessage({
              id: 'auth.usernamePlaceholder',
            })}
            error={getError('code', errors)}
          />
        )}
        <AuthInput
          name="code"
          inputRef={register}
          placeholder={intl.formatMessage({
            id: 'changePassword.confirmationCode',
          })}
          error={getError('code', errors)}
        />
        <AuthInput
          type="password"
          name="password"
          inputRef={register}
          placeholder={intl.formatMessage({ id: 'changePassword.newPassword' })}
          error={getError('password', errors)}
        />
        <AuthInput
          type="password"
          name="confirmPassword"
          inputRef={register}
          placeholder={intl.formatMessage({
            id: 'changePassword.confirmNewPassword',
          })}
          error={getError('confirmPassword', errors)}
        />
        <div className="mt-8">
          <AuthButton type="submit" fullWidth>
            <FormattedMessage
              id={isLoading ? 'common.pleaseWait' : 'common.submit'}
            />
          </AuthButton>
        </div>
        <div className="text-center mt-6">
          <span
            onClick={() => onFormExit()}
            className="inline-block w-6 h-6 font-bold underline text-secondary cursor-pointer hover:text-primary transition-colors"
          >
            {icons.chevronLeft}
          </span>
        </div>
      </form>
    </div>
  );
};

export { ConfirmResetPasswordForm };
