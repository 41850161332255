import * as React from 'react';

/** Internet Explorer might have different values. */
const alternatives = {
    'Escape': ['Escape', 'Esc'],
};

/** To see possible values of 'key': https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/key */
export const useKeyPress = (key, onKeyPress) => {
    React.useEffect(() => {
        const possibleValues = alternatives[key] || [key];

        const callback = event => {
            if (event.isComposing || possibleValues.includes(event.key)) {
                onKeyPress();
            }
        }
        document.addEventListener('keydown', callback);

        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);
};
