import * as React from 'react';
import cn from 'classnames';
import { COLLECTION_STATE } from 'config/const';
import { useIntl } from 'react-intl';

type Props = {
  color: 'green' | 'orange' | 'white' | 'red' | 'yellow' ;
  children: React.ReactNode;
};

const Badge = ({ color, ...rest }: Props) => {
  const stateBadge = ['green', 'orange', 'red'].includes(color);

  return (
    <span
      className={cn(
        'border border-transparent rounded-full py-0 px-1 md:py-1 md:px-2 text-xxs md:text-xs shadow-md text-center flex items-center',
        {
          'text-secondary bg-white': color === 'white',
          'bg-green': color === 'green',
          'bg-error': color === 'red',
          'bg-higlight': color === 'orange',
          'text-white uppercase font-bold': stateBadge,
        }
      )}
      {...rest}
    />
  );
};

type StateBadgeProps = {
  state: CollectionState;
};

const checkIsPreLive = (state: CollectionState) =>
  [
    COLLECTION_STATE.DRAFT,
    COLLECTION_STATE.IN_REVIEW,
    COLLECTION_STATE.PUBLISHING,
  ].includes(state);

export const StateBadge = ({ state }: StateBadgeProps) => {
  const intl = useIntl();
  if (!state) {
    return null;
  }
  const isPreLive = checkIsPreLive(state);

  const stateToBadge = {
    [COLLECTION_STATE.DRAFT]: intl.formatMessage({
      id: 'collection.badge.draft',
    }),
    [COLLECTION_STATE.IN_REVIEW]: intl.formatMessage({
      id: 'collection.badge.inReview',
    }),
    [COLLECTION_STATE.PUBLISHING]: intl.formatMessage({
      id: 'collection.badge.publishing',
    }),
    [COLLECTION_STATE.LIVE]: intl.formatMessage({
      id: 'collection.badge.live',
    }),
  };

  return (
    <span
      className={cn('px-4 py-2 rounded-md uppercase border my-2 inline-block', {
        'text-higlight border-higlight': isPreLive,
        'text-green border-green': !isPreLive,
      })}
    >
      {stateToBadge[state]}
    </span>
  );
};

export default Badge;
