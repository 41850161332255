import { AuthButton as Button } from 'components/button';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { FormattedMessage } from 'react-intl';
import TextInput from 'components/inputs/text-input';
import { useValidation } from 'utils/validation';

type FormData = {
  INSTAGRAM: string;
};

const emptyFormData: FormData = {
  INSTAGRAM: '',
};

type Props = {
  initialData?: FormData;
  onFormSubmit: (data: { socialAccounts: FormData }) => Promise<void>;
};

export default function SocialForm({
  initialData = emptyFormData,
  onFormSubmit,
}: Props) {
  const { requiredValidation } = useValidation();

  const schema: yup.SchemaOf<FormData> = yup.object().shape({
    INSTAGRAM: requiredValidation,
  });

  const {
    formState: { errors, isDirty },
    handleSubmit,
    control,
  } = useForm<FormData>({
    defaultValues: initialData,
    resolver: yupResolver(schema),
  });

  return (
    <form
      onSubmit={handleSubmit(data => onFormSubmit({ socialAccounts: data }))}
    >
      <div className="flex flex-col items-start mt-4">
        <h3 className="mb-6 text-2xl font-semibold">Social links</h3>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-4 lg:gap-x-10">
          <Controller
            name="INSTAGRAM"
            control={control}
            render={(field) => {
              return (
                <TextInput
                  value={field.value}
                  onChange={field.onChange}
                  inputRef={field.ref}
                  label="Instagram"
                  error={errors.INSTAGRAM?.message}
                />
              );
            }}
          />
        </div>
        <div className="mt-8">
          <Button type="submit" disabled={!isDirty}>
            <FormattedMessage id="account.submitSocialForm" />
          </Button>
        </div>
      </div>
    </form>
  );
}
