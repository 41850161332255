import Modal from 'components/modal';

type Props = {
    text: string,
    onAccept: () => void;
    onReject: () => void;
};

const ConfirmationModal = ({text, onAccept, onReject} : Props) => {
    return (
        <div>
            <Modal onExit={onReject} withExitButton>
                <div className="flex flex-col items-center">
                    <p>{text}</p>
                    <div className="flex">
                        <button className="bg-green hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-5" onClick={onAccept}>
                            Yes
                        </button>
                        <button className="bg-red-400 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded m-5" onClick={onReject}>
                            No
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
        );
}

export default ConfirmationModal;