import * as React from 'react';
import { divide, path } from 'ramda';
import { useSelector, useDispatch } from 'react-redux';
import { store } from 'lib/redux/index';
import { validateCollectionInput } from 'lib/redux/formValidation/actions';
import { useIntl } from 'react-intl';
import Input from 'components/input';
import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import Button from 'components/button';
import UserDetails from 'components/user-details';
import {
  USER_ROLES,
  AVAILABLE_COUNTRIES,
  AVAILABLE_LANGUAGES,
  PROFILE_PIC_ASPECT_RATIO,
} from 'config/const';
import { endpoints } from 'config/api';
import {
  getResponseErrorMessage,
  useCountries,
  useLanguages,
} from 'utils/common';
import { fetchModeratorsSelectOptions } from 'utils/user';
import mimeTypes from 'utils/mime-types';
import restClient from 'lib/rest-client';
import { getImageDimensions } from 'lib/file-upload';
import Select from 'components/select';
import ErrorBox from 'components/error-box';
import { userSelector } from 'lib/redux';
import { LoaderOverlay } from 'components/loader';
import MediaUploadButton, {
  OnFileSelectedParam,
} from 'components/media-upload-button';
import PageTitle from 'components/PageTitle';

const imageMimeTypes = mimeTypes.image.join(', ');

const validatePayload = (payload, isCreatingInfluencer) => {
  const optionalFields = isCreatingInfluencer ? [] : ['profilePictureUrl'];
  let error = false;
  Object.entries(payload).forEach(([key, value] : [string, any]) => {
    if (optionalFields.includes(key)) {
      return;
    }

    if (!value) {
      store.dispatch(validateCollectionInput('Field is required', key));
      // throw new Error(`All the fields are required`);
      error = true;
    }
    if (Array.isArray(value) && !value.length) {
      // throw new Error(`${key} must contain at least one value`);
      error = true;

    }
    switch(key){
      case "email":
        if(!value.match(/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/)){
          store.dispatch(validateCollectionInput('Email is invalid', 'email'));
          // throw new Error('Email input is invalid');
          error = true;

        }
        break;
      case "phone":
        if(!value.match(/^\+\d{1,3}\d{1,12}$/)){
          store.dispatch(validateCollectionInput('Phone is invalid', 'phone'));
          // throw new Error('Phone input is invalid');
          error = true;

        }
        break;
      case 'socialAccounts':
        if(!value['INSTAGRAM']){
          store.dispatch(validateCollectionInput('Instagram link is required', 'instagram'));
          // throw new Error(`All the fields are required`);
          error = true;

        }
        break;
      default:
        return
    }
  });
  if(error) throw new Error(`Some fields are not valid`);
};

const initialPayload = {
  nameEnglish: '',
  nameArabic: '',
  familyNameEnglish: '',
  familyNameArabic: '',
  influencerNameArabic: '',
  influencerNameEnglish: '',
  email: '',
  phone: '',
  profilePictureUrl: '',
  country: AVAILABLE_COUNTRIES.QATAR,
  language: AVAILABLE_LANGUAGES.ENGLISH,
  role: USER_ROLES.INFLUENCER,
  socialAccounts: {
    INSTAGRAM: ''
  },
  accountModeratorId: '',
};

const Label = ({ children }) => (
  <span className="text-secondary">{children}</span>
);

const roleOptions = [
  { value: USER_ROLES.INFLUENCER, name: 'Influencer' },
  { value: USER_ROLES.MODERATOR, name: 'Moderator' },
];

const CreateUserPage = () => {
  const intl = useIntl();
  const currentUser = useSelector(userSelector);
  const dispatch = useDispatch();
  const countries = useCountries();
  const languages = useLanguages();
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);
  const [message, setMessage] = React.useState<string | null>(null);
  const [payload, setPayload] = React.useReducer(
    (state, update) => ({ ...state, ...update }),
    initialPayload
  );
  const [moderatorsToAssign, setModeratorsToAssign] = React.useState<
    Array<any>
  >([]);
  const isInfluencer = payload.role === USER_ROLES.INFLUENCER;
  const mediaUploadButton = React.useRef<any>(null);

  const onRoleChange = (role: UserRole) => {
    setPayload({
      role,
      socialAccounts: [],
      influencerNameArabic: '',
      influencerNameEnglish: '',
      accountModeratorId: '',
    });
  };

  const createOnChange = (name: string) => evt =>
    setPayload({ [name]: evt.target.value });

  const onSocialAccountChange = evt => {
    const socialAccounts = evt.target.value;
    setPayload({ socialAccounts: {INSTAGRAM: socialAccounts} });
  };

  const onSubmit = async () => {
    try {
      setError(null);
      setMessage(null);
      setIsLoading(true);
      let body = { ...payload };
      if (!isInfluencer) {
        delete body.socialAccounts;
        delete body.influencerNameArabic;
        delete body.influencerNameEnglish;
        delete body.accountModeratorId;
      }
      const imageUrl = await mediaUploadButton.current.uploadFile();
      body = {
        ...body,
        profilePictureUrl: imageUrl,
      };
      validatePayload(body, isInfluencer);
      const { userId } = await restClient.post(endpoints.createUser, {
        body,
      });
      setMessage(`User ${userId} has been created.`);
      setPayload(initialPayload);
      mediaUploadButton.current.clear();
    } catch (error) {
      const message = getResponseErrorMessage(error);
      setError(message);
    } finally {
      setIsLoading(false);
    }
  };

  const isValidAccountPic = async (file: File) => {
    let isValid = false;
    let error = '';

    try {
      const { width, height } = await getImageDimensions(file);
      const aspectRatio = width / height;
      isValid =
        aspectRatio >= PROFILE_PIC_ASPECT_RATIO.MIN &&
        aspectRatio <= PROFILE_PIC_ASPECT_RATIO.MAX;
    } catch (e) {
      error = intl.formatMessage({ id: 'upload.cannotLoadFile' });
    }

    return { isValid, error };
  };

  const onFileSelected = async ({ file }: OnFileSelectedParam) => {
    const { isValid, error } = await isValidAccountPic(file);
    let nextError: string | null = null; // By default: reset error which could be caused by previously selected file, being too large
    if (error.length) {
      nextError = error;
    } else if (!isValid) {
      dispatch(validateCollectionInput(intl.formatMessage({
        id: 'account.profilePic.invalidAspect',
      }),'profilePictureUrl'));
      nextError = intl.formatMessage({
        id: 'account.profilePic.invalidAspect',
      });
    }
    setError(nextError);
    return isValid && !error.length;
  };

  const onImageError = (error: string) => {
    setError(error);
  };

  const fetchModerators = async () => {
    try {
      const moderatorsToAssign = await fetchModeratorsSelectOptions();
      if (!moderatorsToAssign.length) {
        throw new Error('Moderators list is empty');
      }
      setModeratorsToAssign(moderatorsToAssign);
      setPayload({
        accountModeratorId: path([0, 'value'], moderatorsToAssign),
      });
    } catch (error) {
      setError(getResponseErrorMessage(error));
    }
  };

  React.useEffect(() => {
    fetchModerators();
  }, []);

  return (
    <Layout>
      <Header>
        <PageTitle title="Create User"/>
      </Header>
      <Content>
        <div className="flex justify-end">
          <Button
            onClick={onSubmit}
            disabled={isLoading}
            className="mt-4 md:m-0"
          >
            Create user
          </Button>
        </div>
        <div className="md:w-6/12">
          <div>
          {error && <ErrorBox>{error}</ErrorBox>}
          </div>
          {isLoading && <LoaderOverlay />}
          {message && (
            <div className="border-2 border-green p-4 font-bold mb-8 text-green">
              {message}
            </div>
          )}
          <div className="flex flex-col my-4">
            <Label>Role</Label>
            <Select
              onChange={onRoleChange}
              value={payload.role}
              options={roleOptions}
            />
          </div>
          <Input
            label="Name - English"
            value={payload.nameEnglish}
            onChange={createOnChange('nameEnglish')}
            fullWidth
            name="nameEnglish"
            maxLength={64}
          />
          <Input
            label="Name - Arabic"
            value={payload.nameArabic}
            onChange={createOnChange('nameArabic')}
            fullWidth
            name="nameArabic"
            maxLength={64}
          />
          <Input
            label="Family name - English"
            value={payload.familyNameEnglish}
            onChange={createOnChange('familyNameEnglish')}
            fullWidth
            name="familyNameEnglish"
            maxLength={64}
          />
          <Input
            label="Family name - Arabic"
            value={payload.familyNameArabic}
            onChange={createOnChange('familyNameArabic')}
            fullWidth
            name="familyNameArabic"
            maxLength={64}
          />
          {isInfluencer && (
            <Input
              label="Influencer name - English"
              value={payload.influencerNameEnglish}
              onChange={createOnChange('influencerNameEnglish')}
              fullWidth
              name="influencerNameEnglish"
              maxLength={64}
            />
          )}
          {isInfluencer && (
            <Input
              label="Influencer name - Arabic"
              value={payload.influencerNameArabic}
              onChange={createOnChange('influencerNameArabic')}
              fullWidth
              name="influencerNameArabic"
              maxLength={64}
            />
          )}
          {isInfluencer && (
            <div className="flex flex-col">
              <Label>Assigned moderator</Label>
              <Select
                onChange={accountModeratorId =>
                  setPayload({ accountModeratorId })
                }
                options={moderatorsToAssign}
                value={payload.accountModeratorId}
              />
            </div>
          )}
          <Input
            label="E-mail"
            value={payload.email}
            onChange={createOnChange('email')}
            fullWidth
            name="email"
          />
            <Input
              onChange={createOnChange('phone')}
              value={payload.phone}
              label="Phone"
              fullWidth
              placeholder="e.g +97128115555"
              isTooltip={true}
              maxLength={16}
              name="phone"
              tooltipText={<div className="flex flex-col"><span>Accepted phone number format: +DDDNNNNNN</span><span>DDD : up to 3 country code digits</span><span>NNNNNN : up to 12 number digits</span></div>}
            />
          <div className="flex flex-col my-4">
            <Label>
              Profile picture - aspect ratio 16:9 (max-size: 3mb){' '}
              {!isInfluencer && ' (optional)'}
            </Label>
            <MediaUploadButton
              acceptedMime={imageMimeTypes}
              onFileSelected={onFileSelected}
              fileUrl={payload.profilePictureUrl}
              isVideo={false}
              initialFile={null}
              onError={onImageError}
              ref={mediaUploadButton}
              name={'profilePictureUrl'}
              picAspectX={16}
              picAspectY={9}
              toCrop={true}
            />
          </div>
          <div className="flex flex-col my-4">
            <Label>Country</Label>
            <Select
              onChange={country => setPayload({ country })}
              options={countries}
              value={payload.country}
            />
          </div>
          <div className="flex flex-col my-4">
            <Label>Language</Label>
            <Select
              onChange={language => setPayload({ language })}
              options={languages}
              value={payload.language}
            />
          </div>
          {isInfluencer && (
            <Input
              value={
                payload.socialAccounts.INSTAGRAM
              }
              label="Instagram"
              onChange={onSocialAccountChange}
              fullWidth
              name="instagram"
            />
          )}
        </div>
      </Content>
    </Layout>
  );
};

export default CreateUserPage;
