export default {
  'common.companyName': '6thStreet',
  'common.countries.qat': 'Qatar',
  'common.countries.are': 'United Arab Emirates',
  'common.countries.sau': 'Saudi Arabia',
  'common.countries.kwt': 'Kuwait',
  'common.countries.omn': 'Oman',
  'common.countries.bhr': 'Bahrain',
  'common.countries.egy' : "Egypt",
  'common.languages.en': 'English',
  'common.languages.ar': 'Arabic',
  'common.selectOption': 'Select option',
  'common.retry': 'Retry',
  'common.confirm': 'Confirm',
  'common.cancel': 'Cancel',
  'common.submit': 'Submit',
  'common.pleaseWait': 'Please wait...',
  'common.rejectReason': 'Reject reason: ',
  'common.duplicate': 'Duplicate',
  'common.genericError': 'Something went wrong',
  'common.pageNotFound': 'Page not found',
  'common.refresh': 'Refresh',
  'common.pinCollection': 'Pin collection',
  'common.unpinCollection': 'Unpin collection',
  'validation.fieldRequired': 'This field is required',
  'validation.passwordsDoNotMatch': 'Passwords do not match',
  'validation.requireNumbers': 'Require numbers',
  'validation.requireLowerCase': 'Require lowercase letters',
  'validation.requireUpperCase': 'Require uppercase letters',
  'validation.requireSpecialChars': 'Require special characters',
  'validation.minChars': 'Password should have min {length} characters',
  'labels.selectCountry': 'Select country',
  'auth.welcomeBack': 'Welcome Back',
  'auth.usernamePlaceholder': 'Email or Mobile#',
  'auth.passwordPlaceholder': 'Password',
  'auth.signIn': 'Sign In',
  'auth.requestNewPassword': 'Request new password',
  'auth.setupNewPassword': 'Setup new password',
  'auth.resetPassword': 'Reset password',
  'auth.fetchProfileFailureMsg': 'Failed to fetch user profile',
  'auth.forgotPassword': 'Forgot password?',
  'auth.receivedConfirmationCode': 'Received confirmation code?',
  'auth.passwordUpdated': 'Password has been changed',
  'auth.restMailSent': 'Email with Confirmation Code has been sent',
  'menu.dashboard': 'Dashboard',
  'menu.collections': 'Collections',
  'menu.featuredProducts': 'Featured Products',
  'menu.account': 'Account',
  'menu.settings': 'Settings',
  'menu.synchronization': 'Synchronization',
  'menu.logout': 'Logout',
  'menu.helpCenter': 'Help Center',
  'menu.createUser': 'Create user',
  'menu.assignModerator': 'Assign moderator',
  'menu.influencers': 'Influencers',
  'menu.pendingApprovals': 'Pending approvals',
  'menu.influencersOrder': 'Influencers Order',
  'menu.influencerDashboard' : 'Influencer Performance',
  'settings.language': 'Language',
  'settings.country': 'Country',
  'settings.english': 'English / اللغة الانجليزية',
  'settings.arabic': 'Arabic / العربية',
  'account.name': 'Name',
  'account.nameEnglish': 'Name - English',
  'account.nameArabic': 'Name - Arabic',
  'account.familyName': 'Family name',
  'account.familyNameEnglish': 'Family name - English',
  'account.familyNameArabic': 'Family name - Arabic',
  'account.influencerName': 'Influencer Name',
  'account.influencerNameEnglish': 'Influencer name - English',
  'account.influencerNameArabic': 'Influencer name - Arabic',
  'account.phone': 'Phone',
  'account.email': 'E-Mail',
  'account.password': 'Password',
  'account.change': 'Change',
  'account.profilePic': 'Profile Picture (16:9)',
  'account.profilePic.invalidAspect':
    'Profile picture should have aspect ratio 16:9',
  'account.save': 'Save',
  'account.saving': 'Saving...',
  'account.shareStoreLink': 'Share store',
  'account.storeLink': 'Store link',
  'account.storeLinkCopied': 'Store Link has been copied to clipboard',
  'account.collectionLinkCopied' : 'Collection Link has been copied to clipboard',
  'account.storelinkNotAvailable': 'Store Link not available',
  'account.submitInfluencerForm': 'Save Influencer Details',
  'account.submitAddressForm': 'Save Address Details',
  'account.submitPassportForm': 'Save Passport Details',
  'account.submitBankAccountForm': 'Save Bank Account Details',
  'account.submitContractForm': 'Save Contract Details',
  'account.submitSocialForm': 'Save Social Accounts Details',
  'account.updateSuccess': 'Influencer details have been updated',
  'changePassword.currentPassword': 'Current password',
  'changePassword.newPassword': 'New password',
  'changePassword.confirmNewPassword': 'Confirm new password',
  'changePassword.passwordsDoNotMatch': 'Passwords do not match',
  'changePassword.change': 'Change',
  'changePassword.changing': 'Changing...',
  'changePassword.confirmationCode': 'Confirmation code',
  'collection.title.label': 'Collection Title (max 20 char)',
  'collection.title.placeholder': 'Collection Title',
  'collection.arabicTitle.label': 'Arabic Collection Title',
  'collection.arabicTitle.placeholder': 'Arabic Collection Title',
  'collection.liveCollections': 'Live collections',
  'collection.desc.label': 'Collection Description (max 200 char)',
  'collection.desc.placeholder': 'Type a description here...',
  'collection.arabicDesc.label': 'Arabic Collection Description',
  'collection.arabicDesc.placeholder': 'Type a description here...',
  'collection.saveButton.text': 'Save & Submit',
  'collection.saveDraftButton.text': 'Save',
  'collection.submitButton.text': 'Submit',
  'collection.updateButton.text': 'Update',
  'collection.withdrawButton.text': 'Withdraw',
  'collection.deleteButton.text': 'Delete',
  'collection.rejectButton.text': 'Reject',
  'collection.approveButton.text': 'Approve',
  'collection.coverImage.label': 'Cover Image 500px/700px (.jpg .png)',
  'collection.collections': 'Collections',
  'collection.tabs.info': 'Info',
  'collection.tabs.products': 'Products',
  'collection.media': 'Media file: Image(png, jpeg), Video(mp4, 3gpp, 3gpp2)',
  'collection.feedback': 'Feedback',
  'collection.arabicFeedback': 'Arabic Feedback',
  'collection.sendFeedback': 'Send Feedback',
  'collection.noCollections': 'No collections',
  'products.search.placeholder': 'Search by name, brand, SKU',
  'products.dragAndDrop': 'Drag & Drop to order the products',
  'products.productsCountAdded': `{count} {count, plural, one {Product} other {Products}} added`,
  'collection.products.addtoCollection': 'Add to collection',
  'collection.influencer': 'Influencer',
  'collection.addNew': 'Add New',
  'collection.targetAudience': 'Target Audience',
  'collection.audience.kids': 'Kids',
  'collection.audience.men': 'Men',
  'collection.audience.women': 'Women',
  'collection.duplicate.selectTargetCountry': 'Select target country:',
  'upload.tooLargeFile': 'Selected file exceeds maximum allowed file size',
  'upload.codecNotSupported': 'Format not supported by browser',
  'upload.url': 'URL: ',
  'upload.fileName': 'File name: ',
  'upload.cannotLoadFile': 'Cannot load file',
  'collection.wrongCoverImageSize':
    'Wrong cover image size - should be 500px X 700px (width x height)',
  'collection.badge.draft': 'Draft',
  'collection.badge.inReview': 'Pending',
  'collection.badge.live': 'Live',
  'collection.badge.rejected': 'Rejected',
  'collection.badge.publishing': 'Publishing',
  'featuredProducts.noProducts': 'No products',
  'featuredProducts.emptyFPList': 'No featured products',
  'featuredProducts.title': 'Featured products',
  'featuredProducts.status': 'Featured products status:',
  'dashboard.overview': 'Overview',
  'dashboard.liveCollections': 'Live collections',
  'dashboard.draftCollections': 'Draft collections',
  'dashboard.collections': 'Collections',
  'helpCenter.title': 'Help Center',
  'helpCenter.availabilityText': 'We are available all days from: 9am - 9pm',
  'influencers.emptyList': 'No influencers',
  'influencers.create': 'Create',
  'influencers.featuredProducts': 'Featured Products',
  'influencers.collection': 'Collection',
  'influencers.enableCollections': 'Enable Collections',
  'influencers.disableCollections': 'Disable Collections',
  'influencers.confirmEnableCollectionsMessage':
    'Enable collections for {name}?',
  'influencers.confirmDisableCollectionsMessage':
    'Disable collections for {name}?',
  'influencers.displayingOutOf': 'Displaying {num} influencers out of {total}.',
  'influencers.pageNum': 'Page {pageNum}',
  'influencers.changeModerator': 'Change moderator',
  'influencers.searchForInfluencer': 'Search for influencer',
  'influencers.viewCollections': 'View collections',
  'influencers.viewFP': 'View Featured Products',
  'loader.text': 'Please wait this might take a while',
  'synchronization.createdAt': 'Created at',
  'synchronization.status': 'Status',
  'synchronization.listEmpty': 'No synchronization data is available',
  'synchronization.autoRefresh': 'Automatically refresh list',
  'synchronization.synchronize': 'Run Synchronization',
};
