import * as React from 'react';
import { useIntl } from 'react-intl';
import { FILE_UPLOAD_LIMIT } from 'config/const';
import { uploadFileToPublicS3, getLinkForFile } from 'lib/file-upload';
import useEnlargeMedia from 'lib/use-enlarge-media';
import { useKeyPress } from 'lib/key-press';
import mimeTypes from 'utils/mime-types';
import icons from 'components/icons';
import Loader from 'components/loader';
import FileInput from 'components/file-input';
import { useSelector, useDispatch } from 'react-redux';
import { collectionValidationSelector } from 'lib/redux/index';
import { validateCollectionInput } from 'lib/redux/formValidation/actions';

export type OnFileSelectedParam = {
  file: File; //< File is provided in case if upload should be provided externally
  isImage: boolean;
};

type Props = {
  acceptedMime: string; //< Multiple can be separated by ", "
  fileUrl?: string; //< Set to initial image or video
  isVideo: boolean; //< True if initial media file is video
  initialFile: File | null; //< Used when user selected file, then button was destroyed, then recreated
  onError: (message: string) => void;
  onFileSelected?: (param: OnFileSelectedParam) => boolean | Promise<boolean>; //< \return true if file is OK and should be used, false otherwise
  name?: string;
  changeSize?: boolean;
};

const MediaUploadButton = React.forwardRef(
  (
    {
      acceptedMime,
      onFileSelected,
      fileUrl,
      isVideo,
      onError,
      initialFile,
      name
    }: Props,
    ref
  ) => {
    const intl = useIntl();
    const [file, setFile] = React.useState<File | null>(null);
    const [previewUrl, setPreviewUrl] = React.useState<string | null>(null);
    const [isImage, setIsImage] = React.useState(true);
    const [isFormatSupported, setIsFormatSupported] = React.useState(true);
    const dispatch = useDispatch()
    const formValidation = useSelector(collectionValidationSelector);

    const updateThumbnail = async (newFile: File) => {
      setPreviewUrl(null);
      setFile(newFile);
      const url = await getLinkForFile(newFile);
      setPreviewUrl(url);
      if(name) dispatch(validateCollectionInput('', name))
    };

    React.useEffect(() => {
      if (initialFile) {
        updateThumbnail(initialFile).then(() => {
          setIsImage(!isVideo);
        });
      } else if (fileUrl) {
        setPreviewUrl(fileUrl);
        setIsImage(!isVideo);
      }
    }, []);

    const onFileInputChanged = async (selectedFile: any) => {
      setIsFormatSupported(true); // Removing info.
      onError(''); // Removing info.
      const mimeType = selectedFile.type;
      // Verification, if selected mime type is correct, is passed onto browser.
      const isImageSelected = mimeTypes.image.includes(mimeType);
      const maxFileSize = isImageSelected
        ? FILE_UPLOAD_LIMIT.IMAGE
        : FILE_UPLOAD_LIMIT.VIDEO;
      if (selectedFile.size > maxFileSize) {
        name && dispatch(validateCollectionInput(`${intl.formatMessage({ id: 'upload.tooLargeFile' })} (max=3mb)`, name));
        onError(intl.formatMessage({ id: 'upload.tooLargeFile' }));
        return;
      }

      if (onFileSelected) {
        const isValid = await onFileSelected({
          file: selectedFile,
          isImage: isImageSelected,
        });
        if (!isValid) {
          return;
        }
      }

      if (isImageSelected !== isImage) {
        setIsImage(isImageSelected);
      }
      await updateThumbnail(selectedFile);
    };

    // As for MVP, assuming that all video problems are caused by not supported format. There's no possibility to read error source, as browsers
    // usually do not give same error text in 'event.target.error'.
    const onVideoError = () => {
      setIsFormatSupported(false);
    };

    React.useImperativeHandle(ref, () => ({
      // If file should be uploaded is controlled from parent element. It is due to fact, that user might change his mind, thus creating not needed
      // file (and increasing S3 cost).
      uploadFile: async () => {
        if (file) {
          return await uploadFileToPublicS3(file, isImage);
        }
        return fileUrl;
      },
      clear: () => {
        setFile(null);
        setPreviewUrl(null);
      },
    }));

    const {
      enlargePreview,
      onEnlargeClose,
      isEnlarged,
      renderEnlargedPreview,
    } = useEnlargeMedia(isImage, previewUrl as any, onVideoError);

    useKeyPress('Escape', onEnlargeClose);

    const isThumbnailFromFile = initialFile !== null;
    const idOfFileLabel = isThumbnailFromFile
      ? 'upload.fileName'
      : 'upload.url';
    const fileDescLabel = intl.formatMessage({ id: idOfFileLabel });
    const fileDescValue =
      isThumbnailFromFile && initialFile ? initialFile.name : fileUrl;

    // Requirements: if thumbnail is from selected file: show name of file.
    // If thumbnail is from URL and thumbnail is of video and browser doesn't support video format: show URL.
    // Otherwise, don't show URL nor name of file.
    return (
      <>
        {isEnlarged && renderEnlargedPreview()}
        {previewUrl !== null &&
          !isThumbnailFromFile &&
          !isImage &&
          !isFormatSupported && (
            <a
              href={fileUrl}
              target="_blank"
              className="text-secondary text-xs"
            >
              {fileDescLabel}
              {fileDescValue}
            </a>
          )}
        {previewUrl !== null && isThumbnailFromFile && (
          <span className="text-secondary text-xs">
            {fileDescLabel}
            {fileDescValue}
          </span>
        )}
        <div className="button-add">
          <div className="w-full h-full flex items-center justify-center relative rounded-md overflow-hidden">
            {previewUrl === null && file === null && (
              <FileInput
                className="absolute top-0 left-0 w-full h-full cursor-pointer"
                acceptedMime={acceptedMime}
                onFileSelected={onFileInputChanged}
              >
                <span className="w-16 h-16 text-green outline-none focus:outline-none">
                  {icons.plus}
                </span>
              </FileInput>
            )}
            {previewUrl === null && file !== null && <Loader />}
            {previewUrl !== null && !isFormatSupported && (
              <>
                <span className="text-base text-green outline-none focus:outline-none select-none">
                  {intl.formatMessage({ id: 'upload.codecNotSupported' })}
                </span>
                <FileInput
                  className="absolute right-0 bottom-0 w-16 h-16"
                  acceptedMime={acceptedMime}
                  onFileSelected={onFileInputChanged}
                >
                  <span className="w-16 h-16 absolute right-0 bottom-0 text-white font-bold cursor-pointer rounded-md bg-opacity-50 bg-green">
                    {icons.plus}
                  </span>
                </FileInput>
              </>
            )}
            {previewUrl !== null && isFormatSupported && (
              <div className="outline-none focus:outline-none w-full h-full">
                {isImage && (
                  <img
                    src={previewUrl}
                    className="w-full h-full object-cover"
                  />
                )}
                {!isImage && (
                  <video
                    className="w-full h-full object-cover"
                    preload="metadata"
                    onError={onVideoError}
                  >
                    <source src={previewUrl} />
                  </video>
                )}
                <FileInput
                  className="absolute right-0 bottom-0 w-16 h-16"
                  acceptedMime={acceptedMime}
                  onFileSelected={onFileInputChanged}
                >
                  <span className="w-16 h-16 absolute right-0 bottom-0 text-white font-bold cursor-pointer rounded-md bg-opacity-50 bg-green">
                    {icons.plus}
                  </span>
                </FileInput>
                <div
                  onClick={enlargePreview}
                  className="w-16 h-16 absolute left-0 bottom-0 text-white font-bold cursor-pointer rounded-md bg-opacity-50 bg-green"
                >
                  {icons.search}
                </div>
              </div>
            )}
          </div>
        </div>
        {name && !!formValidation[name] && <p className=" mt-2 w-max md:min-w-300 text-red-500 border rounded border-red-500 p-4 text-center">{formValidation[name]}</p>}
      </>
    );
  }
);

export default MediaUploadButton;