import { propEq } from 'ramda';
import { Option as OptionType } from 'components/select';
import { endpoints } from 'config/api';
import { USER_ROLES } from 'config/const';
import restClient from 'lib/rest-client';
import { formatUserName } from './common';

export const hasDisabledCollections = (influencer: User) =>
  propEq('collectionDisplayEnabled', false, influencer);

export const fetchModeratorsSelectOptions = async () => {
  const limit = 50;

  let moderators: Array<any> = [];
  let hasMore = true;
  let page = 0;
  while (hasMore) {
    const path = `${endpoints.adminUsers}?type=${USER_ROLES.MODERATOR}&limit=${limit}&page=${page}`;
    const { items, next } = await restClient.get(path);
    if (next) {
      page += 1;
    } else {
      hasMore = false;
    }
    moderators = [...moderators, ...mapDataToSelectOptions(items)];
  }
  return moderators;
};

const mapDataToSelectOptions = (data: Array<User>): Array<OptionType> =>
  data.map(user => ({
    value: user.id,
    name: formatUserName(user),
  }));
