import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { compose } from 'ramda';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import user, { selectors as userSelectors, UserState } from './user/reducer';
import lang, { selectors as langSelectors } from './lang/reducer';
import collectionValidation from './formValidation/reducer';
import trendingFilter from './trendingUserFilter/reducer';
import collectionsOrderFilter from './collectionsOrderFilter/reducer';
import collections, {
  selectors as collectionsSelectors,
} from 'lib/redux/collections/reducer';

const middlewares = [thunk];

const rootReducer = combineReducers({
  user,
  lang,
  collections,
  trendingFilter,
  collectionValidation,
  collectionsOrderFilter,
});

export const store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(...middlewares))
);

export type RootState = ReturnType<typeof rootReducer>;

// lang selector
export const langSelector = (state: RootState): Language => state.lang;
export const isArabicSelector = compose(langSelectors.isArabic, langSelector);

//trending filter selector
export const trendingFilterSelector = (state: RootState) => state.trendingFilter;

// user selectors
export const userSelector = (state: RootState): UserState => state.user;
export const isModeratorSelector = compose(
  userSelectors.isModeratorSelector,
  userSelector
);
export const isInfluencerSelector = compose(
  userSelectors.isInfluencerSelector,
  userSelector
);

// collection validation selector
export const collectionValidationSelector = (state: RootState) => state.collectionValidation;

// collections order filter selector
export const collectionOrderFilterSelector = state => state.collectionsOrderFilter;

// collections selectors
export const collectionsSelector = (state: RootState) => state.collections;
export const liveCollectionsCountSelector = compose(
  collectionsSelectors.liveCollectionsCountSelector,
  collectionsSelector
);
export const preLiveCollectionsCountSelector = compose(
  collectionsSelectors.preLiveCollectionsCountSelector,
  collectionsSelector
);
