import * as React from 'react';
import { useSelector } from 'react-redux';
import { userSelector } from 'lib/redux';
import icons from './icons';
import { errorToast, successToast } from './toast/toast';
import Modal from './modal';
import Heading from './heading';
import { FormattedMessage } from 'react-intl';

export default function ShareCollectionLink({collectionLink}) {
  const [isCreatorOpen, setIsCreatorOpen] = React.useState(false);
  const linkRef = React.useRef<HTMLInputElement>();

  const copyLinkToClipboard = () => {
    if (!linkRef.current) {
      errorToast(<FormattedMessage id="account.storelinkNotAvailable" />);
      return;
    }

    linkRef.current.select();
    document.execCommand('copy');
    successToast(<FormattedMessage id="account.collectionLinkCopied" />);
  };

  const openInNewTab = () => {
    if (!linkRef.current) {
      errorToast(<FormattedMessage id="account.storelinkNotAvailable" />);
      return;
    }

    window.open(linkRef.current.value, '_blank');
  };

  if (!collectionLink) return null;

  return (
    <>
      <div
        onClick={() => setIsCreatorOpen(true)}
        className=" flex p-5 items-center text-secondary hover:text-higlight transition-colors duration-300 cursor-pointer"
      >
        <span className="w-6 h-6 inline-block">{icons.share}</span>
        <span className="ms-2 text-md">
          Share collection
        </span>
      </div>
      {isCreatorOpen && (
        <Modal withExitButton onExit={() => setIsCreatorOpen(false)}>
          <Heading>
            Collection Link
          </Heading>
          <div className="mt-2 md:mt-4 flex items-center space-x-2">
            <input
              ref={linkRef as any}
              value={collectionLink}
              readOnly
              className="p-2 border border-border text-secondary w-full"
            />
            <span
              onClick={() => copyLinkToClipboard()}
              className="cursor-pointer text-higlight"
            >
              {icons.duplicate}
            </span>
            <span
              onClick={() => openInNewTab()}
              className="cursor-pointer text-higlight"
            >
              {icons.externalLink}
            </span>
          </div>
        </Modal>
      )}
    </>
  );
}