import { useEffect } from 'react';
import { useHistory } from 'react-router';
import { useLocation } from 'react-router-dom';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowLeft} from '@fortawesome/free-solid-svg-icons';

const PageTitle = ({title} : {title: string}) => {
    const history = useHistory();
    const { pathname: path } = useLocation();
    return (
        <div className="flex items-center p-3 border-t md:border-t-0" style={{borderColor: "lightgray"}}>
            {path !== '/' && <FontAwesomeIcon icon={faArrowLeft} size="lg" onClick={() =>{
                if (document.referrer) history.goBack();
                else if (path.search('influencer-collections') > 0) history.goBack();
                else if (path.search('featured-edit') > 0) history.goBack();
                else history.push('/');
            }}/>}
            <span className="text-xl md:text-3xl text-extrabold tracking-wide ml-5">
                {title}
            </span>
        </div>
    )
}
export default PageTitle;