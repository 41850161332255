import * as React from 'react';
import { SubmitButton, DeleteButton } from 'components/button';
import { COLLECTION_STATE, USER_ROLES } from 'config/const';
import { useIntl } from 'react-intl';
import ShareCollectionLink from 'components/ShareCollectionLink';

const HeaderCollectionButtons = ({
  collectionState,
  userRole,
  onDraftUpdate,
  onDraftSubmit,
  onLiveUpdate,
  onWithdraw,
  onDelete,
  onReject,
  onApprove,
  isFrozen,
  onDuplicate,
  collectionLink
}: any) => {
  const intl = useIntl();
  const isInfluencer = userRole === USER_ROLES.INFLUENCER;
  const isModerator = userRole === USER_ROLES.MODERATOR;

  return (
    <div className="flex items-center space-x-2 my-4">
      {collectionState === COLLECTION_STATE.DRAFT && (
        <>
          <DeleteButton withIcon hideableText onClick={onDelete}>
            {intl.formatMessage({ id: 'collection.deleteButton.text' })}
          </DeleteButton>
          <SubmitButton onClick={onDuplicate}>
            {intl.formatMessage({ id: 'common.duplicate' })}
          </SubmitButton>
          <SubmitButton onClick={onDraftUpdate}>
            {intl.formatMessage({ id: 'collection.updateButton.text' })}
          </SubmitButton>
          <SubmitButton onClick={onDraftSubmit}>
            {intl.formatMessage({ id: 'collection.saveButton.text' })}
          </SubmitButton>
        </>
      )}
      {collectionState === COLLECTION_STATE.IN_REVIEW && (
        <>
          {isInfluencer && (
            <>
              <DeleteButton onClick={onWithdraw}>
                {intl.formatMessage({ id: 'collection.withdrawButton.text' })}
              </DeleteButton>
              <SubmitButton onClick={onDuplicate}>
                {intl.formatMessage({ id: 'common.duplicate' })}
              </SubmitButton>
            </>
          )}
          {isModerator && (
            <>
              {isFrozen ? (
                <DeleteButton withIcon hideableText onClick={onDelete}>
                  {intl.formatMessage({ id: 'collection.deleteButton.text' })}
                </DeleteButton>
              ) : (
                <DeleteButton onClick={onReject}>
                  {intl.formatMessage({
                    id: 'collection.rejectButton.text',
                  })}
                </DeleteButton>
              )}
              <SubmitButton onClick={onApprove}>
                {intl.formatMessage({
                  id: 'collection.approveButton.text',
                })}
              </SubmitButton>
              <SubmitButton onClick={onDuplicate}>
                {intl.formatMessage({ id: 'common.duplicate' })}
              </SubmitButton>
            </>
          )}
        </>
      )}
      {collectionState === COLLECTION_STATE.LIVE && (
        <>
          <DeleteButton withIcon hideableText onClick={onDelete}>
            {intl.formatMessage({ id: 'collection.deleteButton.text' })}
          </DeleteButton>
          <SubmitButton onClick={onDuplicate}>
            {intl.formatMessage({ id: 'common.duplicate' })}
          </SubmitButton>
          <SubmitButton onClick={onLiveUpdate}>
            {intl.formatMessage({ id: 'collection.saveDraftButton.text' })}
          </SubmitButton>
        </>
      )}
    </div>
  );
};

export default HeaderCollectionButtons;
