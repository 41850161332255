import * as React from 'react';
import icons from 'components/icons';
import cn from 'classnames';

type ButtonProps = {
  dir: 'next' | 'prev';
  onClick: any;
  disabled?: boolean;
};

const PaginationButton = ({ disabled, onClick, dir }: ButtonProps) => {
  return (
    <span
      onClick={disabled ? null : onClick}
      className={cn(
        'inline-block w-10 h-10 cursor-pointer rounded-sm hover:text-higlight transition-colors duration-300',
        {
          'hover:text-border': disabled,
          'text-border': disabled,
        }
      )}
    >
      {icons[dir === 'next' ? 'chevronRight' : 'chevronLeft']}
    </span>
  );
};

type Props = {
  hasNextPage: boolean;
  currentPage: number;
  onChangePage: (nextPage: number) => void;
};

export default function PaginationButtons({
  hasNextPage,
  currentPage,
  onChangePage,
}: Props) {
  return (
    <div className="w-full m-4 flex justify-center items-center">
      <PaginationButton
        dir="prev"
        onClick={() => {
          onChangePage(currentPage - 1);
        }}
        disabled={currentPage === 1}
      />
      <span className="mx-2">{currentPage}</span>
      <PaginationButton
        dir="next"
        onClick={() => {
          onChangePage(currentPage + 1);
        }}
        disabled={!hasNextPage}
      />
    </div>
  );
}
