import * as React from 'react';
import cn from 'classnames';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faQuestionCircle} from '@fortawesome/free-solid-svg-icons';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import {collectionValidationSelector} from 'lib/redux/index'
import { validateCollectionInput } from 'lib/redux/formValidation/actions';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label?: string;
  containerClassName?: string;
  sideElement?: React.ReactElement;
  fullWidth?: boolean;
  widthClass?: string;
  disabled?: boolean;
  control?: any;
  name?: any;
  rules?: any;
  placeholder?: string;
  isTooltip?: boolean;
  tooltipText?: any;
};

const Input = ({
  containerClassName,
  placeholder,
  sideElement,
  fullWidth,
  widthClass,
  label,
  className,
  disabled,
  control,
  name,
  rules,
  isTooltip,
  tooltipText,
  value,
  ...rest
}: Props) => {

  const validationState = useSelector(collectionValidationSelector);
  const dispatch = useDispatch();
  const onBlur = () => {
    if(!!value) dispatch(validateCollectionInput('', name));
  }

  return (
    <div className={cn('flex flex-col my-4', containerClassName)}>
      <div className="flex items-center">
        {label && (
          <label className="text-secondary" htmlFor={rest.id}>
            {label}
          </label>
        )}
        {isTooltip && <div className="has-tooltip ml-1"><div className='tooltip rounded shadow-lg p-2 bg-white text-black -mt-10'>{tooltipText}</div><FontAwesomeIcon icon={faQuestionCircle}/></div>}
      </div>
      <div className="flex flex-col">
        <input
          onBlur={onBlur}
          className={cn(
            'px-4 py-2 md:py-4 w-full md:min-w-300 appearance-none border rounded-md text-primary text-sm md:text-base',
            {
              'text-secondary': rest.readOnly,
              'border-border focus:border-black': !validationState[name],
              'border-red-500': !!validationState[name],
              'w-full': fullWidth,
              'md:max-w-md': !fullWidth && !widthClass,
              [widthClass || '']: widthClass,
            },
            className
          )}
          placeholder={placeholder}
          type="text"
          disabled={!!disabled}
          value={value}
          {...rest}
        />
        {sideElement && sideElement}
        {!!validationState[name] && <p className="p-2 text-red-500">{validationState[name]}</p>}
      </div>
    </div>
  );
};

type AuthInputProps = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: string;
  containerClassName?: string;
  inputRef?: any;
  label?: string;
};

const AuthInput = ({
  error,
  containerClassName,
  className,
  inputRef = null,
  label,
  ...rest
}: AuthInputProps) => {
  return (
    <div className={cn('flex flex-col', containerClassName)}>
      {label && <label>{label}</label>}
      <input
        className={cn(
          'outline-none border-2 border-border focus:border-black rounded-md p-4 transition-colors duration-300',
          className
        )}
        ref={inputRef}
        {...rest}
      />
      {error && <span className="text-xs text-error mt-2">{error}</span>}
    </div>
  );
};

export { Input as default, AuthInput };
