import * as React from 'react';
import cn from 'classnames';
import { propOr } from 'ramda';
import Badge from 'components/badge';
import { COLLECTION_STATE } from 'config/const';
import { FormattedMessage, useIntl } from 'react-intl';
import Duplication from 'components/duplication';
import icons from 'components/icons';
import fallbackCollectionImage from 'assets/img/collection-fallback-thumbnail.jpg';
import CollectionStatusWarn from 'components/UI/collectionStatus/collectionStatusWarn';

type Props = {
  onClick: (
    collection: CollectionResponse | FeaturedProductsCollection
  ) => void;
  collection: CollectionResponse | FeaturedProductsCollection;
  isFP?: boolean;
  canDuplicate?: boolean;
  duplicationCallback?: Function;
};

const CollectionCard = ({
  onClick,
  collection,
  isFP,
  canDuplicate,
  duplicationCallback,
}: Props) => {
  const intl = useIntl();
  const thumbnailUrl = propOr(
    fallbackCollectionImage,
    'thumbnailUrl',
    collection
  );
  const title = propOr('', 'titleEnglish', collection);
  const [showDuplicationModal, setShowDuplicationModal] = React.useState(false);

  const productsLabel = intl
    .formatMessage({ id: 'collection.tabs.products' })
    .toLowerCase();
  const stateToBadge = {
    [COLLECTION_STATE.DRAFT]: intl.formatMessage({
      id: 'collection.badge.draft',
    }),
    [COLLECTION_STATE.IN_REVIEW]: intl.formatMessage({
      id: 'collection.badge.inReview',
    }),
    [COLLECTION_STATE.PUBLISHING]: intl.formatMessage({
      id: 'collection.badge.publishing',
    }),
    [COLLECTION_STATE.LIVE]: intl.formatMessage({
      id: 'collection.badge.live',
    }),
  };

  return (
    <div className="mb-2 me-2">
      <div
        onClick={() => onClick(collection)}
        className={cn(
          'h-32 flex flex-col justify-between w-24 md:w-200 md:h-300 rounded-lg pb-6 pt-2 px-2 cursor-pointer bg-cover shadow-md'
        )}
        style={{
          backgroundImage: `url("${thumbnailUrl}")`,
        }}
      >
        <div className="flex justify-between items-start">
          <Badge color="white">
            {collection.items.length}{' '}
            <span className="hidden md:inline">{productsLabel}</span>
          </Badge>
          <Badge
            color={
              collection.state === COLLECTION_STATE.LIVE ? 'green' : 'orange'
            }
          >
            {stateToBadge[collection.state]}
            {collection.wasRejected && (
              <div>
                {intl.formatMessage({ id: 'collection.badge.rejected' })}
              </div>
            )}
            {collection.hasErrors && <CollectionStatusWarn/>}
          </Badge>
        </div>
        <div className="font-bold text-white flex flex-col items-center">
          {isFP && (
            <>
              <CardTitle>{collection.owner.influencerNameEnglish}</CardTitle>
              <CardTitle>
                <FormattedMessage
                  id={`common.countries.${collection.country.toLocaleLowerCase()}`}
                />
              </CardTitle>
              <CardTitle>
                {collection.audience.map(a => (
                  <span key={a}>
                    <FormattedMessage
                      id={`collection.audience.${a.toLowerCase()}`}
                    />
                  </span>
                ))}
              </CardTitle>
            </>
          )}
          {!isFP && <CardTitle>{title}</CardTitle>}
        </div>
      </div>
      {canDuplicate && (
        <>
          <div className="text-center mt-1">
            <button
              onClick={() => setShowDuplicationModal(true)}
              className="text-secondary text-sm md:text-lg hover:text-higlight transition-colors duration-300"
            >
              <span className="inline-block w-4 h-4 me-2">
                {icons.duplicate}
              </span>
              <FormattedMessage id="common.duplicate" />
            </button>
          </div>
          {showDuplicationModal && (
            <Duplication
              id={collection.id}
              type={isFP ? 'featuredProducts' : 'collection'}
              sourceCountry={collection.country}
              duplicationCallback={duplicationCallback}
              onExit={() => setShowDuplicationModal(false)}
            />
          )}
        </>
      )}
    </div>
  );
};

const CardTitle = ({ children }: any) => (
  <span style={{ textShadow: '#000 0 0 3px' }}>{children}</span>
);

export default CollectionCard;
