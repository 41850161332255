import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faThumbtack, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { spawn } from 'child_process';

type Props = {
    hasPinnedCollections: boolean;
    pinnedNumber: number;
    clicked: () => void;
}

const ThumbtackIcon = ({hasPinnedCollections, pinnedNumber, clicked} : Props) => {
    return (        
        <div className="flex items-center space-x-2 cursor-pointer justify-start" onClick={clicked}>
            <FontAwesomeIcon icon={faThumbtack} color={hasPinnedCollections ? "black" : "gray"} size="2x"/>
            {hasPinnedCollections && <span style={{fontSize: ".8em", alignSelf: "flex-start"}}>({pinnedNumber})</span>}
        </div>
    );
}

export default ThumbtackIcon;