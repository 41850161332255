import {useState, useEffect} from 'react';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { AVAILABLE_LANGUAGES, ISO_CODE_BY_COUNTRY } from 'config/const';
import { getResponseErrorMessage } from 'utils/common';
import ErrorBox from 'components/error-box';
import Loader from 'components/loader';
import { divide } from 'ramda';

type Props = {
    skusArray: any;
    countryCode: Country;
    lang: Language;
}

const CollectionProductItems = ({skusArray, countryCode, lang} : Props) => {
    const [isLoading, setIsLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [errorMsg, setErrorMsg] = useState('');
    const [productsDetails, setProductsDetails] = useState<Array<ProductResponse> | null>(null);

    useEffect(() => {
        fetchProductsDetails();
    }, [])

    const checkSkusAvailability = (items: ProductResponse[]) => {
        const placeholderArray: ProductResponse[] = [];
        skusArray.forEach((sku) => {
            const newItem = items.find(item => {
                return item.sku === sku.sku
            });
            if(newItem) placeholderArray.push(newItem);
            else placeholderArray.push({sku: sku.sku, name: sku.sku, thumbnail_url: ''});
        })
        return placeholderArray;
    }

    const fetchProductsDetails = async () => {
        try{
            setIsLoading(true);
            const response = await restClient.post(`${getProductsLocaleSubPath(countryCode, 'EN')}${endpoints.products}/skus`, 
            {body:{ ids: skusArray.map(item => item.sku)}});
            setProductsDetails(checkSkusAvailability(response.data));
         }catch(e){
             setIsError(true);
             setErrorMsg(getResponseErrorMessage(e));
         }finally{
            setIsLoading(false);
         }
    }
    const getProductsLocaleSubPath = (country: Country, lang: Language) => {
        const localeSubPath =
          `/${ISO_CODE_BY_COUNTRY[country]}_${lang}`.toLowerCase();
        return localeSubPath;
      };
    return (
        <div className="flex flex-wrap justify-center">
            {productsDetails && !isLoading && !isError && productsDetails.map((product) => <CollectionItem product={product}/>)}
            {isError && !isLoading && <ErrorBox>{errorMsg}</ErrorBox>}
            {isLoading && <Loader/>}
        </div>
    );
}

const CollectionItem = ({product}:{product: ProductResponse}) => {
    return (
        <div className="w-200 flex flex-col justify-start items-center relative bg-white m-2">
            <div className="h-product-card-image w-full">
                {product.thumbnail_url ? (
                <img
                    src={product.thumbnail_url}
                    alt={product.name}
                    className="w-full h-full object-cover object-center rounded-t-md"
                />
                ) : (
                <div className="text-5xl bg-lightGray text-secondary h-full w-full flex justify-center items-center">
                    N/A
                </div>
                )}
            </div>
            <div className="p-3">
                {product.brand_name && (
                <span className="inline-block mt-3 text-sm leading-5 uppercase font-bold text-center">
                    {product.brand_name}
                </span>
                )}
                <span className="inline-block text-sm text-secondary text-center">
                {product.name || product.sku}
                </span>
            </div>
        </div>
    );
}

export default CollectionProductItems;