import * as React from 'react';
import restClient from 'lib/rest-client';
import { List } from 'components/list';
import { endpoints } from 'config/api';
import { ITEMS_PER_PAGE, USER_ROLES } from 'config/const';
import { getResponseErrorMessage } from 'utils/common';
import { LoaderOverlay } from 'components/loader';
import { isEmpty } from 'ramda';
import PaginationButtons from 'components/pagination-buttons';
import { errorToast, successToast } from 'components/toast/toast';
import { AuthInput } from 'components/input';
import { UserName } from 'components/user-name';

type Props = {
  currentModerator: AssingedModerator;
  influencer: User;
  onSuccessCb: () => void;
};

export default function AssingModerator({
  currentModerator,
  influencer,
  onSuccessCb,
}: Props) {
  const [currentPage, setCurrentPage] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [hasNextPage, setHasNextPage] = React.useState(true);
  const [moderators, setModeratos] = React.useState<Array<User>>([]);
  const [query, setQuery] = React.useState('');

  const fetchModerators = React.useCallback(async () => {
    try {
      if (query && query.length < 3) {
        return;
      }
      setIsLoading(true);
      const { items, totalPages } = await restClient.get(`${endpoints.adminUsers}`, {
        queryStringParameters: {
          limit: ITEMS_PER_PAGE,
          page: currentPage - 1,
          type: USER_ROLES.MODERATOR,
          q: query,
        },
      });
      setModeratos(items);
      setHasNextPage(totalPages !== currentPage);
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, query]);

  const handleAssignModerator = async ({
    id: moderatorId,
    email: moderatorEmail,
  }: User) => {
    try {
      setIsLoading(true);
      await restClient.put(
        `${endpoints.adminUsers}/${influencer.id}/moderators`,
        { body: { moderatorId } }
      );
      successToast(
        `Moderator for ${influencer.email} has been changed to ${moderatorEmail}`
      );
      onSuccessCb();
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
      setIsLoading(false);
    }
  };

  React.useEffect(() => {
    fetchModerators();
  }, [fetchModerators]);

  return (
    <div>
      {isLoading && <LoaderOverlay />}
      <div className="mt-4">
        <div className="space-y-2 ms-4 mb-6">
          <h1>
            Change moderator for:
            <UserName user={influencer} className="ms-2 text-higlight" />
          </h1>
          <h1>
            Current moderator:
            <UserName user={currentModerator} className="ms-2 text-higlight" />
          </h1>
        </div>
        <div className="max-w-sm ms-2 mb-8">
          <AuthInput
            label="Search by moderator name, email"
            value={query}
            onChange={evt => setQuery(evt.target.value)}
          />
        </div>
        <List
          withBg
          items={moderators.map(moderator => (
            <div
              className="px-4 cursor-pointer hover:underline"
              onClick={() => handleAssignModerator(moderator)}
            >
              <UserName user={moderator} />
              <span className="text-secondary text-sm ms-2">
                {moderator.email}
              </span>
            </div>
          ))}
        />
      </div>
      {!isEmpty(moderators) && (
        <PaginationButtons
          currentPage={currentPage}
          hasNextPage={hasNextPage}
          onChangePage={setCurrentPage}
        />
      )}
      {isEmpty(moderators) && !isLoading && (
        <h1 className="ms-2 mt-4">No moderators</h1>
      )}
    </div>
  );
}
