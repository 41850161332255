import { AuthButton as Button } from 'components/button';
import { LoaderOverlay } from 'components/loader';
import { errorToast, successToast } from 'components/toast/toast';
import { endpoints } from 'config/api';
import restClient from 'lib/rest-client';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getResponseErrorMessage, useCountries } from 'utils/common';

type FormData = {
  id: string;
  superstarCountries: Array<Country>;
};

type Props = {
  initialData?: FormData;
  submitCallback: () => void;
  updateCurrUserState: (id: String, pickedCountries: Array<Country>) => void;
};

export default function SuperStarForm({
  initialData = {} as any,
  submitCallback,
  updateCurrUserState
}: Props) {
  const [selectedCountries, setSelectedCountries] = useState<Array<Country>>(
    initialData?.superstarCountries || []
  );
  const [isLoading, setIsLoading] = useState(false);
  const countries = useCountries();

  const handleOnChange = (value: Country) => {
    if (selectedCountries.includes(value)) {
      setSelectedCountries(
        selectedCountries.filter(country => country !== value)
      );
    } else {
      setSelectedCountries([...selectedCountries, value]);
    }
  };

  const handleSubmit = async () => {
    try {
      setIsLoading(true);
      await restClient.patch(
        `${endpoints.superadmin.users}/${initialData.id}`,
        { body: { superstarCountries: selectedCountries } }
      );
      successToast('Marking Influencer as SuperStar has been successful');
      updateCurrUserState(initialData.id, selectedCountries);
      submitCallback();
      setIsLoading(false);
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
      setIsLoading(false);
    }
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      <div>
        <h1 className="text-2xl md:text-4xl">Mark Influencer as SuperStar</h1>
        <div className="flex flex-col space-y-4 mt-8">
          {countries.map(country => {
            return (
              <label key={country.name} className="flex items-center text-lg text-secondary cursor-pointer hover:text-black transition-colors duration-300">
                <input
                  type="checkbox"
                  value={country.value}
                  onChange={evt => handleOnChange(evt.target.value as Country)}
                  checked={selectedCountries.includes(country.value)}
                  key={country.value}
                />
                <div className="flex items-center ms-4">
                  <img
                    className="w-8 h-8"
                    src={country.flagImageSrc}
                    alt={country.name}
                  />
                  <span className="ms-2">{country.name}</span>
                </div>
              </label>
            );
          })}
        </div>
        <div className="mt-10">
          <Button onClick={handleSubmit}>
            <FormattedMessage id="common.submit" />
          </Button>
        </div>
      </div>
    </>
  );
}
