import AddressForm from 'components/forms/influencer-details/address-form';
import PassportForm from 'components/forms/influencer-details/passport-form';
import FinanceForm from 'components/forms/influencer-details/finance-form';
import ContractForm from 'components/forms/influencer-details/contract-form';
import SocialForm from 'components/forms/influencer-details/social-form';
import AccountForm from 'components/forms/influencer-details/account-form';
import PersonalDetailsForm from 'components/forms/influencer-details/personal-details-form';
import { useState } from 'react';
import Modal from 'components/modal';
import ChangePassword from 'components/forms/change-password';
import { useDispatch } from 'react-redux';
import { updateUserSettings } from 'lib/redux/user/actions';
import { errorToast, successToast } from 'components/toast/toast';
import { getResponseErrorMessage } from 'utils/common';
import { LoaderOverlay } from 'components/loader';
import { uploadFileToS3 } from 'utils/file';
import { FormattedMessage } from 'react-intl';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { useHistory } from 'react-router-dom';

type Props = {
  initialInfluencerData: any;
  editedBy: 'influencer' | 'superadmin';
};

export default function InfluencerDetails({
  editedBy,
  initialInfluencerData,
}: Props) {
  const [isChangePasswordActive, setIsChangePasswordActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  if (!initialInfluencerData || !editedBy) return null;

  const influencerSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      let { profilePicture, ...dataToSubmit } = data;
      if (profilePicture) {
        const profilePictureUrl = await uploadFileToS3(profilePicture);
        dataToSubmit = { ...dataToSubmit, profilePictureUrl };
      }
      await dispatch(updateUserSettings(dataToSubmit));
      successToast(<FormattedMessage id="account.updateSuccess" />);
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };

  const superAdminSubmit = async (data: any) => {
    try {
      setIsLoading(true);
      let { profilePicture, ...dataToSubmit } = data;
      if (profilePicture) {
        const profilePictureUrl = await uploadFileToS3(profilePicture);
        dataToSubmit = { ...dataToSubmit, profilePictureUrl };
      }
      await restClient.patch(
        `${endpoints.superadmin.users}/${initialInfluencerData.id}`,
        { body: { ...dataToSubmit } }
      );
      successToast(<FormattedMessage id="account.updateSuccess" />);
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      history.push('/users');
    }
  };

  const handleDataSubmit = async (data: any = {}) => {
    return editedBy === 'influencer'
      ? influencerSubmit(data)
      : superAdminSubmit(data);
  };

  return (
    <>
      {isLoading && <LoaderOverlay />}
      {isChangePasswordActive && (
        <Modal onExit={() => setIsChangePasswordActive(false)} withExitButton>
          <ChangePassword
            onSuccessCallback={() => setIsChangePasswordActive(false)}
          />
        </Modal>
      )}
      <div className="flex flex-col space-y-6 md:space-y-12">
        <PersonalDetailsForm
          initialData={initialInfluencerData}
          profilePictureUrl={initialInfluencerData.profilePictureUrl}
          onFormSubmit={handleDataSubmit}
        />
        <AccountForm
          initialData={initialInfluencerData}
          onChangePassword={() => setIsChangePasswordActive(true)}
          readOnly={editedBy === 'superadmin'}
        />
        <AddressForm
          initialData={initialInfluencerData.address}
          onFormSubmit={handleDataSubmit}
        />
        <PassportForm
          initialData={initialInfluencerData.passport}
          onFormSubmit={handleDataSubmit}
        />
        <FinanceForm
          initialData={initialInfluencerData.bankAccount}
          onFormSubmit={handleDataSubmit}
          readOnly={editedBy === 'superadmin'}
        />
        <ContractForm
          initialData={initialInfluencerData.contract}
          onFormSubmit={handleDataSubmit}
          readOnly={editedBy === 'influencer'}
        />
        <SocialForm
          initialData={initialInfluencerData.socialAccounts}
          onFormSubmit={handleDataSubmit}
        />
      </div>
    </>
  );
}
