import * as React from 'react';
import Header from 'components/header';
import Layout from 'components/layout';
import Content from 'components/content';
import UserDetails from 'components/user-details';
import Modal from 'components/modal';
import { useSelector, useDispatch } from 'react-redux';
import {trendingFilterSelector} from 'lib/redux/index';
import {setIsPinned, setIsSuperstar, setQuery, setPickedCountry, setAudience} from 'lib/redux/trendingUserFilter/actions';
import { LoaderOverlay } from 'components/loader';
import { AVAILABLE_COUNTRIES, ITEMS_PER_PAGE, COUNTRY_PICKER_ITEMS } from 'config/const';
import { getResponseErrorMessage, useCountries } from 'utils/common';
import { FormattedMessage } from 'react-intl';
import restClient from 'lib/rest-client';
import { endpoints } from 'config/api';
import { errorToast, successToast } from 'components/toast/toast';
import Input from 'components/input';
import { AuthButton } from 'components/button';
import cn from 'classnames';
import PaginationButtons from 'components/pagination-buttons';
import {List} from 'components/dragable-list';
import StarIcon from "components/UI/PopularityIcons/StarIcon";
import ThumbtackIcon from "components/UI/PopularityIcons/ThumtackIcon";
import { useHistory } from 'react-router-dom';
import RadioButton from 'components/radio-button';
import CountryPickerList from 'components/CountryPicker/CountryPickerList';
import Pagination from 'components/Pagination/Pagination';
import { number } from 'yup/lib/locale';
import PageTitle from 'components/PageTitle';

type InfluencerEntry = {
  id: string;
  email: string;
  nameArabic: string;
  nameEnglish: string;
  familyNameArabic: string;
  familyNameEnglish: string;
  profilePictureUrl: string;
  superstar: boolean;
  numberOfTrendingCollections: number;
  position: number;
  numberOfLiveCollections: number;
};

const InfluencersOrder = () => {
  const history = useHistory();
  const [isLoading, setIsLoading] = React.useState(false);
  const [totalResults, setTotalResults] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [hasMorePages, setHasMorePages] = React.useState(true);
  const [numberOfPages, setNumberOfPages] = React.useState(0);

  const countries = useCountries();
  const [selectedCountry, setSelectedCountry] = React.useState(
    AVAILABLE_COUNTRIES.UAE
  );

  const filter = useSelector(trendingFilterSelector);
  const dispatch = useDispatch();

  const [influencers, setInfluencers] = React.useState<Array<InfluencerEntry>>(
    []
  );
  const [selectedInfluencer, setSelectedInfluencer] =
    React.useState<InfluencerEntry | null>(null);
  const [toggleModal, setToggleModal] = React.useState(false);

  const fetchInfluencers = React.useCallback(async () => {
    try {
      setIsLoading(true);
      const response: PaginatedResponse<InfluencerEntry> = await restClient.get(
        `${endpoints.superadmin.popularity}/${filter.pickedCountry}`,
        {
          queryStringParameters: {
            limit: ITEMS_PER_PAGE,
            page: currentPage - 1,
            q: filter.query,
            superstar: filter.isSuperstar,
            trending: filter.isPinned,
            audience: filter.audience
          },
        }
      );
      setInfluencers(response.items);
      setNumberOfPages(response.totalPages);
      setHasMorePages(!(response.totalPages === currentPage));
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
      setInfluencers([]);
    } finally {
      setIsLoading(false);
    }
  }, [currentPage, selectedCountry, filter]);

  const updateInfluencerSuperstar = (userId, update) => {
    const influencersArr = [...influencers];
    const objIndex = influencersArr.findIndex(obj => obj.id === userId);
    influencersArr[objIndex].superstar = update;
    setInfluencers(influencersArr);
  }

  const markSuperstar = async (userId) => {
    try{
      setIsLoading(true);
      const response = await restClient.put(`${endpoints.superadmin.users}/${userId}/superstars/${filter.pickedCountry}`);
      updateInfluencerSuperstar(userId, true);
      successToast('Influencer marked as superstar');
    }catch(e){
      errorToast(getResponseErrorMessage(e));
    }finally{
      setIsLoading(false);
    }
  }

  const unmarkSuperstar = async (userId) => {
    try{
      setIsLoading(true);
      const response = await restClient.delete(`${endpoints.superadmin.users}/${userId}/superstars/${filter.pickedCountry}`);
      updateInfluencerSuperstar(userId, false);
      successToast('Influencer unmarked as superstar');
    }catch(e){
      errorToast(getResponseErrorMessage(e));
    }finally{
      setIsLoading(false);
    }
  }

  const updateInfluencerOrder = async (order: number, influencerId?) => {
    let id = '';
    if(influencerId){
      id = influencerId;
    } else {
      id = selectedInfluencer!.id
    }
    try {
      setIsLoading(true);
      await restClient.put(
        `${endpoints.superadmin.popularity}/${filter.pickedCountry}`,
        { body: { id, order } }
      );
      successToast(
        <div>
          <strong>
            {selectedInfluencer!.nameEnglish}{' '}
            {selectedInfluencer!.familyNameEnglish}
          </strong>{' '}
          order has been changed to <strong>{order + 1}</strong>
        </div>
      );
      setToggleModal(false);
      setSelectedInfluencer(null);
      setCurrentPage(1);
      if (currentPage === 1) fetchInfluencers();
    } catch (error) {
      errorToast(getResponseErrorMessage(error));
    } finally {
      setIsLoading(false);
    }
  };
  const handleCountryChange = country => {
    setCurrentPage(1);
    setSelectedCountry(country);
    dispatch(setPickedCountry(country))
  };
  const onSuperstarChange = (value) => {
    setCurrentPage(1);
    dispatch(setIsSuperstar(value));
  }
  const onIsPinnedChange = (value) => {
    setCurrentPage(1);
    dispatch(setIsPinned(value));
  }
  const onQueryChange = (e) => {
    setCurrentPage(1);
    dispatch(setQuery(e.target.value));
  }
  const onAudienceChange = (value) => {
    setCurrentPage(1);
    dispatch(setAudience(value));
  }

  const setPosition = (positionItem) => {
    if(positionItem === null) return '';
    return positionItem + 1
  }

  React.useEffect(() => {
    if(filter.query.length == 0 || filter.query.length > 2){
      fetchInfluencers();
    }
  }, [fetchInfluencers]);

  return (
    <>
      {selectedInfluencer && toggleModal && (
        <Modal withExitButton onExit={() => {
          setSelectedInfluencer(null)
          setToggleModal(false)
          }}>
          <div className="flex flex-col items-center space-y-4 py-4">
            <span>
              Change{' '}
              <span className="text-higlight">{`${selectedInfluencer.nameEnglish} ${selectedInfluencer.familyNameEnglish}`}</span>{' '}
              order in{' '}
              <span className="text-higlight">
                <FormattedMessage
                  id={`common.countries.${selectedCountry.toLowerCase()}`}
                />
              </span>
            </span>
            <div className="flex space-x-2">
              {[...Array(5)].map((_, idx) => (
                <Bullet onClick={() => updateInfluencerOrder(idx)} key={idx}>
                  {idx + 1}
                </Bullet>
              ))}
            </div>
            <span>or other: </span>
            <OrderInput onSubmit={updateInfluencerOrder} />
          </div>
        </Modal>
      )}
      <Layout>
        <Header>
          <PageTitle title="Influencers Order"/>
        </Header>
        <Content>
          {isLoading && <LoaderOverlay />}
          <div>
            <div data-testid="country-select" className="mb-4">
              <div className="flex flex-col">
                <div className="flex flex-col">
                  <span className="text-secondary">
                    <FormattedMessage id="settings.country"/>:
                  </span>
                  <CountryPickerList countryList={countries} onCountryPick={handleCountryChange} pickedCountry={filter.pickedCountry}/>
                </div>
                <div className="flex items-center flex-wrap">
                  <Input label="Search by username, name, email:" 
                    value={filter.query} 
                    onChange={onQueryChange}
                  />
                  <div className="flex flex-col sm:ml-10 ">
                    <h5>Type:</h5>
                    <RadioButton 
                      checked={filter.isSuperstar === 'SUPERSTAR'} 
                      label="Superstars" 
                      onChange={() => onSuperstarChange('SUPERSTAR')} 
                      value='SUPERSTAR'
                    />
                    <RadioButton 
                      checked={filter.isSuperstar === 'STAR'} 
                      label="Stars" 
                      onChange={() => onSuperstarChange('STAR')} 
                      value='STAR'
                    />
                    <RadioButton 
                      checked={filter.isSuperstar === 'ALL'} 
                      label="All influencers" 
                      onChange={() => onSuperstarChange('ALL')} 
                      value='ALL'
                    />
                  </div>
                  <div className="flex flex-col sm:ml-10 mt-5 sm:mt-0">
                    <h2>Promo:</h2>
                    <RadioButton 
                      checked={filter.isPinned === 'YES'} 
                      label="Pinned collections" 
                      onChange={() => onIsPinnedChange('YES')} 
                      value='YES'
                    />
                    <RadioButton 
                      checked={filter.isPinned === 'NO'} 
                      label="No Pinned collections" 
                      onChange={() => onIsPinnedChange('NO')} 
                      value='NO'
                    />
                    <RadioButton 
                      checked={filter.isPinned === 'ALL'} 
                      label="All influencers" 
                      onChange={() => onIsPinnedChange('ALL')} 
                      value='ALL'
                    />
                  </div>
                  <div className="flex flex-col sm:ml-10 mt-5 sm:mt-0">
                    <h2>Audience:</h2>
                    <RadioButton 
                      checked={filter.audience === 'MEN'} 
                      label="Man" 
                      onChange={() => onAudienceChange('MEN')} 
                      value='MEN'
                    />
                    <RadioButton 
                      checked={filter.audience === 'WOMEN'} 
                      label="Woman" 
                      onChange={() => onAudienceChange('WOMEN')} 
                      value='WOMEN'
                    />
                    <RadioButton 
                      checked={filter.audience === 'KIDS'} 
                      label="Kids" 
                      onChange={() => onAudienceChange('KIDS')} 
                      value='KIDS'
                    />
                    <RadioButton 
                      checked={filter.audience === 'ALL'} 
                      label="All influencers" 
                      onChange={() => onAudienceChange('ALL')} 
                      value='ALL'
                    />
                  </div>
                </div>
              </div>
            </div>
            {!!influencers.length && <List
              withBg
              updateInfluencers={updateInfluencerOrder}
              pageNumber={currentPage}
              itemsPerPage={ITEMS_PER_PAGE}
              influencers={influencers}
              setSelectedInfluencer={setSelectedInfluencer}
              items={influencers.map((influencer, idx) => (
                <InfluencerDetails
                  key={influencer.id}
                  influencer={influencer}
                  onClick={(item) => {
                    setSelectedInfluencer(item)
                    setToggleModal(true)
                  }}
                  markSuperstar={markSuperstar}
                  unmarkSuperstar={unmarkSuperstar}
                  navigateToCollections={() => history.push(`${endpoints.superadmin.influCollections}/${influencer.id}`)}
                  order={setPosition(influencer.position)}
                />
              ))}
            />}
            {!influencers.length && !isLoading && <div className="flex justify-center text-xl mt-10 p-20 shadow-2xl">NO MATCHING SEARCH RESULTS</div>}
            {!!influencers.length && 
              <Pagination 
                disableNext={currentPage === numberOfPages} 
                disablePrev={currentPage === 1} 
                numberOfPages={numberOfPages} 
                itemsPerPage={ITEMS_PER_PAGE} 
                pageChange={setCurrentPage}
              />
            }
          </div>
        </Content>
      </Layout>
    </>
  );
};

const Bullet = ({ children, onClick = null, color = 'orange' }: any) => {
  return (
    <div
      onClick={onClick}
      className={cn('w-8 h-8 rounded-full flex justify-center items-center ', {
        'bg-higlight text-white': color === 'orange',
        'cursor-pointer transition-opacity hover:opacity-50 duration-500':
          onClick,
      })}
    >
      {children}
    </div>
  );
};

const OrderInput = ({ onSubmit }: { onSubmit: (order: number) => void }) => {
  const [inputValue, setInputValue] = React.useState<string>('');
  const order = Number.parseInt(inputValue || '0');

  return (
    <div className="flex flex-col">
      <Input
        type="number"
        min="1"
        value={inputValue}
        onChange={evt => setInputValue(evt.target.value)}
      />
      <AuthButton disabled={!order} onClick={() => onSubmit(order - 1)}>
        Change order
      </AuthButton>
    </div>
  );
};

const InfluencerDetails = ({
  influencer,
  onClick,
  order,
  markSuperstar,
  unmarkSuperstar,
  navigateToCollections,
}: {
  influencer: InfluencerEntry;
  onClick: (influencer: InfluencerEntry) => void;
  order: number;
  markSuperstar:  (userId: string) => Promise<void>;
  unmarkSuperstar: (userId: string) => Promise<void>;
  navigateToCollections: () => void;
}) => {
  return (
    <div className="flex flex-col lg:flex-row lg:items-center w-full justify-between">
      <div className="flex items-center space-x-2 sm:flex-row">
        <Bullet color={order <= 5 && order! ? 'orange' : 'default'}>{order}</Bullet>
        <span className="ms-3 flex">
          {influencer.nameEnglish} {influencer.familyNameEnglish}{' '}
          <span className="ms-2 text-secondary sm:text-sm text-xs break-all">
            {influencer.email}
          </span>
        </span>
      </div>
      <div className="flex items-start sm:items-center w-60 min-w-max mt-5 lg:mt-0">
        <div className={influencer.numberOfLiveCollections > 0 ? "flex items-center justify-between sm:mr-8 mr-2 w-24" : "flex items-center justify-end sm:mr-8 mr-2 w-24"}>
          {influencer.numberOfLiveCollections > 0 && 
            <ThumbtackIcon 
            hasPinnedCollections={influencer.numberOfTrendingCollections > 0} 
            pinnedNumber={influencer.numberOfTrendingCollections} 
            clicked={navigateToCollections}
            />
          }
          <StarIcon 
          superstar={influencer.superstar} 
          toggleSuperstar={() => {
            if(influencer.superstar){
              unmarkSuperstar(influencer.id);
            }else{
              markSuperstar(influencer.id);
            }}}
          />
        </div>
        <span
          onClick={() => onClick(influencer)}
          className="cursor-pointer hover:underline"
        >
          Change order
        </span>
      </div>
    </div>
  );
};

export default InfluencersOrder;
