import CollectionsOrderItem,{PlaceholderItem} from './CollectionsOrderItem';
import {DragDropContext, Droppable, Draggable} from 'react-beautiful-dnd';

const AdditionalPanel = ({items, pickedItem, setItem, pickItem, hasPinnedCollections}) => {
    return (
        <div className="flex mt-10 flex-col">
            <h1>Additional Items</h1>
            <div className="flex mt-5 flex-wrap">
                {!!items && items.map((item, index) => <div key={index} onClick={!!pickedItem ? () => setItem('ADDITIONAL', index) : () => pickItem('ADDITIONAL', item.data.id)}><CollectionsOrderItem pickedItem={pickedItem}  data={item.data} key={index}/></div>)}
                {items.length === 0 && <div onClick={() => !!pickedItem && setItem('ADDITIONAL', 0)}><PlaceholderItem text="Empty"/></div>}
                {hasPinnedCollections && <p>There is no pinned collection in that country. Go to “Influencers order” and pin the collection.</p>}
            </div>
        </div>
    )
}

export default AdditionalPanel;